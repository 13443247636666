//
import React, { useEffect, useState } from "react";
import { CustomModal, SearchDiv } from "./inventoryStyle";
import { Button, Checkbox, message, Spin } from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import ProductList from "./ProductList";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { InventoryState } from "../../redux/reducers/InventoryReducer";
import { SearchBox } from "../../components/Search/Search";
import {
  addSelectedProduct,
  clearSelectedProduct,
  getProducts,
  removeSelectedProduct,
  updateProduct,
  updateSelectedProduct,
} from "../../redux/actions/productActions";
import {
  createDraftOrderFromAPI,
  getOrders,
  getOrder,
  createOrder,
} from "../../redux/actions/orderActions";
import ProductModal from "./ProductModal";
import AddCustomProduct from "./AddCustomProduct";
import styled from "styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import EditOrderModale from "./EditOrderModale";

const Container = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  #inventoryFilter {
    margin: 0 0 0 15px;
    flex-wrap: nowrap;
    &.ant-form-inline .ant-form-item:last-of-type {
      margin-right: 0;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    height: 40px;
    color: #292d32;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
  }
  table {
    thead {
      .ant-checkbox-inner {
        background-color: transparent; /* Customize the check color */
      }
    }
  }
  @media (max-width: 768px) {
    background-color: #fff;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  @media (max-width: 768px) {
    border-radius: 4px;
    border: 1px solid #ddd;
    order: 2;
  }
`;

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const ProductDetails = styled.div`
  flex: 1;
  padding: 5px 16px;
  gap: 18px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  &.pricing {
    & > div {
      &:not(.Requested) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.center {
    align-items: center;
  }
  &:first-of-type {
    border-left: 1px solid #ddd;
  }
  b {
    align-self: stretch;
    flex: 1;
    a {
      color: inherit;
      white-space: nowrap;
    }
  }
  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    flex-wrap: nowrap;
    span {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      p {
        margin: 0 0 3px;
        color: #292d32;
      }
      label {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid #ddd;
    border-right: none;
    font-size: 10px;
    b {
      font-size: inherit;
    }
    & > div {
      font-size: inherit;
      gap: 16px;
      span {
        justify-content: flex-start;
        font-size: inherit;
        label {
          font-size: inherit;
        }
      }
    }
    &.center {
      align-items: flex-start;
    }
  }
`;
const SkuDivMargin = styled.div`
  margin-bottom: 1.4rem;
`;
interface Product {
  id: string;
  image: string;
  title: string;
  sku: string;
  storeRequestPriceRange?: [number, number];
  price: number;
  requestedPrice?: number;
  cost?: number;
  option1Value?: string;
}
interface AddInventoryModalProp {
  isModalVisible: boolean;
  setIsModalVisible: () => void;
}
export default function CreateOrderModale({
  isModalVisible,
  setIsModalVisible,
}: AddInventoryModalProp) {
  const { store } = useAppSelector((state) => state.StoreReducer);
  const { products, updatedProduct, productsLoading, selectedProducts } =
    useAppSelector((state) => state.ProductReducer);
  const {
    inventoriesToReview,
    paginatedInventories,
    inventoriesLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const [inventoriesToAdd, setInventoriesToAdd] = useState<any[]>(
    inventoriesToReview ?? []
  );
  const [search, setSearch] = useState("");
  const [productModal, setProductModal] = useState<boolean>(false);
  const [customModal, setCustomModal] = useState<boolean>(false);
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [editOrder, setEditOrder] = useState<boolean>(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<any[]>([]);
  const [order, setOrder] = useState<any>();
  const dispatch = useAppDispatch();
  const addToInventories = (items: any[]) => {
    setInventoriesToAdd([...inventoriesToAdd, ...items]);
  };
  const handleCancel = async () => {
    await dispatch(clearSelectedProduct());
    setTimeout(() => {
      setIsModalVisible();
    }, 1000);
  };
  const handleProductModal = async (selectedProducts) => {
    try {
      if (selectedProducts.length <= 0) {
        message.error("Please select at least one product");
      } else {
        const response = await dispatch(createOrder(selectedProducts));
        setOrders(response.order);
        setSelectedOrder(response.inventory);
        setOrder(response.order);
        await dispatch(clearSelectedProduct());
        setIsModalVisible();
        setEditOrder(true);
      }
    } catch {
      console.error("error");
    }
  };
  const handleCustomModal = () => {
    setIsModalVisible();
    setCustomModal(true);
  };
  const handleAddProductModal = () => {
    setProductModal(false);
  };
  const handleAddCustomProductModal = () => {
    setCustomModal(false);
  };
  const handleAddCustomProductModalSuccess = () => {
    setCustomModal(false);
    setProductModal(true);
  };

  useEffect(() => {
    dispatch(getProducts(search));
  }, [search]);

  const toggleSelection = (product: any) => {
    const isProductSelected = selectedProducts.some((p) => p.id === product.id);
    if (isProductSelected) {
      dispatch(removeSelectedProduct(product.id, false));
    } else {
      dispatch(addSelectedProduct(product));
    }
  };
  const isSelected = selectedProducts.some(
    (p) => p.id === paginatedInventories?.rows
  );

  const handleCategoryChange = async (productId: any, value: any) => {
    //         const selectedOption = productTemplates.find(
    //   (option) => option.id === templateId
    // );
    setSelectedCategory((prevSelectedCategory) => ({
      ...prevSelectedCategory,
    }));

    try {
      const updatedProductData = { ...value };
      const response = await dispatch(
        updateProduct(productId, updatedProductData)
      );
      const productsToUpdate = selectedProducts.find((p) => p.id === productId);
      const updatedProduct = {
        ...productsToUpdate,
        productId: response?.id,
        storeId: response?.storeId,
        productTemplateId: response?.productTemplateId,
      };
      dispatch(updateSelectedProduct(updatedProduct));
    } catch (error) {
      console.error("Dispatch error:", error);
    }
  };
  useEffect(() => {}, [paginatedInventories?.rows]);
  const showModal = () => {
    setEditOrder(false);
  };
  return (
    <>
      {editOrder && (
        <EditOrderModale
          orders={orders}
          selectedOrders={selectedOrder}
          order={order}
          isModalVisible={editOrder}
          setIsModalVisible={showModal}
        />
      )}
      {customModal && (
        <AddCustomProduct
          setIsCustomModal={handleAddCustomProductModal}
          setIsSucces={handleAddCustomProductModalSuccess}
          isProductModal={customModal}
        />
      )}
      <CustomModal
        title="Add Inventories"
        visible={isModalVisible}
        onCancel={() => handleCancel()}
        width={1200}
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: "flex" }}>
            <Button
              key="back"
              type="primary"
              style={{
                padding: 8,
                width: 90,
                height: 52,
                borderRadius: "8px",
                border: "1px solid #DDDDDD",
                color: "black",
                background: "#F4F4F4",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 10px",
                height: 52,
                borderRadius: "8px",
                background: "#685FF9",
                right: "2%",
                position: "absolute",
              }}
              onClick={() => handleProductModal(selectedProducts)}
            >
              Add Products {`(${selectedProducts.length})`}
            </Button>
          </div>,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <SearchDiv style={{ flexGrow: 1, marginRight: "10px" }}>
            <SearchBox
              //   value={}
              //   onChange={(e) => setSearch(e)}
              placeholder={"Search Products"}
            />
          </SearchDiv>
          <Button
            key="submit"
            type="primary"
            style={{
              padding: "8px 10px",
              height: 52,
              width: 162,
              border: "1px solid ",
              color: "#685FF9",
              borderRadius: "8px",
              backgroundColor: "rgba(104, 95, 249, 0.1)",
            }}
            onClick={() => handleCustomModal()}
          >
            Add Custom Item
          </Button>
        </div>
        <Container>
          <Wrapper>
            {inventoriesLoading ? (
              <>
                <Spin />
              </>
            ) : (
              <>
                {!inventoriesLoading &&
                  paginatedInventories?.rows.map((product, key) => {
                    let rangeUsed = false;
                    const { storeRequestPriceRange } = product;
                    if (
                      storeRequestPriceRange &&
                      storeRequestPriceRange[1] &&
                      storeRequestPriceRange[0]
                    ) {
                      rangeUsed = true;
                    }
                    return (
                      <ProductRow key={key}>
                        <ProductDetails>
                          <div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                type="checkbox"
                                style={{
                                  marginLeft: "auto",
                                  borderRadius: "6px",
                                  padding: "5px",
                                  backgroundColor: isSelected
                                    ? "#685FF9"
                                    : "transparent",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the parent click event
                                  toggleSelection(product);
                                }}
                                onChange={() =>
                                  handleCategoryChange(product.id, product)
                                }
                              />
                              <img
                                src={product?.product?.image}
                                alt=""
                                style={{ height: "61px", width: "61px" }}
                              />
                            </div>
                            <div style={{ flex: "0 0 250px" }}>
                              <label
                                style={{ color: "grey", fontWeight: "500" }}
                              >
                                Name
                              </label>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.9em",
                                  marginTop: "4px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {product?.product?.title}
                                {/* <a style={{ display: "block" }}>{inventory.code}</a> */}
                              </p>
                            </div>

                            <div style={{ flex: "0 0 0px" }}>
                              <label
                                style={{ color: "grey", fontWeight: "500" }}
                              >
                                SKU
                              </label>
                              <SkuDivMargin>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {product?.product?.sku?.slice(0, 12)}
                                  {product?.product?.sku?.length > 12
                                    ? "..."
                                    : null}
                                </p>
                              </SkuDivMargin>
                            </div>
                            <div style={{ flex: "0 0 0px" }}>
                              <label
                                style={{ color: "grey", fontWeight: "500" }}
                              >
                                Condition
                              </label>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.9em",
                                  marginTop: "4px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {product?.option2Value}
                              </p>
                            </div>
                            <div style={{ flex: "0 0 0px" }}>
                              <label
                                style={{ color: "grey", fontWeight: "500" }}
                              >
                                Size
                              </label>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.9em",
                                  marginTop: "4px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {product?.option1Value}
                              </p>
                            </div>
                            <div style={{ flex: "0 0 0px" }}>
                              <label
                                style={{ color: "grey", fontWeight: "500" }}
                              >
                                Days Active
                              </label>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.9em",
                                  marginTop: "4px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {"size"}
                              </p>
                            </div>
                            <div style={{ flex: "0 0 0px" }}>
                              <label
                                style={{ color: "grey", fontWeight: "500" }}
                              >
                                Price
                              </label>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.9em",
                                  marginTop: "4px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {getSymbolFromCurrency(store.currency)}
                                {rangeUsed
                                  ? `${storeRequestPriceRange[0]} - ${storeRequestPriceRange[1]}`
                                  : product.price}
                                {product?.requestedPrice && (
                                  <b
                                    style={{ color: "green", display: "block" }}
                                  >
                                    {getSymbolFromCurrency(store.currency)}
                                    {product.requestedPrice}
                                  </b>
                                )}
                              </p>
                            </div>
                            <div style={{ flex: "0 0 0px" }}>
                              <label
                                style={{ color: "grey", fontWeight: "500" }}
                              >
                                Cost
                              </label>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.9em",
                                  marginTop: "4px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {getSymbolFromCurrency(store.currency)}
                                {product.cost
                                  ? Number(product.cost).toFixed(1)
                                  : "0"}
                              </p>
                            </div>
                            <div style={{ flex: "0 0 0px" }}>
                              <label
                                style={{ color: "grey", fontWeight: "500" }}
                              >
                                Projected Profit
                              </label>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.9em",
                                  marginTop: "4px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {getSymbolFromCurrency(store.currency)}
                                {product.cost
                                  ? Number(
                                      +product.price - +product.cost
                                    ).toFixed(1)
                                  : "0"}
                              </p>
                            </div>
                          </div>
                        </ProductDetails>
                      </ProductRow>
                    );
                  })}
              </>
            )}
          </Wrapper>
        </Container>
      </CustomModal>
    </>
  );
}
