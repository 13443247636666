export const LOGIN_LOADING: string = "LOGIN_LOADING";
export const GET_PRODUCTS: string = "GET_PRODUCTS";
export const GET_SUBMITTED_PRODUCTS: string = "GET_SUBMITTED_PRODUCTS";
export const CREATE_PRODUCT: string = "CREATE_PRODUCT";
export const GET_PRODUCT: string = "GET_PRODUCT";
export const UPDATE_PRODUCT: string = "UPDATE_PRODUCT";
export const GET_INVENTORIES: string = "GET_INVENTORIES";
export const GET_PAGINATED_INVENTORIES: string = "GET_PAGINATED_INVENTORIES";
export const GET_ORDERS_PAYOUTS_INVENTORIES: string =
  "GET_ORDERS_PAYOUTS_INVENTORIES";
export const CALCULATE_FEES: string = "CALCULATE_FEES";
export const CREATE_INVENTORY: string = "CREATE_INVENTORY";
export const ACCEPT_REQUESTED_INVENTORY: string = "ACCEPT_REQUESTED_INVENTORY";
export const DECLINE_REQUESTED_INVENTORY: string =
  "DECLINE_REQUESTED_INVENTORY";
export const SET_INVENTORIES_TO_ADD: string = "SET_INVENTORIES_TO_ADD";
export const ACCEPT_INVENTORY: string = "ACCEPT_INVENTORY";
export const DECLINE_INVENTORY: string = "DECLINE_INVENTORY";
export const WITHDRAW_INVENTORY: string = "WITHDRAW_INVENTORY";
export const ACCEPT_INVENTORY_PRICE: string = "ACCEPT_INVENTORY_PRICE";
export const DECLINE_INVENTORY_PRICE: string = "DECLINE_INVENTORY_PRICE";
export const GET_INVENTORY_FILTER_OPTIONS: string =
  "GET_INVENTORY_FILTER_OPTIONS";
export const GET_INVENTORY: string = "GET_INVENTORY";
export const UPDATE_INVENTORY: string = "UPDATE_INVENTORY";
export const GET_CONSIGNERS: string = "GET_CONSIGNERS";
export const GET_EMPLOYEES: string = "GET_EMPLOYEES";
export const GET_ORDERS: string = "GET_ORDERS";
export const RESYNC_ALL_ORDERS = "RESYNC_ALL_ORDERS";
export const RESYNC_ORDER = "RESYNC_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";
export const UPDATE_ORDER_INFO = "UPDATE_ORDER_INFO";
export const GET_PAYOUT_FILTER_OPTIONS: string = "GET_PAYOUT_FILTER_OPTIONS";
export const GET_PAYOUTS: string = "GET_PAYOUTS";
export const GET_CONSIGNER_PAYOUTS: string = "GET_CONSIGNER_PAYOUTS";
export const GET_CONSIGNER: string = "GET_CONSIGNER";
export const DELETE_CONSIGNER: string = "DELETE_CONSIGNER";
export const GET_EMPLOYEE: string = "GET_EMPLOYEE";
export const CREATE_EMPLOYEE: string = "CREATE_EMPLOYEE";
export const SEND_EMPLOYEE_INVITE: string = "SEND_EMPLOYEE_INVITE";
export const UPDATE_EMPLOYEE: string = "UPDATE_EMPLOYEE";
export const UPDATE_CONSIGNER: string = "UPDATE_CONSIGNER";
export const RESET_UPDATED_CONSIGNER: string = "RESET_UPDATED_CONSIGNER";
export const GET_ORDER: string = "GET_ORDER";
export const EXPORT_INVENTORY: string = "EXPORT_INVENTORY";
export const EXPORT_ORDERS: string = "EXPORT_ORDERS";
export const PRINT_INVENTORY: string = "PRINT_INVENTORY";
export const PAYOUT_INVENTORIES: string = "PAYOUT_INVENTORIES";
export const GET_PAYOUT: string = "GET_PAYOUT";
export const EXPORT_PAYOUT_INVENTORIES: string = "EXPORT_PAYOUT_INVENTORIES";
export const MARK_PAID_INVENTORIES: string = "MARK_PAID_INVENTORIES";
export const GET_USER: string = "GET_USER";
export const CREATE_CONSIGNER: string = "CREATE_CONSIGNER";
export const CREATE_SUBMIT_PRODUCT: string = "CREATE_SUBMIT_PRODUCT";
export const DELETE_INVENTORY: string = "DELETE_INVENTORY";
export const DELETE_EMPLOYEE: string = "DELETE_EMPLOYEE";
export const REQUEST_INVENTORY_PRICE_CHANGE: string =
  "REQUEST_INVENTORY_PRICE_CHANGE";
export const GET_CONSIGNER_REPORT: string = "GET_CONSIGNER_REPORT";
export const GET_SHOP_REPORT: string = "GET_SHOP_REPORT";
export const GET_CONSIGNER_SALE_PAYOUT: string = "GET_CONSIGNER_SALE_PAYOUT";
export const RESET_CREATED_INVENTORY: string = "RESET_CREATED_INVENTORY";
export const ACCEPT_EMPLOYEE: string = "ACCEPT_EMPLOYEE";
export const TRANSFER_INVENTORY: string = "TRANSFER_INVENTORY";
export const ACCEPT_TRANSFER_INVENTORY: string = "ACCEPT_TRANSFER_INVENTORY";
export const RESYNC_PRODUCT = "RESYNC_PRODUCT";
export const GET_STORE: string = "GET_STORE";
export const UPDATE_STORE: string = "UPDATE_STORE";
export const CREATE_STORE: string = "CREATE_STORE";
export const GET_CONSIGNERTYPES = "GET_CONSIGNERTYPES";
export const GET_CONSIGNERTYPE = "GET_CONSIGNERTYPE";
export const UPDATE_CONSIGNERTYPE = "UPDATE_CONSIGNERTYPE";
export const CREATE_CONSIGNERTYPE = "CREATE_CONSIGNERTYPE";
export const GET_PRODUCTTEMPLATE: string = "GET_PRODUCTTEMPLATE";
export const GET_PRODUCTTEMPLATES: string = "GET_PRODUCTTEMPLATES";
export const UPDATE_PRODUCTTEMPLATE: string = "UPDATE_PRODUCTTEMPLATE";
export const CREATE_PRODUCTTEMPLATE: string = "CREATE_PRODUCTTEMPLATE";
export const DELETE_PRODUCTTEMPLATE: string = "DELETE_PRODUCTTEMPLATE";
export const GET_STORE_FOR_DOMAIN: string = "GET_STORE_FOR_DOMAIN";
export const UPDATE_STORE_SHOP: string = "UPDATE_STORE_SHOP";
export const GET_SUBLOCATION: string = "GET_SUBLOCATION";
export const GET_SUBLOCATIONS: string = "GET_SUBLOCATIONS";
export const UPDATE_SUBLOCATION: string = "UPDATE_SUBLOCATION";
export const CREATE_SUBLOCATION: string = "CREATE_SUBLOCATION";
export const GET_SCAN: string = "GET_SCAN";
export const GET_SCANS: string = "GET_SCANS";
export const UPDATE_SCAN: string = "UPDATE_SCAN";
export const CREATE_SCAN: string = "CREATE_SCAN";
export const GET_INVOICE: string = "GET_INVOICE";
export const GET_INVOICES: string = "GET_INVOICES";
export const UPDATE_INVOICE: string = "UPDATE_INVOICE";
export const CREATE_INVOICE: string = "CREATE_INVOICE";
export const RESYNC_ALL_INVENTORY: string = "RESYNC_ALL_INVENTORY";
export const SEND_CONSIGNER_VERIFICATION_EMAIL: string =
  "SEND_CONSIGNER_VERIFICATION_EMAIL";
export const UPDATE_CONSIGNER_PASSWORD: string = "UPDATE_CONSIGNER_PASSWORD";
export const MERGE_PRODUCT: string = "MERGE_PRODUCT";
export const GET_INVENTORYLOGS: string = "GET_INVENTORYLOGS";
export const GET_CHEQUES: string = "GET_CHEQUES";
export const RETRY_AUTO_PAYOUT: string = "RETRY_AUTO_PAYOUT";
export const REQUEST_INVENTORY_WITHDRAWAL: string =
  "REQUEST_INVENTORY_WITHDRAWAL";
export const ACCEPT_INVENTORY_WITHDRAWAL: string =
  "ACCEPT_INVENTORY_WITHDRAWAL";
export const DECLINE_INVENTORY_WITHDRAWAL: string =
  "DECLINE_INVENTORY_WITHDRAWAL";
export const SCAN_GTIN_INVENTORY: string = "SCAN_GTIN_INVENTORY";
export const SET_IS_MOBILE = "SET_IS_MOBILE";
export const CANCEL_DROPOFF = "CANCEL_DROPOFF";
export const ADD_SELECTED_PRODUCT = "ADD_SELECTED_PRODUCT";
export const REMOVE_SELECTED_PRODUCT = "REMOVE_SELECTED_PRODUCT";
export const UPDATE_SELECTED_PRODUCT_SIZE = "UPDATE_SELECTED_PRODUCT_SIZE";
export const UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT";
export const CLEAR_SELECTED_PRODUCT = "CLEAR_SELECTED_PRODUCT";
