import {
  CREATE_PRODUCT,
  CREATE_SUBMIT_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_SUBMITTED_PRODUCTS,
  MERGE_PRODUCT,
  RESYNC_PRODUCT,
  UPDATE_PRODUCT,
  ADD_SELECTED_PRODUCT,
  REMOVE_SELECTED_PRODUCT,
  UPDATE_SELECTED_PRODUCT_SIZE,
  UPDATE_SELECTED_PRODUCT,
  CLEAR_SELECTED_PRODUCT,
} from "./types";
import { Product } from "../reducers/ProductReducer";
import { AppDispatch } from "../store";
import { FormValues as ProductFormValues } from "../../components/Common/ProductForm";
import { message } from "antd";
import { api } from "../../api";
import { Store } from "../reducers/StoreReducer";
//redux dispatchs
const getProductsLoading = () => ({
  type: GET_PRODUCTS,
  products: [],
  productsLoading: true,
});

const getProductsError = () => ({
  type: GET_PRODUCTS,
  products: [],
  productsLoading: false,
});

const getProductsSuccess = (products: Product[]) => ({
  type: GET_PRODUCTS,
  products,
  productsLoading: false,
});

const getProductLoading = () => ({
  type: GET_PRODUCT,
  product: null,
  productLoading: true,
});

const getProductError = () => ({
  type: GET_PRODUCT,
  product: null,
  productLoading: false,
});
export const addSelectedProduct = (product: any) => {
  return {
    type: ADD_SELECTED_PRODUCT,
    selectedProducts: product,
  };
};
export const updateSelectedProduct = (updatedProduct, index = null) => ({
  type: UPDATE_SELECTED_PRODUCT,
  updatedProduct,
  index,
});
export const removeSelectedProduct = (productId, size) => ({
  type: REMOVE_SELECTED_PRODUCT,
  productId,
  size,
});
export const clearSelectedProduct = () => ({
  type: CLEAR_SELECTED_PRODUCT,
});
const getProductSuccess = (product: Product) => ({
  type: GET_PRODUCT,
  product,
  productLoading: false,
});

const getSubmittedProductsLoading = () => ({
  type: GET_SUBMITTED_PRODUCTS,
  submittedProducts: [],
  submittedProductsLoading: true,
});

const getSubmittedProductsError = () => ({
  type: GET_SUBMITTED_PRODUCTS,
  submittedProducts: [],
  submittedProductsLoading: false,
});

const getSubmittedProductsSuccess = (submittedProducts: Product[]) => ({
  type: GET_SUBMITTED_PRODUCTS,
  submittedProducts,
  submittedProductsLoading: false,
});

const createProductLoading = () => ({
  type: CREATE_PRODUCT,
  createProductLoading: true,
  createdProduct: null,
});

const createProductError = () => ({
  type: CREATE_PRODUCT,
  createProductLoading: false,
  createdProduct: null,
});

const createProductSuccess = (createdProduct: Product) => ({
  type: CREATE_PRODUCT,
  createProductLoading: false,
  createdProduct,
});
const createSubmitProductLoading = () => ({
  type: CREATE_SUBMIT_PRODUCT,
  createSubmitProductLoading: true,
  createdSubmitProduct: null,
});

const createSubmitProductError = () => ({
  type: CREATE_SUBMIT_PRODUCT,
  createSubmitProductLoading: false,
  createdSubmitProduct: null,
});

const createSubmitProductSuccess = (
  createdSubmitProduct: ProductFormValues
) => ({
  type: CREATE_SUBMIT_PRODUCT,
  createSubmiteProductLoading: false,
  createdSubmitProduct,
});
const updateProductLoading = () => ({
  type: UPDATE_PRODUCT,
  updateProductLoading: true,
  updatedProduct: null,
});

const updateProductError = () => ({
  type: UPDATE_PRODUCT,
  updateProductLoading: false,
  updatedProduct: null,
});

const updateProductSuccess = (updatedProduct: Product) => ({
  type: UPDATE_PRODUCT,
  updateProductLoading: false,
  updatedProduct,
});

const clearUpdateProduct = () => ({
  type: UPDATE_PRODUCT,
  updateProductLoading: false,
  updatedProduct: null,
});

const resyncProductLoading = () => ({
  type: RESYNC_PRODUCT,
  resyncProductLoading: true,
});

const resyncProductError = () => ({
  type: RESYNC_PRODUCT,
  resyncProductLoading: false,
});

const resyncProductSuccess = () => ({
  type: RESYNC_PRODUCT,
  resyncProductLoading: false,
});

const mergeProductLoading = () => ({
  type: MERGE_PRODUCT,
  mergeProductLoading: true,
});

const mergeProductError = () => ({
  type: MERGE_PRODUCT,
  mergeProductLoading: false,
});

const mergeProductSuccess = (mergeProduct: any) => ({
  type: MERGE_PRODUCT,
  mergeProduct,
  mergeProductLoading: false,
});

//api calls
const getProductsFromAPI = async (search: string) => {
  const { data, error } = await api.provide("get", "/api/products", {
    search,
  });
  if (error) throw error;
  return (data?.products || []) as any;
};

const getStoreProductsFromAPI = async (search: string, location: string) => {
  const { data, error } = await api.provide(
    "get",
    "/api/inventories/getStoreProducts",
    {
      search,
      location,
    }
  );
  if (error) throw error;
  return (data?.products || []) as any;
};

export const getStoreProductsForCatalogueFromAPI = async (
  search: string,
  location: string,
  handle: string
) => {
  const { data, error } = await api.provide(
    "get",
    "/open/stores/getStoreProducts",
    {
      search,
      location,
      handle,
    }
  );
  if (error) throw error;
  return (data || {}) as any;
};

export const getStoreBuyandSellInfoFromAPI = async (
  search: string,
  handle: string
) => {
  const { data, error } = await api.provide(
    "get",
    "/open/stores/getStoreBuyandSellInfo",
    {
      search,
      handle,
    }
  );
  if (error) throw error;
  return (data || {}) as any;
};

const getProductFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/products/:id", {
    id,
  });
  if (error) throw error;
  return {
    ...(data?.product || {}),
    duplicateProducts: data?.duplicateProducts,
  };
};

export const getShopifyProductsFromAPI = async (
  shopifyProductIds: string[]
) => {
  const { data, error } = await api.provide(
    "post",
    "/api/products/shopifyProducts",
    {
      shopifyProductIds,
    }
  );
  if (error) throw error;
  return data;
};

const getSubmittedProductsFromAPI = async (search: string) => {
  const { data, error } = await api.provide(
    "get",
    "/api/products/submittedProducts",
    {
      search,
    }
  );
  if (error) throw error;
  return (data?.submittedProducts || []) as any;
};

const createProductFromAPI = async (product: ProductFormValues) => {
  const payload = {
    title: product.title,
    brand: product?.brand,
    category: product?.category,
    sku: product?.sku,
    image: product?.images,
    productTemplateId: product?.productTemplateId,
    gender: product?.gender,
    GTIN: product?.GTIN,
  };

  const { data, error } = await api.provide("post", "/api/products", payload);
  if (error) throw error;
  return data;
};

const createSubmitProductFromAPI = async (
  productFormValues: ProductFormValues
) => {
  const payload = {
    title: productFormValues.title,
    brand: productFormValues.brand,
    category: productFormValues.category,
    sku: productFormValues.sku,
    images: productFormValues.images,
    productTemplateId: productFormValues.productTemplateId,
    gender: productFormValues.gender,
  };

  const { data, error } = await api.provide(
    "post",
    "/api/products/submittedProducts",
    payload
  );
  if (error) throw error;
  return data as any;
};

const updateProductFromAPI = async (id: string, product: ProductFormValues) => {
  const payload = {
    id,
    title: product.title,
    brand: product.brand,
    category: product.category,
    sku: product.sku,
    stockXId: product.stockXId,
    shopifyId: product.shopifyId,
    images: product.images || product.image,
    productTemplateId: product.productTemplateId,
    gender: product.gender,
    GTIN: product.GTIN,
  };

  const { data, error } = await api.provide(
    "put",
    "/api/products/:id",
    payload
  );
  if (error) throw error;
  return data;
};

export const updateProductFn = async (
  id: string,
  product: ProductFormValues,
  store: Store
) => {
  const payload = {
    id,
    title: product.title,
    brand: product.brand,
    category: product.category,
    sku: product.sku,
    stockXId: product.stockXId,
    shopifyId: product.shopifyId,
    images: product.images || product.image,
    productTemplateId: product.productTemplateId,
    gender: product.gender,
    GTIN: product.GTIN,
    store,
  };

  const { data, error } = await api.provide(
    "put",
    "/open/stores/products/:id",
    payload
  );
  if (error) throw error;
  return data;
};

export const resyncProductFromAPI = async (id: string) => {
  message.info("Resyncing product in background");

  const { data, error } = await api.provide("get", "/api/products/resync/:id", {
    id,
  });
  if (error) throw error;
  return data;
};

const mergeProductFromAPI = async (
  fromProductId: string,
  toProductId: string
) => {
  message.info("Merging product in background");

  const { data, error } = await api.provide("post", "/api/products/merge", {
    fromProductId,
    toProductId,
  });
  if (error) throw error;
  return data;
};

export const fetchProductLowestPricesFromAPI = async (id: string) => {
  const { data, error } = await api.provide(
    "get",
    "/api/products/lowestVariantPrices/:id",
    {
      id,
    }
  );
  if (error) throw error;
  return data;
};

//actions
export const getProducts = (search: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getProductsLoading());
    try {
      dispatch(getProductsSuccess(await getProductsFromAPI(search)));
    } catch (e) {
      dispatch(getProductsError());
    }
  };
};
//actions
export const getStoreProducts = (search: string, location: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getProductsLoading());
    try {
      dispatch(
        getProductsSuccess(await getStoreProductsFromAPI(search, location))
      );
    } catch (e) {
      dispatch(getProductsError());
    }
  };
};
export const getStoreProductsForCatalogue = (
  search: string,
  location: string,
  handle: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getProductsLoading());
    try {
      dispatch(
        getProductsSuccess(
          await getStoreProductsForCatalogueFromAPI(search, location, handle)
        )
      );
    } catch (e) {
      dispatch(getProductsError());
    }
  };
};
export const getProduct = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getProductLoading());
    try {
      dispatch(getProductSuccess(await getProductFromAPI(id)));
    } catch (e) {
      dispatch(getProductError());
    }
  };
};
export const getSubmittedProducts = (search: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getSubmittedProductsLoading());
    try {
      dispatch(
        getSubmittedProductsSuccess(await getSubmittedProductsFromAPI(search))
      );
    } catch (e) {
      dispatch(getSubmittedProductsError());
    }
  };
};
export const createProduct = (product: ProductFormValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createProductLoading());
    try {
      const createdProduct = await createProductFromAPI(product);
      dispatch(createProductSuccess(createdProduct));
      return createdProduct; // Return the created product data
    } catch (e) {
      dispatch(createProductError());
      throw e; // Throw the error so it can be caught in the component
    }
  };
};
export const createSubmitProduct = (productFormValues: ProductFormValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createSubmitProductLoading());
    try {
      dispatch(
        createSubmitProductSuccess(
          await createSubmitProductFromAPI(productFormValues)
        )
      );
    } catch (e) {
      dispatch(createSubmitProductError());
    }
  };
};
export const updateProduct = (id: string, product: ProductFormValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateProductLoading());
    try {
      const response = await updateProductFromAPI(id, product);
      dispatch(updateProductSuccess(response));
      return response; // Return the response
    } catch (e) {
      dispatch(updateProductError());
      throw e; // Re-throw the error to handle it in the caller function
    }
  };
};

export const resyncProduct = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(resyncProductLoading());
    try {
      await resyncProductFromAPI(id);
      dispatch(resyncProductSuccess());
    } catch (e) {
      dispatch(resyncProductError());
    }
  };
};
export const mergeProduct = (fromProductId: string, toProductId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(mergeProductLoading());
    try {
      dispatch(
        mergeProductSuccess(
          await mergeProductFromAPI(fromProductId, toProductId)
        )
      );
    } catch (e) {
      dispatch(mergeProductError());
    }
  };
};
export const clearUpdatedProduct = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(clearUpdateProduct());
  };
};

export default {
  getProducts,
  getSubmittedProducts,
  createProduct,
  getProduct,
  updateProduct,
  mergeProduct,
};
