import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import { jsPDF } from "jspdf";
import {
  RouteComponentProps,
  useHistory,
  useParams,
  Redirect,
} from "react-router";
import { Spin, Modal, message, Input } from "antd";
import QRCode from "qrcode";
import HorizontalPageCard, {
  DropdownSelectorItem,
} from "../components/Common/HorizontalPageCard";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  deleteInventory,
  getInventory,
  getInventoryFilterOptions,
  updateInventory,
  withdrawInventory,
} from "../redux/actions/inventoryActions";
import { getInventoryLogs } from "../redux/actions/inventoryLogActions";
import Content from "../constants/Content";
import { InventoryState, Inventory } from "../redux/reducers/InventoryReducer";
import { Consigner } from "../redux/reducers/ConsignerReducer";
import EditableInventoryCard from "../components/Common/EditableInventoryCard";
import InventoryTimeline from "../components/Common/InventoryTimeline";
import { UserState } from "../redux/reducers/UserReducer";
import logo from "../assets/images/logo.png";
import moment from "moment";
import { Store } from "../redux/reducers/StoreReducer";
import { getProductTemplates } from "../redux/actions/productTemplateActions";
import getSymbolFromCurrency from "currency-symbol-map";
import autoTable from "jspdf-autotable";
import {
  getProduct,
  getShopifyProductsFromAPI,
  resyncProductFromAPI,
} from "../redux/actions/productActions";

/**
 * Inventory Item Screen
 * renders a screen where user can edit a singular inventory item
 * TODO Tests:
 *  - renders correctly (horizontal page card, editable inventory list)
 *  - print packing slip
 *  - change consigner
 *  - on editable card  changes should update inventory
 * @returns
 */

//styles
const Container = styled.div``;

interface RouteParams {
  id: string;
}

export interface UpdateInventoryFormValues {
  consigner: Consigner;
  payoutFee: string;
  price: string;
  option1Value: string;
  option2Value: string;
  option3Value: string;
  location: string;
  subLocation: string;
  payout: string;
  cost: string | null;
  status: string;
  notes: string;
}
export const printLabel = async (inventories: Inventory[], store: Store) => {
  let height = Number(store.labelSize.height);
  let width = Number(store.labelSize.width);
  let qrCourtOrderHeight = 50;
  let qrCourtOrderWidth = 60;
  const storeId = store.id;
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: [height, width],
  });

  const shopifyProductIds: any = inventories.map(
    (inventory) => inventory.product.shopifyId
  );
  const shopifyProducts = await getShopifyProductsFromAPI(shopifyProductIds);

  for (let i = 0; i < inventories.length; i++) {
    const inventory = inventories[i];
    const { code } = inventory;
    let variantId;
    let shopifyVariant;

    const shopifyProduct = (shopifyProducts?.products || []).find(
      (product: any) => product.id == inventory.product.shopifyId
    );

    // Rare instance where both the inventory and product do not have a shopifyId. Resync the product from the API
    if (!inventory.shopifyId && !inventory.product.shopifyId) {
      message.error(
        "Resynced items without Shopify ID. Please try again in a few seconds.",
        10
      );
      resyncProductFromAPI(inventory.product.id);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      continue;
    }

    if (!shopifyProduct || !shopifyProduct.variants) {
      message.error(
        `No Shopify Product Found for ${inventory.product.title}. Resyncing`,
        10
      );
      resyncProductFromAPI(inventory.product.id);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      continue;
    }

    // Make sure the product options are not null for check with inventory. They must be an empty string not null.
    shopifyProduct.variants?.map((variant: any) =>
      variant.option1 == null ? (variant.option1 = "") : variant.option1
    );
    shopifyProduct.variants?.map((variant: any) =>
      variant.option2 == null ? (variant.option2 = "") : variant.option2
    );
    shopifyProduct.variants?.map((variant: any) =>
      variant.option3 == null ? (variant.option3 = "") : variant.option3
    );

    if (shopifyProduct) {
      // set the variant if the product has any variants
      shopifyVariant = shopifyProduct.variants.find(
        (variant: any) =>
          variant.option1 == inventory.option1Value &&
          variant.option2 == inventory.option2Value &&
          variant.option3 == inventory.option3Value
      );
    }
    doc.setFontSize(8);
    let qrCode = await QRCode.toDataURL(code);
    let shopifyQrCode = "";
    if (store.labelOptions.find((item: any) => item == "Shopify QR Code")) {
      if (inventory.shopifyId != null) {
        variantId = inventory.shopifyId;
      } else {
        if (shopifyVariant) {
          variantId = shopifyVariant.id
            ? shopifyVariant.id
            : shopifyVariant[0].id;
        } else {
          variantId = inventory.shopifyId || inventory.product.shopifyId;
        }

        let filtersStockx = {
          printed: "",
          status: "Active",
          option1Value: inventory.option1Value,
          option2Value: inventory.option2Value,
          option3Value: inventory.option3Value,
          category: "",
          consigner: "",
          productId: inventory.productId,
        };
      }

      let shopifyTrackingParams = "";
      if (
        store.shopifyTrackingParams &&
        store.shopifyTrackingParams.length > 0
      ) {
        const outputParams = (arr: any) => {
          // initialize the output string
          let output = "&";

          for (let i = 0; i < arr.length; i++) {
            let obj = arr[i];
            let param = obj.param;
            let value = obj.value;

            output += `${param}=${value}`;
            // add an ampersand to the output string if this is not the last object in the array
            if (i < arr.length - 1) {
              output += "&";
            }
          }
          return output;
        };
        shopifyTrackingParams = outputParams(store.shopifyTrackingParams);
      }
      if (store.customShopifyUrl != "" && store.customShopifyUrl != null) {
        shopifyQrCode = await QRCode.toDataURL(
          `https://${store.customShopifyUrl!}/products/${shopifyProduct.handle!}?variant=${variantId}${shopifyTrackingParams}`
        );
      } else {
        shopifyQrCode = await QRCode.toDataURL(
          `https://${store.shop!
            .shop!}/products/${shopifyProduct.handle!}?variant=${variantId}${shopifyTrackingParams}`
        );
      }
    }

    let images = await Promise.all(
      store.labelOptions.map(async (item: any) => {
        if (item == "Logo" && store?.logo) {
          let imageFormat = "jpeg";
          if (store.logo.endsWith(".png")) {
            imageFormat = "png";
          } else if (
            store.logo.endsWith(".jpg") ||
            store.logo.endsWith(".jpeg")
          ) {
            imageFormat = "jpeg";
          }
          doc.addImage(
            store?.logo!,
            imageFormat,
            width - 22.5,
            height - height,
            width - width + 20,
            height - height + 8
          );
        } else if (item == "Scan To Shop") {
          if (height < 33) {
            doc.setFontSize(5);
            doc.text("SCAN TO SHOP", width - 24, height - height + 23.5, {
              angle: 90,
            });
          } else {
            doc.setFontSize(6.5);
            doc.text("SCAN TO SHOP", width - 26, height - height + 26, {
              angle: 90,
            });
          }
        } else if (item == "QR Code") {
          if (storeId === 101) {
            // safe updates
            doc.addImage(
              qrCode,
              "png",
              width - (height - 8),
              height - height + 8,
              height - 8,
              height - 8
            );
          } else {
            doc.addImage(
              qrCode,
              "png",
              width - 25,
              height - height + 8,
              width - width + 25,
              height - height + 20
            );
          }
        } else if (item == "Shopify QR Code") {
          if (height < 33 || storeId === 32) {
            doc.addImage(
              shopifyQrCode,
              "png",
              width - 21.5,
              height - height + 9,
              storeId === 32 ? qrCourtOrderWidth : width - width + 20,
              storeId === 32 ? qrCourtOrderHeight : height - height + 16
            );
          } else {
            doc.addImage(
              shopifyQrCode,
              "png",
              width - 25,
              height - height + 8,
              width - width + 25,
              height - height + 18
            );
          }
        }
      })
    );
    //remove QR code and label from array
    const noImages = store.labelOptions.filter(
      (item: any) => item !== "Logo" && item !== "QR Code"
    );

    let bodyNoImg: any;
    bodyNoImg = noImages.map((item: any) =>
      //if not logo, all text
      {
        try {
          if (item === "Item Description") {
            return [`${inventory.product.title}`];
          } else if (item === "Item Id") {
            return [`${inventory.code}`];
          } else if (item === "SKU") {
            return [`${inventory.product.sku}`];
          } else if (item === "Consigner Id") {
            return [`Consigner Id: ${inventory.consigner.id}`];
          } else if (item === "Consigner") {
            return [
              `Consigner: ${inventory.consigner.firstName} ${inventory.consigner.lastName}`,
            ];
          } else if (item === "Size") {
            return [
              `${inventory.option1Value} / ${inventory.option2Value} / ${inventory.option3Value}`,
            ];
          } else if (item === "Payout Fee") {
            return [
              `${getSymbolFromCurrency(store.currency)}${inventory.payoutFee}`,
            ];
          } else if (item === "Price") {
            return [
              `${getSymbolFromCurrency(store.currency)}${inventory.price}`,
            ];
          } else if (item === "Sublocation" && inventory.subLocation !== null) {
            return [`${inventory.subLocation}`];
          }
        } catch (e) {
          console.log(e);
        }
      }
    );

    const filteredBody = bodyNoImg.filter(
      (item: undefined) => item !== undefined
    );

    autoTable(doc, {
      theme: "plain",
      body: filteredBody,
      startY: 2,
      showHead: "firstPage",
      styles: {
        // overflow: "hidden",
        fontSize: height < 33 ? 7 : 8,
        cellPadding: 0,
        cellWidth: "wrap",
      },
      margin: { left: 2, right: width / 3, bottom: 0 },
      tableWidth: "wrap",
    });

    doc.setPage(1);

    autoTable(doc, {
      theme: "plain",
      startY: 0,
      showHead: "firstPage",
      styles: { overflow: "hidden", cellPadding: 0 },
      tableWidth: "wrap",
      margin: {
        top: 0,
        // left: ,
        right: 0,
        bottom: 0,
      },
      body: [[""], [""]],
      didDrawCell: function (data: any) {
        // doc.addImage(store?.logo!, width - 22, 0, 22, height / 2);
        // doc.addImage(qrCode, "png", width - 15, 15, 15, height / 2);
        images;
      },
    });

    if (i + 1 !== inventories.length) {
      doc.addPage();
    }
  }
  doc.save("inventory_label.pdf");
};

const handleQrCodeImage = async (
  code: string,
  id: string,
  store: any,
  setQrCodeImage: (image: string) => void
) => {
  setQrCodeImage(await QRCode.toDataURL(`${store.domain}/inventories/${id}`));
};

export const getDropDownValuesForConsigner = (
  consigners: any
): DropdownSelectorItem[] => {
  return consigners.map((consigner: any) => {
    return {
      label: consigner.label,
      value: JSON.stringify(consigner.value),
    };
  });
};

const LabelToPrintContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  height: 29mm;
  width: 90.3mm;
`;
const LabelProductText = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
`;
const Logo = styled.img`
  height: 20px;
  object-fit: contain;
`;

const InventoryItem = () => {
  const { id }: RouteParams = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [selectedConsigner, setSelectedConsigner] = useState("");
  const [selectedOption1Value, setSelectedOption1Value] = useState("");
  const [selectedOption2Value, setSelectedOption2Value] = useState("");
  const [selectedOption3Value, setSelectedOption3Value] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedPayoutFee, setSelectedPayoutFee] = useState("");
  const [selectedPayout, setSelectedPayout] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedSubLocation, setSelectedSubLocation] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedNotes, setSelectedNotes] = useState("");
  const [updateCall, setUpdateCall] = useState<any>(null);
  const printRef = useRef(null);
  const {
    inventory,
    inventoryLoading,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
    updatedInventory,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const { inventoryLogs } = useAppSelector(
    (state) => state.InventoryLogReducer
  );
  const {
    product,
    productLoading,
    updateProductLoading,
    resyncProductLoading,
    updatedProduct,
    mergeProduct,
  } = useAppSelector((state) => state.ProductReducer);
  const {
    productTemplate,
    productTemplates,
    productTemplateLoading,
    productTemplatesLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  const isStoreAccount = () => {
    try {
      if (selectedConsigner && selectedConsigner !== "") {
        const consigner = JSON.parse(selectedConsigner);
        return consigner.isStoreAccount === true ? true : false;
      }
    } catch (e) {
      return false;
    }
  };

  const resetInventory = () => {
    setQrCodeImage("");
    setSelectedConsigner("");
    setSelectedOption1Value("");
    setSelectedOption2Value("");
    setSelectedOption3Value("");
    setSelectedPrice("");
    setSelectedPayout("");
    setSelectedLocation("");
    setSelectedSubLocation("");
    setSelectedStatus("");
    setSelectedNotes("");
  };
  message.config({ maxCount: 1 });

  useEffect(() => {
    //get inventory on load
    dispatch(getInventory(id));
    return () => {
      resetInventory();
    };
  }, []);

  useEffect(() => {
    //get consigner values for dropdopw
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());
  }, [inventory]);

  useEffect(() => {
    //get products here any time id changes
    if (inventory && inventory.id) {
      dispatch(getInventoryLogs(inventory.id));
    }
  }, [inventory]);

  useEffect(() => {
    //get products here any time id changes
    if (inventory && inventory.productId) {
      dispatch(getProduct(inventory.productId));
    }
  }, [inventory]);

  useEffect(() => {
    if (updatedInventory && updatedInventory.length !== 0 && updateCall) {
      if (updatedInventory.error) {
        message
          .loading("Saving", 1)
          .then(() => message.error(updatedInventory.error));
      } else {
        message.loading("Saving", 1).then(() => message.success("Saved"));
      }
      setUpdateCall(null);
    }
  }, [updatedInventory]);

  useEffect(() => {
    //reload qrcode image whenever inventory changes
    if (inventory) {
      const { code, id } = inventory;
      handleQrCodeImage(code, id, store, setQrCodeImage);
    }
  }, [inventory]);
  useEffect(() => {
    //set editable inventory values whenever inventory changes
    if (inventory) {
      setSelectedConsigner(JSON.stringify(inventory.consigner));
      setSelectedPrice(inventory.price);
      setSelectedPayout(inventory.payoutAmount);
      setSelectedOption1Value(inventory.option1Value);
      setSelectedOption2Value(inventory.option2Value);
      setSelectedOption3Value(inventory.option3Value);
      setSelectedLocation(inventory.location);
      setSelectedSubLocation(inventory.subLocation);
      setSelectedStatus(inventory.status);
      setSelectedNotes(inventory.notes);
    }
  }, [inventory]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  const handleSave = () => {
    if (selectedConsigner && inventory && Number(inventory.id) === Number(id)) {
      dispatch(
        updateInventory(id, {
          consigner: JSON.parse(selectedConsigner),
          price: selectedPrice,
          payoutFee: selectedPayoutFee,
          option1Value: selectedOption1Value,
          option2Value: selectedOption2Value,
          option3Value: selectedOption3Value,
          location: selectedLocation,
          subLocation: selectedSubLocation,
          cost: isStoreAccount() ? selectedPayout : null,
          payout: selectedPayout,
          status: selectedStatus,
          notes: selectedNotes,
        })
      );
      setUpdateCall(true);
    }
  };

  const handleChangingStatuses = (newStatus: string) => {
    if (
      newStatus === "Active" &&
      (!inventory?.location ||
        inventory?.location === "" ||
        !inventory?.acceptedOn)
    ) {
      //Force accepting from pending
      message.error(
        "This item has not been accepted, you must accept it via the incoming tab."
      );
    } else {
      setSelectedStatus(newStatus);
    }
  };

  const handleWithdrawInventory = async (inventory: Inventory) => {
    if (inventory.status === "Pending") {
      //Show a modal with a delete warning and call delete inventory
      Modal.confirm({
        title: "Are you sure delete this item?",
        content:
          "This item is currently pending and deleting it will remove it from the system.",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: () => {
          dispatch(deleteInventory(inventory));
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else if (
      inventory.status === "Active" ||
      inventory.status === "RequestPriceChange"
    ) {
      //Show a modal with withdrawal warning
      // //Check if we are adding a fee
      const acceptedOnDate = moment(inventory.acceptedOn);
      var current = moment();
      const daysSinceAccept = current.diff(acceptedOnDate, "days");

      let isWithdrawnEarly = false;
      let withdrawalFee = 0;
      if (daysSinceAccept <= Content.WITHDRAW_EARLY_AMOUNT_OF_DAYS) {
        //There is a withdrawal fee
        withdrawalFee = Content.WITHDRAW_FEE;
        isWithdrawnEarly = true;
      }
      Modal.confirm({
        title: "Are you sure you wish to withdraw this item?",
        content: `This item will be removed from the site fully. ${
          isWithdrawnEarly
            ? `This item is being withdrawn within the cutoff of ${Content.WITHDRAW_EARLY_AMOUNT_OF_DAYS} days and will be charged a fee of ${store?.currency}${withdrawalFee} on the consigners account`
            : ""
        }`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: () => {
          dispatch(
            withdrawInventory(inventory, isWithdrawnEarly, withdrawalFee)
          );
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Inventory")
  ) {
    return <Redirect to="/" />;
  }

  if (
    inventoryLoading ||
    !inventory ||
    // !productTemplate ||
    inventoryFilterOptionsLoading ||
    !inventoryFilterOptions
  ) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }
  return (
    <Container>
      <HorizontalPageCard
        image={qrCodeImage}
        title={`${inventory.product.title} - Status: ${inventory.status} - Code: ${inventory.code}`}
        subtitle={`${inventory.product.sku}`}
        primaryAction={() => printLabel([inventory], store as Store)}
        primaryActionText={Content.INVENTORY_ITEM_ACTION_TEXT}
        dropdownSelectorItems={getDropDownValuesForConsigner(
          inventoryFilterOptions.consigners
        )}
        dropdownSelectorValue={selectedConsigner}
        onDropdownChange={(value) => setSelectedConsigner(value)}
        dropdownSelectorItemsTwo={inventoryFilterOptions.statuses}
        dropdownSelectorValueTwo={selectedStatus}
        onDropdownChangeTwo={(value) => handleChangingStatuses(value)}
        inventoryStatus={inventory.status}
        safeInventoryStatusMode={store.safeInventoryStatusMode}
      />

      <EditableInventoryCard
        id={id}
        category={inventory.product.category}
        consigner={selectedConsigner}
        option1={inventory.productTemplate?.option1}
        option2={inventory.productTemplate?.option2}
        option3={inventory.productTemplate?.option3}
        image={inventory.product.image}
        price={selectedPrice}
        payoutFee={selectedPayoutFee}
        payout={selectedPayout}
        location={selectedLocation}
        productTemplate={
          product &&
          productTemplates.find(
            (template) => template.id === product?.productTemplateId
          )
        }
        option1Value={selectedOption1Value}
        option2Value={selectedOption2Value}
        option3Value={selectedOption3Value}
        subLocation={selectedSubLocation}
        title={Content.EDIT_INVENTORY_CARD_TITLE}
        locationValues={inventoryFilterOptions.locations}
        option1Values={inventory.productTemplate?.option1Values}
        option2Values={inventory.productTemplate?.option2Values}
        option3Values={inventory.productTemplate?.option3Values}
        setOption1Value={setSelectedOption1Value}
        setOption2Value={setSelectedOption2Value}
        setOption3Value={setSelectedOption3Value}
        setPayoutFee={setSelectedPayoutFee}
        setPrice={setSelectedPrice}
        setPayout={setSelectedPayout}
        setLocation={setSelectedLocation}
        setSubLocation={setSelectedSubLocation}
        setSelectedNotes={setSelectedNotes}
        selectedNotes={selectedNotes}
        status={inventory.status}
        onDelete={() => handleWithdrawInventory(inventory)}
        onSave={() => handleSave()}
        isStoreAccount={isStoreAccount}
      />
      <InventoryTimeline inventoryLogs={inventoryLogs} />
    </Container>
  );
};

export default InventoryItem;
