import {
  GET_INVOICE,
  GET_INVOICES,
  UPDATE_INVOICE,
  CREATE_INVOICE,
} from "../actions/types";
import { Inventory } from "./InventoryReducer";

export type Invoice = any;

export interface InvoiceState {
  invoices: Invoice[];
  invoicesLoading: boolean;
  invoice: Invoice | null;
  invoiceLoading: boolean;
  updatedInvoice: Invoice | null;
  updatedInvoiceLoading: boolean;
  createdInvoice: Invoice | null;
  createdInvoiceLoading: boolean;
  inventories: Inventory[];
}

const defaultState = {
  invoices: [],
  invoicesLoading: false,
  invoice: null,
  invoiceLoading: false,
  updatedInvoice: null,
  updatedInvoiceLoading: false,
  createdInvoice: null,
  createdInvoiceLoading: false,
  inventories: [],
};

export const DefaultInvoiceState = defaultState;

export type InvoiceAction = {
  type: string;
  invoices: Invoice[];
  invoicesLoading: boolean;
  invoice: Invoice | null;
  invoiceLoading: boolean;
  updatedInvoice: Invoice | null;
  updatedInvoiceLoading: boolean;
  createdInvoice: Invoice | null;
  createdInvoiceLoading: boolean;
  inventories: Inventory[];
};

export default (state: InvoiceState = defaultState, action: InvoiceAction) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        invoices: action.invoices,
        invoicesLoading: action.invoicesLoading,
      };
    case GET_INVOICE:
      return {
        ...state,
        invoice: action.invoice,
        invoiceLoading: action.invoiceLoading,
        inventories: action.inventories,
      };
    case UPDATE_INVOICE:
      return {
        ...state,
        updatedInvoice: action.updatedInvoice,
        updatedInvoiceLoading: action.updatedInvoiceLoading,
      };
    case CREATE_INVOICE:
      return {
        ...state,
        createdInvoice: action.createdInvoice,
        createdInvoiceLoading: action.createdInvoiceLoading,
        inventories: action.inventories,
      };
    default:
      return state;
  }
};
