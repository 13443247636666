import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Checkbox, DatePicker, Input, Spin, message } from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getProducts } from "../redux/actions/productActions";
import ProductList from "../components/Common/ProductList";
import { Redirect } from "react-router-dom";
import { UserState } from "../redux/reducers/UserReducer";
import ProductTemplates from "./ProductTemplates";
import { getProductTemplates } from "../redux/actions/productTemplateActions";
import RedesignStyling from "../constants/RedesignStyling";
import {
  ActionItem,
  FooterCustom,
  ModalHeadAction,
  PromptModal,
  ReviewModal,
  ReviewModalTable,
} from "../consignerScreens/ConsignerProducts";
import getSymbolFromCurrency from "currency-symbol-map";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import {
  createInventories,
  getInventoryFilterOptions,
  resetCreatedInventory,
  setInventoriesToAddAction,
} from "../redux/actions/inventoryActions";
import { Box, Link, Slide, Modal, ButtonGroup } from "@mui/material";
import { StoreState } from "../redux/reducers/StoreReducer";
import _ from "lodash";
import { handleAddInventory } from "./AddInventory";
import { AppDispatch } from "../redux/store";
import { AppState } from "../redux/reducers/AppReducer";
const { Search } = Input;
import SuccessImg from "../assets/images/Success.png";
import { InvoiceState } from "../redux/reducers/InvoiceReducer";
import { createInvoice, updateInvoice } from "../redux/actions/invoiceActions";
import { Moment } from "moment";

/*
Products Component
- products are fetched from getProducts API (redux/actions/productActions) and added into the Products Reducer 
- products fetch on load and everytime search is updated
Returns Component with
- Search Component
- List of Products
*/

//styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

const CustomButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  .ant-btn {
    height: 54px;
    padding: 14px 16px;
    gap: 10px;
    flex: 0 0 auto;
    display: flex;
  }
  & > input {
    height: 54px;
    flex: 0 0 227px;
  }
  & > div {
    height: 54px;
    flex: 0 0 183px;
  }
`;

interface ResellerReviewModalProps {
  open: boolean;
  handleClose: any;
  inventoriesToAdd: Array<Inventory>;
  addInventoriesLoading: boolean;
  setAddInventoriesLoading: any;
  dispatch: AppDispatch;
  setInventoriesToAdd: any;
}

const ResellerReviewModal = (props: ResellerReviewModalProps): JSX.Element => {
  const {
    open,
    handleClose,
    inventoriesToAdd,
    addInventoriesLoading,
    setAddInventoriesLoading,
    dispatch,
    setInventoriesToAdd,
  } = props;
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const { createdInventory, createdInventoryLoading }: InventoryState =
    useAppSelector((state) => state.InventoryReducer);
  const { createdInvoice }: InvoiceState = useAppSelector(
    (state) => state.InvoiceReducer
  );
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [pendingPayment, setPendingPayment] = useState(false);
  const [createInvoiceFlag, setCreateInvoiceFlag] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(false);
  const [trackingCode, setTrackingCode] = useState("");
  const [purchaseDate, setPurchaseDate] = useState<Moment | null>(null);

  useEffect(() => {
    if (!_.isEmpty(createdInventory)) {
      console.log(createdInventory);
      setInventoriesToAdd([]);
      handleClose();
      setOpenSuccessModal(true);
      dispatch(resetCreatedInventory());
      setReadyToSubmit(false);
      message.destroy(1);
      // history.push("/inventory");
    }
  }, [createdInventory]);
  const handleCancelAddInventory = () => {
    // setInventoriesToAdd([]);
    handleClose();
  };

  useEffect(() => {
    console.log(inventoriesToAdd);
  }, [inventoriesToAdd]);

  useEffect(() => {
    if (createInvoiceFlag) {
      if (createdInvoice) {
        setInventoriesToAdd(
          inventoriesToAdd.map((inventory) => ({
            ...inventory,
            invoiceId: createdInvoice.id,
          }))
        );
      } else {
        dispatch(
          createInvoice(
            trackingCode,
            purchaseDate
              ? purchaseDate?.toISOString()
              : new Date().toISOString(),
            JSON.parse(inventoriesToAdd.at(0)?.consigner).id
          )
        );
      }
    }
  }, [createdInvoice, createInvoiceFlag]);

  useEffect(() => {
    let statusData = "Active";
    if (purchaseOrder) {
      statusData = "Pending";
    }
    setInventoriesToAdd(
      inventoriesToAdd.map((inventory) => ({
        ...inventory,
        status: statusData,
      }))
    );
  }, [purchaseOrder]);

  useEffect(() => {
    let statusData = "Active";
    if (pendingPayment) {
      statusData = "Buying";
      setInventoriesToAdd(
        inventoriesToAdd.map((inventory) => ({
          ...inventory,
          status: statusData,
          buyingId: JSON.parse(inventoriesToAdd.at(0)?.consigner).id,
        }))
      );
    } else {
      setInventoriesToAdd(
        inventoriesToAdd.map((inventory) => ({
          ...inventory,
          status: statusData,
        }))
      );
    }
  }, [pendingPayment]);

  useEffect(() => {
    if (purchaseDate) {
      setInventoriesToAdd(
        inventoriesToAdd.map((inventory) => ({
          ...inventory,
          acceptedOn: purchaseDate?.toISOString(),
        }))
      );
    }
  }, [purchaseDate]);

  const handleChangeTrackingCode = async (trackCode: string) => {
    setTrackingCode(trackCode);
    if (createdInvoice) {
      await dispatch(
        updateInvoice(
          createdInvoice.id,
          createdInvoice.code,
          trackCode,
          createdInvoice.invoiceDate,
          createdInvoice.consignerId
        )
      );
    } else {
      await dispatch(
        createInvoice(
          trackingCode,
          purchaseDate?.toISOString(),
          JSON.parse(inventoriesToAdd.at(0)?.consigner).id
        )
      );
    }
  };

  const handleAddInventoryData = async () => {
    message.loading({
      content: "Creating inventories. Please wait...",
      key: 1,
      duration: 0,
    });
    console.log("====inventoriesToAdd====", inventoriesToAdd);

    await dispatch(createInventories(inventoriesToAdd));
    setAddInventoriesLoading(true);
  };
  return (
    <div>
      <PromptModal
        heading="Your product has been added successfully"
        open={openSuccessModal}
        setOpen={setOpenSuccessModal}
        Img={SuccessImg}
      />
      <Modal
        open={open}
        onClose={handleClose}
        style={{ zIndex: 99, top: 0 }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        hideBackdrop
      >
        <Slide direction="up" in={open}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              maxWidth: "100vw",
              height: "100%",
              bgcolor: RedesignStyling.PAGE_BG,
              p: 2,
              overflowY: "scroll",
            }}
          >
            <ModalHeadAction>
              <div>
                <ActionItem>
                  <p>
                    <Link
                      style={{ textDecoration: "none" }}
                      onClick={handleCancelAddInventory}
                    >
                      Reviews
                    </Link>
                  </p>
                  <img
                    style={{ transform: "rotateZ(180deg)" }}
                    src="/svg/CaretDown.svg"
                    alt=""
                    id="reviews"
                  />
                </ActionItem>
                <ActionItem>
                  <p>Added Items</p>
                  <b id="addedItems">
                    {inventoriesToAdd.reduce(
                      (sum, inventory: any) => sum + inventory.quantity,
                      0
                    )}
                  </b>
                </ActionItem>
                <ActionItem>
                  <p>Values</p>
                  <b id="values">
                    {getSymbolFromCurrency(store?.currency)}
                    {inventoriesToAdd
                      .reduce(
                        (sum, inventory: any) =>
                          sum +
                          Number(inventory.payout ?? 0) * inventory.quantity,
                        0
                      )
                      .toFixed(2)}
                  </b>
                </ActionItem>
                <ActionItem style={{ flexDirection: "row" }}>
                  <Button
                    onClick={handleCancelAddInventory}
                    type="text"
                    style={{
                      width: 166,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid="addInventoryBtn"
                    onClick={async () => {
                      await handleAddInventoryData();
                    }}
                    type="primary"
                    loading={addInventoriesLoading}
                    style={{
                      width: 166,
                    }}
                    disabled={createdInventoryLoading}
                  >
                    Submit
                  </Button>
                </ActionItem>
              </div>
            </ModalHeadAction>
            {inventoriesToAdd.length > 0 && (
              <ReviewModalTable
                inventoryList={inventoriesToAdd}
                setInventoryList={setInventoriesToAdd}
                inventoriesLoading={addInventoriesLoading}
                header="Inventories"
                store={store}
                isMobile={isMobile}
              />
            )}
            <CustomButtonGroup>
              <Button
                type="ghost"
                onClick={() => setPendingPayment(!pendingPayment)}
              >
                <Checkbox checked={pendingPayment} /> Pending payment
              </Button>
              <Button
                type="ghost"
                onClick={() => setCreateInvoiceFlag(!createInvoiceFlag)}
              >
                <Checkbox checked={createInvoiceFlag} /> Create Invoice
              </Button>
              <Button
                type="ghost"
                onClick={() => setPurchaseOrder(!purchaseOrder)}
              >
                <Checkbox checked={purchaseOrder} /> Purchase Order
              </Button>
              <Input
                placeholder="Enter Tracking Code"
                value={trackingCode}
                onChange={(e) => handleChangeTrackingCode(e.target.value)}
              />
              <DatePicker
                placeholder="Purchase Date"
                value={purchaseDate}
                onChange={(e) => setPurchaseDate(e)}
              />
            </CustomButtonGroup>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

//types
export interface ProductsProps {}

export default function Products({}: ProductsProps): JSX.Element {
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();

  const { products, updatedProduct, productsLoading } = useAppSelector(
    (state) => state.ProductReducer
  );
  const {
    inventoriesToReview,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [inventoriesToAdd, setInventoriesToAdd] = useState<any[]>(
    inventoriesToReview ?? []
  );
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [addInventoriesLoading, setAddInventoriesLoading] = useState(false);

  const addToInventories = (items: any[]) => {
    setInventoriesToAdd([...inventoriesToAdd, ...items]);
  };

  const closeReviewModal = () => {
    setOpenReviewModal(false);
  };

  const handleOpenReviewModal = () => {
    if (inventoriesToAdd.length < 1) {
      message.warning("No item has been added for review");
      return;
    }
    window.scrollTo({ top: 0 });
    setOpenReviewModal(true);
  };
  const {
    productTemplate,
    productTemplates,
    productTemplateLoading,
    productTemplatesLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  useEffect(() => {
    dispatch(getProducts(search));
    //get products here any time search updates
  }, [search]);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());
  }, [products]);

  useEffect(() => {
    dispatch(setInventoriesToAddAction(inventoriesToAdd));
  }, [inventoriesToAdd]);

  useEffect(() => {
    if (inventoryFilterOptionsLoading) return;
    if (
      !inventoryFilterOptions ||
      !inventoryFilterOptions?.consigners ||
      inventoryFilterOptions?.consigners.length < 1
    ) {
      dispatch(getInventoryFilterOptions());
    }
    console.log("inventoryFilterOptions", inventoryFilterOptions);
  }, [inventoryFilterOptions, inventoryFilterOptionsLoading]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Products")
  ) {
    return <Redirect to="/" />;
  }
  //component

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  return (
    <Container data-testid="ProductsScreen">
      <ResellerReviewModal
        open={openReviewModal}
        handleClose={closeReviewModal}
        inventoriesToAdd={inventoriesToAdd}
        setAddInventoriesLoading={setAddInventoriesLoading}
        addInventoriesLoading={addInventoriesLoading}
        dispatch={dispatch}
        setInventoriesToAdd={setInventoriesToAdd}
      />
      <Search
        data-testid="search"
        placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
        allowClear
        enterButton="Search"
        size="large"
        onSearch={searchProducts}
      />
      <ProductList
        data-testid="productList"
        products={products}
        loading={productsLoading}
        productTemplates={productTemplates}
        updatedProduct={updatedProduct!}
        dispatch={dispatch}
        setInventoriesToAdd={addToInventories}
      />
      <Link data-testid="createProductLink" href="/products/create">
        Create New Product
      </Link>
      <FooterCustom>
        <div>
          <ActionItem>
            <p>
              <Link
                style={{ textDecoration: "none" }}
                onClick={handleOpenReviewModal}
              >
                Review
              </Link>
            </p>
            <img src="/svg/CaretDown.svg" alt="" id="reviews" />
          </ActionItem>
          <ActionItem>
            <p>Added Items</p>
            <b id="addedItems">
              {inventoriesToAdd.reduce(
                (sum, inventory: any) => sum + inventory.quantity,
                0
              )}
            </b>
          </ActionItem>
          <ActionItem>
            <p>Values</p>
            <b id="values">
              {getSymbolFromCurrency(store?.currency)}
              {inventoriesToAdd
                .reduce(
                  (sum, inventory: any) =>
                    sum + Number(inventory.payout ?? 0) * inventory.quantity,
                  0
                )
                .toFixed(2)}
            </b>
          </ActionItem>
          <ActionItem>
            <Button type="primary" onClick={handleOpenReviewModal}>
              Review Items
            </Button>
          </ActionItem>
        </div>
      </FooterCustom>
    </Container>
  );
}
