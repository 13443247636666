import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Dropdown,
  Image,
  Menu,
  Space,
  Spin,
  Typography,
} from "antd";
import { Inventory } from "../../redux/reducers/InventoryReducer";
import { Store } from "../../redux/reducers/StoreReducer";
import styled from "styled-components";
import {
  CaretDownFilled,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import getSymbolFromCurrency from "currency-symbol-map";
import { inventories } from "../../constants/TestData";
import { ColumnTypes } from "../../consignerScreens/ConsignerInventory";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { getLowestPriceForVariantFromAPI } from "../../redux/actions/inventoryActions";
import { useAppSelector } from "../../redux/hooks";
import { InventoryPageTypes } from "../../screens/Inventory";
import moment from "moment";
import check from "../../assets/images/svg/check.svg";
import InvoiceModal from "../../screens/Inventory/InvoiceModal";
// Styled components
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  @media (max-width: 768px) {
    border-radius: 4px;
    border: 1px solid #ddd;
    order: 2;
  }
`;

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ProductImageContainer = styled.div`
  flex: 0 0 133px;
  width: auto;
  display: flex;
  padding: 5px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  position: relative;
  .ant-image {
    object-fit: contain;
    max-height: 100%;
    flex: 1;
  }
  .ant-checkbox-wrapper {
    position: absolute;
    flex: 0 0 auto;
    top: 5px;
    left: 0;
    z-index: 2;
  }
  @media (max-width: 768px) {
    flex: 0 0 77px;
  }
`;

const ProductDetails = styled.div`
  flex: 1;
  padding: 5px 16px;
  gap: 18px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  &.pricing {
    & > div {
      &:not(.Requested) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.center {
    align-items: center;
  }
  &:first-of-type {
    border-left: 1px solid #ddd;
  }
  b {
    align-self: stretch;
    flex: 1;
    a {
      color: inherit;
      white-space: nowrap;
    }
  }
  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    flex-wrap: nowrap;
    span {
      flex: 1;
      // width: 90px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between; // space-between;
      p {
        margin: 0 0 3px;
        color: #292d32;
      }
      label {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
      }
    }
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid #ddd;
    border-right: none;
    font-size: 10px;
    b {
      font-size: inherit;
    }
    & > div {
      font-size: inherit;
      gap: 16px;
      span {
        justify-content: flex-start;
        font-size: inherit;
        label {
          font-size: inherit;
        }
      }
    }
    &.center {
      align-items: flex-start;
    }
  }
`;

export const ActionButtons = styled.div`
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  div {
    display: flex;
    gap: 11px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    // padding: 11px 19px;
    align-self: stretch;
    .ant-btn {
      color: #232323;
    }
  }
  @media (max-width: 768px) {
    flex: 0 0 auto;
    padding: 0 8px;
    justify-content: flex-end;
    // div {
    //   flex-direction: row;
    //   justify-content: space-between;
    // }
    .ant-btn {
      // min-width: 32px;
      max-width: 32px;
      max-height: 32px;
      font-size: 16px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #b2c7eb;
      &.ant-btn-ghost {
        font-size: 16px;
      }
      span:not(.anticon) {
        display: none;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  width: 130px;
  max-height: 32px;
  flex-shrink: 0;
  flex: 1;
  text-transform: capitalize;
  &.ant-btn-ghost {
    display: flex;
    padding: 11.5px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
    color: var(--Text---Grey, #989898);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &.ant-btn-dangerous {
    background-color: transparent;
    color: initial;
  }
  &:not(.ant-btn-primary) {
    border-radius: 8px;
    // background: var(--Grey---Button-Fill, #f4f4f4);
  }
  @media (max-width: 768px) {
    max-height: 50px;
    padding: 0;
  }
`;

export const ProductRowMobile = styled.div`
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
`;

const ProductRowHidden = styled.span`
  display: none;
  padding: 16px 10px;
  justify-content: space-evenly;
  border-top: 1px solid #ddd;
  &.expanded {
    display: flex;
  }
  &:first-of-type {
    border-bottom: 1px solid #ddd;
  }
  div {
    display: flex;
    gap: 13px;
  }
  b {
    font-weight: 500;
    font-size: 10px;
  }
  label {
    color: var(--Text---Grey, #989898);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 15px */
  }
`;

export const ProductRowMobileTop = styled.span`
  flex: 1;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
  .ant-checkbox-wrapper {
    align-self: start;
    position: absolute;
  }
  & > div {
    display: flex;
    & > b {
      font-weight: 500;
      margin-bottom: 13px;
    }
    span {
      div {
        b {
          font-weight: 500;
        }
        label {
          color: var(--Text---Grey, #989898);
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 15px */
        }
      }
    }
  }
`;
const SkuDivMargin = styled.div`
  margin-bottom: 1.4rem;
`;

const InventoryCardMobileView = ({
  dataSource,
  columnSrc,
  priceMap,
  status,
}: {
  dataSource: Inventory[];
  columnSrc: any[];
  priceMap: any[];
  status: any;
}) => {
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [expandedKey, setExpandedKey] = useState(-1);
  return (
    <Wrapper>
      {dataSource.map((inventory, key) => (
        <ProductRowMobile key={key}>
          <ProductRowMobileTop>
            {columnSrc
              .find((col) => col.dataIndex === "id")
              ?.render(inventory.id, inventory)}
            <Image
              src={inventory.product?.image}
              width={77}
              alt=""
              style={{ flex: "0 0 77px" }}
            />
            <div style={{ flex: 1, flexDirection: "column", fontSize: 12 }}>
              <b style={{ flex: 1 }}>{inventory.product?.title}</b>
              <div style={{ display: "flex", flex: 1 }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.product?.sku}</b>
                    <label>SKU</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.price}
                    </b>
                    {inventory?.requestedPrice && (
                      <b style={{ color: "green", display: "block" }}>
                        {getSymbolFromCurrency(store.currency)}
                        {inventory.requestedPrice}
                      </b>
                    )}
                    <label>Price</label>
                  </div>
                  {status === InventoryPageTypes.Requested && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <b>
                        {priceMap.find(
                          (rec) =>
                            rec.key ===
                            `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product?.id}`.replace(
                              /[\s]/g,
                              ""
                            )
                        )?.qty ?? inventory.qty}
                      </b>
                      <label>Current stock</label>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {inventory.consigner?.firstName}{" "}
                      {inventory.consigner?.lastName}
                    </b>
                    <label>Seller</label>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <b>{inventory.option2Value}</b>
                    <label>Condition</label>
                  </div> */}
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.option1Value}</b>
                    <label>Size</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.payoutAmount
                        ? Number(inventory.payoutAmount).toFixed(1)
                        : "0"}
                    </b>
                    <label>Payout</label>
                  </div>
                  {status === InventoryPageTypes.Requested && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <b>
                        {getSymbolFromCurrency(store.currency)}
                        {priceMap.find(
                          (rec) =>
                            rec.key ===
                            `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product?.id}`.replace(
                              /[\s]/g,
                              ""
                            )
                        )?.price ?? inventory.price}
                      </b>
                      <label>Current lowest price</label>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.location ?? inventory.option3Value}</b>
                    <label>Location</label>
                  </div>
                </span>
              </div>
            </div>
            <ActionButtons>
              {columnSrc
                .find((col) => col.dataIndex === "operation")
                ?.render("", inventory)}
            </ActionButtons>
          </ProductRowMobileTop>
        </ProductRowMobile>
      ))}
    </Wrapper>
  );
};

interface InventoryCardViewProps {
  inventories: Inventory[];
  store: Store;
  loading: boolean;
  columnSrc: any[];
  nestedView?: boolean;
  groupedData?: any[];
  priceMap: { price: number; qty: number; key: string }[];
  setPriceMap: any;
  setShowAcceptInventoryModal?: any;
  setSelectedRowKeys?: any;
  setShowRequestedInventoryModal?: any;
  status?: number;
  isInvoice?: boolean;
  invoiceRecord?: any;
  ordersView?: boolean;
  groupedDataOrder: any[];
  setIsInvoiceModal: () => void;
  handleViewClickProp: (inventory: any) => void;
}

const InventoryCardView = (data: InventoryCardViewProps) => {
  const {
    inventories: dataSource,
    loading,
    store,
    columnSrc,
    nestedView,
    groupedData,
    groupedDataOrder,
    priceMap,
    setPriceMap,
    status,
    setSelectedRowKeys,
    setShowAcceptInventoryModal,
    setShowRequestedInventoryModal,
    isInvoice,
    invoiceRecord,
    setIsInvoiceModal,
    ordersView,
    handleViewClickProp,
  } = data;
  const [activeKey, setActiveKey] = useState("");
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const [fetchOngoing, setFetchOngoing] = useState(false);

  const fetchLowestPricesAndQtyOfVariants = async (data: Inventory[]) => {
    setFetchOngoing(true);
    const prcMap = [...priceMap];
    for (const inventoryToReturn of data) {
      const key = `${inventoryToReturn.option1Value}-${
        inventoryToReturn?.option2Value
      }-${inventoryToReturn?.option3Value}-${inventoryToReturn?.location}-${
        inventoryToReturn?.storeId
      }-${
        inventoryToReturn?.productId ?? inventoryToReturn?.product.id
      }`.replace(/[\s]/g, "");
      const dataFound = prcMap.find((data) => data.key === key);
      if (dataFound === undefined || !dataFound) {
        const lowestPriceData = await getLowestPriceForVariantFromAPI({
          option1Value: inventoryToReturn?.option1Value ?? "",
          option2Value: inventoryToReturn?.option2Value ?? undefined,
          option3Value: inventoryToReturn?.option3Value ?? undefined,
          location: inventoryToReturn?.location ?? "",
          productId: Number(inventoryToReturn?.product?.id) ?? 0,
        });
        prcMap.push({
          key,
          qty: lowestPriceData.qty,
          price: lowestPriceData.price,
        });
      }
    }
    setPriceMap(prcMap);
    setFetchOngoing(false);
  };
  const handleViewClick = (inventory) => {
    handleViewClickProp(inventory);
  };
  useEffect(() => {
    if (
      !dataSource ||
      nestedView ||
      status !== InventoryPageTypes.Requested ||
      fetchOngoing
    )
      return;
    fetchLowestPricesAndQtyOfVariants(dataSource);
  }, [dataSource, nestedView, status, fetchOngoing]);
  useEffect(() => {
    console.log("collapse header====", activeKey, groupedDataOrder);
  }, [groupedData, setActiveKey, activeKey]);
  const CollapseHeader = (record: any) => {
    const { activeKey, image, title, keyItem, quantity, sku, inventories } =
      record;
    const expandIcon =
      activeKey === keyItem ? (
        <CaretDownOutlined style={{ flex: "0 0 30px" }} />
      ) : (
        <CaretRightOutlined style={{ flex: "0 0 30px" }} />
      );
    return (
      <>
        <InvoiceModal
          isInvoiceModal={isInvoice}
          invoiceRecord={invoiceRecord}
          closeInvoiceModal={setIsInvoiceModal}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {expandIcon}
          {status === InventoryPageTypes.Find && (
            <img
              height={52.5}
              src={image}
              width={52.5}
              style={{ flex: 1, objectFit: "contain" }}
            />
          )}
          <span
            style={{
              flex: 3,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "150%",
            }}
          >
            {title}
          </span>
          {status === InventoryPageTypes.Find && (
            <span
              style={{
                flex: 2,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: "150%",
              }}
            >
              {sku}
            </span>
          )}
          <span
            style={{
              flex: "0 0 auto",
              padding: "4px 8px 4px 0",
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            {quantity} Units
          </span>
          <span>
            {status &&
            [
              InventoryPageTypes.Incoming,
              InventoryPageTypes.Requested,
            ].includes(status) ? (
              // <StyledButton
              //   onClick={() => {
              //     setSelectedRowKeys(
              //       inventories.map((inventory) => inventory.id)
              //     );
              //     if (status === InventoryPageTypes.Incoming)
              //       setShowAcceptInventoryModal(true);
              //     else setShowRequestedInventoryModal(true);
              //   }}
              // >
              //   Accept Alll
              // </StyledButton>
              <img
                src={check}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedRowKeys(
                    inventories.map((inventory) => inventory.id)
                  );
                  if (status === InventoryPageTypes.Incoming)
                    setShowAcceptInventoryModal(true);
                  else setShowRequestedInventoryModal(true);
                }}
              />
            ) : (
              ""
            )}
          </span>
        </div>
      </>
    );
  };
  const CollapseHeaderOrder = (record: any) => {
    const { activeKey, image, title, keyItem, quantity, sku, inventories } =
      record;
    const expandIcon =
      activeKey === keyItem ? (
        <CaretDownOutlined style={{ flex: "0 0 30px" }} />
      ) : (
        <CaretRightOutlined style={{ flex: "0 0 30px" }} />
      );
    console.log("===inventories====", inventories);

    return (
      <>
        <InvoiceModal
          isInvoiceModal={isInvoice}
          invoiceRecord={invoiceRecord}
          closeInvoiceModal={setIsInvoiceModal}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {expandIcon}
          {status === InventoryPageTypes.Find && (
            <img
              height={52.5}
              src={image}
              width={52.5}
              style={{ flex: 1, objectFit: "contain" }}
            />
          )}
          <span
            style={{
              flex: 3,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "150%",
            }}
          >
            {title}
          </span>
          <span
            style={{
              flex: 3,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "150%",
            }}
          >
            {title}
          </span>
          {status === InventoryPageTypes.Find && (
            <span
              style={{
                flex: 2,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: "150%",
              }}
            >
              {sku}
            </span>
          )}
          <span
            style={{
              flex: "0 0 auto",
              padding: "4px 8px 4px 0",
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            {quantity} Units
          </span>
          <span>
            {status &&
            [
              InventoryPageTypes.Incoming,
              InventoryPageTypes.Requested,
            ].includes(status) ? (
              // <StyledButton
              //   onClick={() => {
              //     setSelectedRowKeys(
              //       inventories.map((inventory) => inventory.id)
              //     );
              //     if (status === InventoryPageTypes.Incoming)
              //       setShowAcceptInventoryModal(true);
              //     else setShowRequestedInventoryModal(true);
              //   }}
              // >
              //   Accept Alll
              // </StyledButton>
              <img
                src={check}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedRowKeys(
                    inventories.map((inventory) => inventory.id)
                  );
                  if (status === InventoryPageTypes.Incoming)
                    setShowAcceptInventoryModal(true);
                  else setShowRequestedInventoryModal(true);
                }}
              />
            ) : (
              ""
            )}
          </span>
        </div>
      </>
    );
  };

  const sortSizes = (sizes: Inventory[]) => {
    const sizeOrder = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];
    return sizes.sort((a, b) => {
      if (
        Number(a.option1Value?.replace(/[^0-9.]/g, "")) ||
        Number(b.option1Value?.replace(/[^0-9.]/g, ""))
      ) {
        return (
          a.option1Value?.replace(/[^0-9.]/g, "") -
          b.option1Value?.replace(/[^0-9.]/g, "")
        );
      }
      // Remove any country prefix and trim whitespace
      const sizeA = a.option1Value?.split(" ").pop();
      const sizeB = b.option1Value?.split(" ").pop();

      // Get the index of sizes from the size order
      const indexA = sizeOrder?.indexOf(sizeA);
      const indexB = sizeOrder?.indexOf(sizeB);

      // Compare the index to sort
      return indexA - indexB;
    });
  };
  console.log("Grouped Data", groupedData);
  const handleCollapseChange = (key) => {
    // console.log(key);
    const dataSrc = dataSource.find((data) => data.key === key);
    if (status == InventoryPageTypes.Requested) {
      fetchLowestPricesAndQtyOfVariants(dataSrc?.inventories);
    }
    setActiveKey(key);
  };
  const formatDateTime = (dateString) => {
    const options: any = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };
  console.log("===CollapseHeaderOrder=====", groupedDataOrder);

  if (loading) return <Spin />;
  else if (nestedView && groupedData)
    return (
      <Wrapper>
        <Collapse
          bordered={false}
          accordion
          expandIcon={() => null}
          style={{ display: "flex", flexDirection: "column", gap: 16 }}
          onChange={handleCollapseChange}
        >
          {groupedDataOrder?.map((data, key) => {
            1;
            const { inventories } = data;
            return (
              <>
                {!ordersView ? (
                  <CollapsePanel
                    style={{
                      borderRadius: 8,
                      border: "0.5px solid var(--Line, #DDD)",
                    }}
                    key={`${data.key}`}
                    header={
                      <CollapseHeader
                        keyItem={`${data?.productId}`}
                        activeKey={activeKey}
                        image={data?.productImage}
                        title={data?.productTitle}
                        sku={data?.productSku}
                        inventories={inventories}
                        /* title={
                    data?.consigner?.firstName +
                    " " +
                    data?.consigner?.lastName
                  } */
                        quantity={inventories?.length ?? 1}
                      />
                    }
                  >
                    {sortSizes(inventories).map((inventory, recordKey) => {
                      let rangeUsed = false;
                      const { storeRequestPriceRange } = inventory;
                      if (
                        storeRequestPriceRange &&
                        storeRequestPriceRange[1] &&
                        storeRequestPriceRange[0]
                      ) {
                        rangeUsed = true;
                      }

                      return (
                        <ProductRow key={inventory.id}>
                          <div>
                            {columnSrc
                              .find((col) => col.dataIndex === "id")
                              ?.render(inventory.id, inventory)}
                            <img
                              src={inventory.product?.image}
                              alt=""
                              style={{ height: "61px", width: "61px" }}
                            />
                          </div>
                          <ProductDetails>
                            <div>
                              <div style={{ flex: "0 0 250px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Name
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {inventory.product?.title}
                                </p>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  SKU
                                </label>
                                <SkuDivMargin>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "0.9em",
                                      marginTop: "4px",
                                      lineHeight: "1rem",
                                    }}
                                  >
                                    {inventory?.product?.sku?.slice(0, 12)}
                                    {inventory?.product?.sku.length > 12
                                      ? "..."
                                      : null}
                                  </p>
                                </SkuDivMargin>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Condition
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {inventory?.option2Value}
                                </p>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Size
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {inventory?.option1Value}
                                </p>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Cost
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {getSymbolFromCurrency(store.currency)}
                                  {inventory.cost
                                    ? Number(inventory.cost).toFixed(1)
                                    : "0"}
                                </p>
                              </div>
                            </div>
                          </ProductDetails>

                          <div style={{ width: "15%" }}></div>
                          {columnSrc.find(
                            (col) => col.dataIndex === "operation"
                          ) && (
                            <div style={{ display: "flex" }}>
                              {columnSrc
                                .find((col) => col.dataIndex === "operation")
                                ?.render("", inventory)}
                            </div>
                          )}
                        </ProductRow>
                      );
                    })}
                  </CollapsePanel>
                ) : (
                  <CollapsePanel
                    style={{
                      borderRadius: 8,
                      border: "0.5px solid var(--Line, #DDD)",
                    }}
                    key={`${data.key}`}
                    header={
                      <CollapseHeaderOrder
                        keyItem={`${data?.inventories?.orderId}`}
                        activeKey={activeKey}
                        image={data?.productImage}
                        title={data?.inventories[0]?.Seller}
                        sku={data?.productSku}
                        inventories={inventories}
                        /* title={
                      data?.consigner?.firstName +
                      " " +
                      data?.consigner?.lastName
                    } */
                        quantity={inventories?.length ?? 1}
                      />
                    }
                  >
                    {sortSizes(inventories).map((inventory, recordKey) => {
                      let rangeUsed = false;
                      const { storeRequestPriceRange } = inventory;
                      if (
                        storeRequestPriceRange &&
                        storeRequestPriceRange[1] &&
                        storeRequestPriceRange[0]
                      ) {
                        rangeUsed = true;
                      }

                      return (
                        <ProductRow key={inventory.id}>
                          <ProductDetails>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <div
                                style={{
                                  flex: "0 0 250px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={inventory?.product?.image}
                                  alt=""
                                  style={{ height: "61px", width: "61px" }}
                                />
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {inventory.product?.title}
                                </p>
                              </div>

                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  SKU
                                </label>
                                <SkuDivMargin>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "0.9em",
                                      marginTop: "4px",
                                      lineHeight: "1rem",
                                    }}
                                  >
                                    {inventory.product?.sku?.slice(0, 12)}
                                    {inventory.product?.sku.length > 12
                                      ? "..."
                                      : null}
                                  </p>
                                </SkuDivMargin>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Size
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {inventory.option2Value}
                                </p>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Quantity
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {inventory.option1Value}
                                </p>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Order ID
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {inventory?.order?.name}
                                </p>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Seller name
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {inventory?.Seller}
                                </p>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Price
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {getSymbolFromCurrency(store?.currency)}
                                  {inventory.price
                                    ? Number(inventory.price).toFixed(1)
                                    : 0}
                                </p>
                              </div>
                              <div style={{ flex: "0 0 0px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Cost
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {getSymbolFromCurrency(store?.currency)}
                                  {inventory.cost
                                    ? Number(inventory.cost).toFixed(1)
                                    : 0}
                                </p>
                              </div>
                              <div style={{ flex: "0 0 200px" }}>
                                <label
                                  style={{ color: "grey", fontWeight: "500" }}
                                >
                                  Date Sold
                                </label>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "4px",
                                    lineHeight: "1rem",
                                  }}
                                >
                                  {formatDateTime(
                                    inventory?.order?.orderDate.toString()
                                  )}
                                </p>
                              </div>
                              <div
                                style={{
                                  flex: "0 0 auto",
                                  marginLeft: "auto",
                                  alignSelf: "center",
                                }}
                              >
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    color: "#747474",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleViewClick(inventory)}
                                >
                                  View
                                </button>
                              </div>
                            </div>
                          </ProductDetails>
                        </ProductRow>
                      );
                    })}
                  </CollapsePanel>
                )}
              </>
            );
          })}
        </Collapse>
      </Wrapper>
    );
  return isMobile ? (
    <InventoryCardMobileView
      dataSource={dataSource}
      columnSrc={columnSrc}
      priceMap={priceMap}
      status={status}
    />
  ) : (
    <>
      <InvoiceModal
        isInvoiceModal={isInvoice}
        invoiceRecord={invoiceRecord}
        closeInvoiceModal={setIsInvoiceModal}
      />
      <Wrapper>
        {dataSource.map((inventory, key) => {
          let rangeUsed = false;
          const { storeRequestPriceRange } = inventory;
          if (
            storeRequestPriceRange &&
            storeRequestPriceRange[1] &&
            storeRequestPriceRange[0]
          ) {
            rangeUsed = true;
          }
          const acceptedOnDate = moment(inventory?.acceptedOn ?? new Date());
          let current = moment();
          const daysSinceAccept = current.diff(acceptedOnDate, "days");
          const items = [
            {
              key: "1",
              label: "Pending Sale",
            },
            {
              key: "2",
              label: "Sold",
            },
            {
              key: "3",
              label: "Active",
            },
            {
              key: "4",
              label: "Delete",
            },
          ];
          const menuItems = (
            <Menu>
              {items.map((item) => (
                <Menu.Item
                  key={item.key}
                  style={{
                    borderRadius: "8px",
                    borderBottom: "1px solid #f0f0f0",
                  }}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          );
          return (
            <ProductRow key={key}>
              <div>
                {columnSrc
                  .find((col) => col.dataIndex === "id")
                  ?.render(inventory.id, inventory)}
                <img
                  src={inventory.product?.image}
                  alt=""
                  style={{ height: "61px", width: "61px" }}
                />
              </div>
              <ProductDetails>
                <div>
                  <div style={{ flex: "0 0 250px" }}>
                    <label style={{ color: "grey", fontWeight: "500" }}>
                      Name
                    </label>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9em",
                        marginTop: "4px",
                        lineHeight: "1rem",
                      }}
                    >
                      {inventory.product?.title}
                      {/* <a style={{ display: "block" }}>{inventory.code}</a> */}
                    </p>
                  </div>

                  <div style={{ flex: "0 0 0px" }}>
                    <label style={{ color: "grey", fontWeight: "500" }}>
                      SKU
                    </label>
                    <SkuDivMargin>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {inventory.product?.sku?.slice(0, 12)}
                        {inventory.product?.sku.length > 12 ? "..." : null}
                      </p>
                    </SkuDivMargin>
                  </div>
                  <div style={{ flex: "0 0 0px" }}>
                    <label style={{ color: "grey", fontWeight: "500" }}>
                      Condition
                    </label>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9em",
                        marginTop: "4px",
                        lineHeight: "1rem",
                      }}
                    >
                      {inventory.option2Value}
                    </p>
                  </div>
                  <div style={{ flex: "0 0 0px" }}>
                    <label style={{ color: "grey", fontWeight: "500" }}>
                      Size
                    </label>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9em",
                        marginTop: "4px",
                        lineHeight: "1rem",
                      }}
                    >
                      {inventory.option1Value}
                    </p>
                  </div>

                  {inventory.status === "Active" && (
                    <div style={{ flex: "0 0 0px" }}>
                      <label style={{ color: "grey", fontWeight: "500" }}>
                        Days Active
                      </label>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {inventory.acceptedOn ? daysSinceAccept : ""}
                        {/* Removed + " Days" */}
                      </p>
                    </div>
                  )}
                  {["Paid", "Sold"].includes(inventory.status) && (
                    <>
                      <div style={{ flex: "0 0 0px" }}>
                        <label style={{ color: "grey", fontWeight: "500" }}>
                          Order ID
                        </label>
                        <p>{inventory.orderId}</p>
                      </div>
                      <div style={{ flex: "0 0 0px" }}>
                        <label style={{ color: "grey", fontWeight: "500" }}>
                          Sold Date
                        </label>
                        <p>{moment(inventory?.soldOn).format("YY/MM/DD")}</p>
                      </div>
                    </>
                  )}
                  <div style={{ flex: "0 0 0px" }}>
                    <label style={{ color: "grey", fontWeight: "500" }}>
                      Price
                    </label>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9em",
                        marginTop: "4px",
                        lineHeight: "1rem",
                      }}
                    >
                      {getSymbolFromCurrency(store.currency)}
                      {rangeUsed
                        ? `${storeRequestPriceRange[0]} - ${storeRequestPriceRange[1]}`
                        : inventory.price}
                      {inventory?.requestedPrice && (
                        <b style={{ color: "green", display: "block" }}>
                          {getSymbolFromCurrency(store.currency)}
                          {inventory.requestedPrice}
                        </b>
                      )}
                    </p>
                  </div>
                  <div style={{ flex: "0 0 0px" }}>
                    <label style={{ color: "grey", fontWeight: "500" }}>
                      Cost
                    </label>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9em",
                        marginTop: "4px",
                        lineHeight: "1rem",
                      }}
                    >
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.cost ? Number(inventory.cost).toFixed(1) : "0"}
                    </p>
                  </div>
                  <div style={{ flex: "0 0 0px" }}>
                    <label style={{ color: "grey", fontWeight: "500" }}>
                      Projected Profit
                    </label>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9em",
                        marginTop: "4px",
                        lineHeight: "1rem",
                      }}
                    >
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.cost
                        ? Number(+inventory.price - +inventory.cost).toFixed(1)
                        : "0"}
                    </p>
                  </div>
                  <div>
                    <label style={{ color: "grey", fontWeight: "500" }}>
                      Status
                    </label>
                    <Dropdown overlay={menuItems}>
                      <Typography.Link
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        <Space>
                          {inventory?.status}
                          <DownOutlined />
                        </Space>
                      </Typography.Link>
                    </Dropdown>
                  </div>
                </div>
              </ProductDetails>
              {/* <ActionButtons>
              <Button type="default" target="_blank" href={`inventories/${inventory.id}`} icon={<EditOutlined />}>Edit</Button>
            </ActionButtons> */}
              {columnSrc.find((col) => col.dataIndex === "operation") && (
                <ActionButtons>
                  {columnSrc
                    .find((col) => col.dataIndex === "operation")
                    ?.render("", inventory)}
                </ActionButtons>
              )}
            </ProductRow>
          );
        })}
      </Wrapper>
    </>
  );
};

export default InventoryCardView;
