import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Spin } from "antd";
import CheckoutBottomBar from "../components/Checkout/CheckoutBottomBar";
import CheckoutHeader from "../components/Checkout/CheckoutHeader";
import CheckoutPage from "../components/Checkout/CheckoutPage";
import CheckoutAcceptInventory from "../components/Checkout/CheckoutAcceptInventory";
import StepsComponent from "../components/Common/Steps";
import Content from "../constants/Content";
import Colors from "../constants/Colors";
import CheckoutProduct from "../components/Checkout/CheckoutProduct";
import CheckoutInputContainer from "../components/Checkout/CheckoutInputContainer";
import GridSelector from "../components/Common/GridSelector";
import { Store } from "../redux/reducers/StoreReducer";
import { Product } from "../redux/reducers/ProductReducer";
import { ProductTemplate } from "../redux/reducers/ProductTemplateReducer";
import {
  RouteComponentProps,
  useHistory,
  useParams,
  Redirect,
} from "react-router";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getProduct } from "../redux/actions/productActions";
import {
  calculateFees,
  createInventory,
  createInventories,
  getInventoryFilterOptions,
  resetCreatedInventory,
  bulkAcceptInventory,
} from "../redux/actions/inventoryActions";
import {
  Inventory,
  InventoryFee,
  InventoryFilterOptions,
  InventoryState,
} from "../redux/reducers/InventoryReducer";
import CheckoutInfo from "../components/Checkout/CheckoutInfo";
import CheckoutShipIntructions from "../components/Checkout/CheckoutShipInstructions";
import { AppDispatch } from "../redux/store";
import { UserState } from "../redux/reducers/UserReducer";
import { Consigner } from "../redux/reducers/ConsignerReducer";
import { getDropDownValuesForConsigner } from "./InventoryItem";
import { getProductTemplate } from "../redux/actions/productTemplateActions";

/**
 * AddInventory
 * TODO Testing:
 *  - should render correclty (page, header, steps, bottom bar, info conatiner, right conatiner, left container, product, input containers)
 *  - goes to next page on click
 *  - on input container change
 *  - gets product
 *  - reload payout fees on price and qauntity change
 *  - renders all sizes in product object
 *  - renders all conditions in product object
 *  - renders all box condition in product object
 *  - renders cleaning fee if used item
 *  - renders box fee if no box
 *  - on next page, sets correct text and logic
 *  - validate checkout returns true when required values are not passed
 *  - should redirect to product page once inventory is successfully added
 *  - should show product cleaning fee test
 *  - should show replacement box fee tests
 *  - goes to ship, when ship step aviaable or products page if not
 *  - redirects after created inventory is not null
 */

const CheckoutLeftContainer = styled.div`
  width: 50%;
  padding: 50px;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const CheckoutRightContainer = styled.div`
  width: 50%;
  padding: 20px;
  background: ${Colors.INPUT_BACKGROUND};
  @media (max-width: 400px) {
    width: 100%;
    padding-bottom: 200px;
  }
`;
const CheckoutInfoContainer = styled.div`
  display: flex;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const Spinner = styled(Spin)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface RouteParams {
  id: string;
}

export interface InventoryFormValues {
  price: number;
  Seller?: string;
  shippingCost?: number;
  quantity: number;
  option1Value: any;
  option2Value: any;
  option3Value: any;
  cost: number;
  consigner: string;
  category: string;
  productId?: string;
  preapprovalStatus?: boolean;
  location?: string;
  subLocation?: string;
  status?: string;
  storeRequestPriceRange?: string;
  invoiceId?: number;
  acceptedOn?: string;
}

export interface InventoryLocations {
  location: string;
  subLocation: string;
}

interface AddInventorySteps {
  title: string;
  description?: string;
}

interface Props {
  steps: AddInventorySteps[];
}

const roundToNearest99 = (number: string) => {
  return Math.ceil(Number(number) / 100) * 100 - 1 || 0;
};

const getInputContainer = ({
  productTemplate,
  current,
  product,
  setOption1Value,
  option1Value,
  setOption2Value,
  option2Value,
  setOption3Value,
  option3Value,
  payoutFee,
  setPayoutFee,
  price,
  setPrice,
  quantity,
  setQuantity,
  calculatedFees,
  createdInventory,
  dbUser,
  cost,
  setCost,
  inventoryFilterOptions,
  setSelectedConsigner,
  selectedConsigner,
  store,
  location,
  setLocation,
  subLocation,
  setSubLocation,
}: {
  productTemplate: ProductTemplate | null;
  current: number;
  product: Product;
  store: Store;
  setOption1Value: (option1Values: any) => void;
  option1Value: any;
  setOption2Value: (option2Values: any) => void;
  option2Value: any;
  setOption3Value: (option3Values: any) => void;
  option3Value: any;

  location: string;
  setLocation: (location: string) => void;
  subLocation: string;
  setSubLocation: (subLocation: string) => void;

  payoutFee: number;
  setPayoutFee: (payoutFee: number) => void;

  price: number;
  setPrice: (price: number) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  calculatedFees: InventoryFee | null;
  createdInventory: Inventory[];
  dbUser: Consigner;
  cost: number;
  setCost: (cost: number) => void;
  inventoryFilterOptions: InventoryFilterOptions;
  setSelectedConsigner: (id: string) => void;
  selectedConsigner: string;
}): JSX.Element => {
  let templateSizes: any;
  if (productTemplate?.option1.toLowerCase() == "size") {
    templateSizes = productTemplate.option1Values;
  } else if (productTemplate?.option2?.toLowerCase() == "size") {
    templateSizes = productTemplate.option2Values;
  } else if (productTemplate?.option3?.toLowerCase() == "size") {
    templateSizes = productTemplate.option3Values;
  } else {
    //no size template
    templateSizes = null;
  }

  let option1Data;

  if (templateSizes && templateSizes.length > 0) {
    option1Data = templateSizes?.map((size: any) => {
      const price =
        product.inventories?.find(
          (inventory: any) => inventory.option1Value === size.size
        )?.price || size.price;
      return {
        title: "",
        // Number(price) >= 0 ? `$${Math.round(Number(price))}` : "N/A",
        subTitle: size,
        value: size,
      };
    });
  } else {
    option1Data = productTemplate?.option1Values.map((option1Value: any) => {
      return {
        title: option1Value,
        subTitle: "",
        value: option1Value,
      };
    });
  }

  switch (current) {
    case 0:
      return (
        //option1
        <GridSelector
          data={option1Data}
          title=""
          onItemClick={(value) => setOption1Value(value)}
          selectedItem={option1Value}
          small
          borderRadius={"0px"}
          borderColor={""}
          borderStyle={"unset"}
          borderWidth={"0ch"}
          padding={"10px"}
          background={Colors.INPUT_BACKGROUND}
          classicVersion={true}
        />
      );
    case 1:
      return (
        //option2
        <GridSelector
          data={productTemplate?.option2Values.map((option2Value: any) => {
            return {
              title: "",
              subTitle: option2Value,
              value: option2Value,
            };
          })}
          title={""}
          onItemClick={(value) => setOption2Value(value)}
          selectedItem={option2Value}
          borderRadius={"0px"}
          borderColor={""}
          borderStyle={"unset"}
          borderWidth={"0ch"}
          padding={"10px"}
          background={Colors.INPUT_BACKGROUND}
          classicVersion={true}
        />
      );
    case 2:
      return (
        //option3
        <GridSelector
          data={productTemplate?.option3Values.map((option3Value) => {
            return {
              title: option3Value,
              subTitle: "",
              value: option3Value,
            };
          })}
          title={""}
          onItemClick={(value) => setOption3Value(value)}
          selectedItem={option3Value}
          borderRadius={"0px"}
          borderColor={""}
          borderStyle={"unset"}
          borderWidth={"0ch"}
          padding={"10px"}
          background={Colors.INPUT_BACKGROUND}
          classicVersion={true}
        />
      );
    case 3:
      return (
        <CheckoutInfo
          cost={cost}
          setCost={setCost}
          calculatedFees={calculatedFees}
          price={price}
          setPrice={(price: number) => setPrice(price)}
          quantity={quantity}
          setQuantity={setQuantity}
          selectedConsigner={selectedConsigner}
          setSelectedConsigner={setSelectedConsigner}
          consigners={getDropDownValuesForConsigner(
            inventoryFilterOptions.consigners
          )}
        />
      );
    case 4:
      return createdInventory ? (
        <CheckoutShipIntructions dbUser={dbUser as Consigner} store={store} />
      ) : (
        <div />
      );
    case 5:
      return createdInventory ? (
        <CheckoutAcceptInventory
          inventoryFilterOptions={inventoryFilterOptions}
          onItemClick={(value) => setLocation(value)}
          onItemClickTwo={(value) => setSubLocation(value)}
          location={location}
          subLocation={subLocation}
        />
      ) : (
        <div />
      );
  }
  return <div />;
};

const validateCart = ({
  option1Value,
  option2Value,
  option3Value,
  price,
  quantity,
}: InventoryFormValues) => {
  return (
    option1Value &&
    option1Value !== "" &&
    price &&
    price > 0 &&
    quantity &&
    quantity > 0
  );
};

export const handleAddInventory = async (
  inventoryFormValues: InventoryFormValues,
  setLoading: (newLoadingState: boolean) => void,
  dispatch: AppDispatch,
  id: string
) => {
  setLoading(true);
  if (validateCart(inventoryFormValues)) {
    const inventories: InventoryFormValues[] = [];
    inventories.push(inventoryFormValues);
    dispatch(await createInventories(inventories, id));
    return;
  } else {
    alert("Please select size, condition, price, and quantity first");
    setLoading(false);
  }
};

export const handleAcceptInventory = async (
  values: any,
  inventory: any,
  setLoading: (newLoadingState: boolean) => void,
  dispatch: AppDispatch
) => {
  setLoading(true);
  if (values.location !== "" || null) {
    dispatch(
      await bulkAcceptInventory(inventory, values.location, values.subLocation)
    );
  } else {
    alert("Please select a location");
    setLoading(false);
  }
};

const AddInventory = (props: Props) => {
  const { id }: RouteParams = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { product, productLoading } = useAppSelector(
    (state) => state.ProductReducer
  );

  const { productTemplate, productTemplateLoading } = useAppSelector(
    (state) => state.ProductTemplateReducer
  );

  const { calculatedFees, createdInventory }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );

  const { store } = useAppSelector((state) => state.StoreReducer);

  const [buttonText, setButtonText] = useState("Next");
  const [currentInputContainer, setCurrentInputContainer] = useState(0);
  const [loading, setLoading] = useState(false);
  const [option1Value, setOption1Value] = useState("");
  const [option2Value, setOption2Value] = useState("");
  const [option3Value, setOption3Value] = useState("");
  const [inputContainerTitle, setInputContainerTitle] = useState("");
  const [category, setCategory] = useState(productTemplate?.category!);
  const [price, setPrice] = useState(0);
  const [payoutFee, setPayoutFee] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [cost, setCost] = useState(0);
  const [selectedConsigner, setSelectedConsigner] = useState("");
  const [steps, setSteps] = useState([]);
  const [location, setLocation] = useState("");
  const [subLocation, setSubLocation] = useState("");

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProduct(id));
    dispatch(resetCreatedInventory());
  }, [id]);

  useEffect(() => {
    if (product?.productTemplateId && store) {
      dispatch(getProductTemplate(product?.productTemplateId));
    }
  }, [product]);

  useEffect(() => {
    if (productTemplate && productTemplate.category) {
      setCategory(productTemplate.category);
      setInputContainerTitle(`Select ${productTemplate.option1}`);
    }
  }, [productTemplate]);

  useEffect(() => {
    let newSteps: any = [];
    if (productTemplate !== null) {
      newSteps.push({ title: productTemplate?.option1 });
      if (productTemplate?.option2 && productTemplate?.option2 !== "") {
        newSteps.push({ title: productTemplate?.option2 });
      }
      if (productTemplate?.option3 && productTemplate?.option3 !== "") {
        newSteps.push({ title: productTemplate?.option3 });
      }
      newSteps.push({ title: "Price" });
    }
    setSteps(newSteps);
  }, [productTemplate]);

  useEffect(() => {
    //re fetches fees on price and quantity changes
    let consigner = selectedConsigner;
    dispatch(
      calculateFees(
        id,
        // payoutFee,
        price,
        quantity,
        option1Value,
        option2Value,
        option3Value,
        cost,
        category,
        consigner
      )
    );
  }, [
    id,
    price,
    quantity,
    cost,
    option1Value,
    option2Value,
    option3Value,
    category,
    selectedConsigner,
  ]);

  useEffect(() => {
    if (createdInventory) {
      if (process.env.REACT_APP_TYPE === "consigner") {
        handleGoToShipPage();
      } else {
        handleGoToAcceptInventoryPage();
        // history.push(`/products/${createdInventory.product.id}`);
      }
      setLoading(false);
    }
  }, [createdInventory]);

  const { dbUser, fbUser }: UserState = useAppSelector(
    (state) => state.UserReducer
  );

  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  useEffect(() => {
    //get consigner values for dropdopw
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    //set editable inventory values whenever inventory changes
    if (inventoryFilterOptions && inventoryFilterOptions.consigners) {
      if (process.env.REACT_APP_TYPE === "employee") {
        //Pick the store account by default if logged in as an employee
        const storeAccount = inventoryFilterOptions.consigners.find(
          (consigner: any) => consigner.value.isStoreAccount === true
        );
        setSelectedConsigner(JSON.stringify(storeAccount?.value));
      } else {
        setSelectedConsigner(JSON.stringify(dbUser));
      }
    }
  }, [inventoryFilterOptions]);

  //checkout data

  const handleGoToOption1 = () => {
    setCurrentInputContainer(0);
    setInputContainerTitle(`Select ${productTemplate?.option1}`);
    setButtonText("Next");
  };

  const handleGoToOption2 = () => {
    setCurrentInputContainer(1);
    setInputContainerTitle(
      `Select ${productTemplate?.option2 ? productTemplate?.option2 : "Price"}`
    );
    setButtonText("Next");
  };

  const handleGoToOption3 = () => {
    setCurrentInputContainer(2);
    setInputContainerTitle(
      `Select ${productTemplate?.option3 ? productTemplate?.option3 : "Price"}`
    );
    setButtonText("Next");
  };

  const handleGoToCheckoutPage = () => {
    dispatch(
      calculateFees(
        id,
        // payoutFee,
        price,
        quantity,
        option1Value,
        option2Value,
        option3Value,
        cost,
        category,
        selectedConsigner
      )
    );
    setCurrentInputContainer(3);
    setInputContainerTitle("Select Price");
    setButtonText("Submit For Review");
  };

  const handleGoToShipPage = () => {
    setCurrentInputContainer(4);
    setButtonText("Done");
    setInputContainerTitle("Final Step");
  };

  const handleGoToAcceptInventoryPage = () => {
    setCurrentInputContainer(5);
    setButtonText("Done");
    setInputContainerTitle("Accept Inventory");
  };

  const onNext = (nextPage: number) => {
    switch (nextPage) {
      case 0:
        handleGoToOption1();
        return;
      case 1:
        {
          productTemplate?.option2
            ? handleGoToOption2()
            : handleGoToCheckoutPage();
        }
        return;
      case 2:
        {
          productTemplate?.option3
            ? handleGoToOption3()
            : handleGoToCheckoutPage();
        }
        return;
      case 3:
        handleGoToCheckoutPage();
        return;
      case 4:
        handleAddInventory(
          {
            option1Value,
            option2Value,
            option3Value,
            price,
            quantity,
            cost,
            consigner: selectedConsigner,
            category,
            subLocation,
          },
          setLoading,
          dispatch,
          id
        );
        return;
      case 5:
        setCurrentInputContainer(0);
        dispatch(resetCreatedInventory());
        window.history.back();
        return;
      case 6:
        handleAcceptInventory(
          { location, subLocation },
          createdInventory,
          setLoading,
          dispatch
        );
        setCurrentInputContainer(0);
        dispatch(resetCreatedInventory());
        history.push(`/products/${createdInventory[0].productId}`);
        return;
      default:
        break;
    }
  };

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Inventory")
  ) {
    return <Redirect to="/" />;
  }

  if (
    productLoading ||
    !product ||
    !store ||
    !steps ||
    inventoryFilterOptionsLoading ||
    !inventoryFilterOptions
  )
    return (
      <CheckoutPage data-testid="ProductScreenLoadingContainer">
        <Spinner data-testid="ProductScreenLoadingSpinner" />
      </CheckoutPage>
    );

  return (
    <CheckoutPage>
      <CheckoutHeader />
      <StepsComponent
        steps={steps}
        current={currentInputContainer}
        title={Content.ADD_INVENTORY_STEPS_TITLE}
        onChange={(current) => onNext(current)}
      />
      <CheckoutInfoContainer>
        <CheckoutLeftContainer>
          <CheckoutProduct product={product} />
        </CheckoutLeftContainer>
        <CheckoutRightContainer>
          <CheckoutInputContainer title={inputContainerTitle}>
            {getInputContainer({
              current: currentInputContainer,
              productTemplate,
              product,
              option1Value,
              setOption1Value,
              option2Value,
              setOption2Value,
              option3Value,
              setOption3Value,
              payoutFee,
              setPayoutFee,
              price,
              setPrice,
              quantity,
              setQuantity,
              calculatedFees,
              createdInventory,
              dbUser: dbUser as Consigner,
              cost,
              setCost,
              inventoryFilterOptions,
              setSelectedConsigner,
              selectedConsigner,
              store,
              location,
              setLocation,
              subLocation,
              setSubLocation,
            })}
          </CheckoutInputContainer>
        </CheckoutRightContainer>
      </CheckoutInfoContainer>

      <CheckoutBottomBar
        buttonText={buttonText}
        onClick={() => onNext(currentInputContainer + 1)}
        loading={loading}
      />
    </CheckoutPage>
  );
};

export default AddInventory;
