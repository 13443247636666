import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Redirect, useLocation } from "react-router-dom";
import firebase from "firebase";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import {
  createStore,
  getStore,
  getStoreFromEmail,
} from "../redux/actions/storeActions";
import { StoreState } from "../redux/reducers/StoreReducer";
import AuthService from "../services/auth";
import { message, Button } from "antd";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Cookies from "js-cookie";
import logo from "../assets/images/logo.png";
import Fonts from "../constants/Fonts";
import Colors from "../constants/Colors";
import RedesignStyling from "../constants/RedesignStyling";
import StoreSignupForm from "../components/Auth/StoreSignupForm";

/**
 * Store Signup Screen
 * renders a screen where store can signup
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  font-family: ${Fonts.CONSIGNER};
  background: ${Colors.CONSIGNER_BG};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 100vh;
  padding: ${RedesignStyling.PAGE_PADDING};
  margin-top: 60px;
  @media (max-width: 768px) {
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
  }
`;
const SignupContainer = styled.div`
  width: 472px;
  max-width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const TOSContainer = styled.div`
  color: #2e2e2e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const TopBarGeneric = styled.div`
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${Colors.WHITE};
  position: fixed;
  top: 0;
`;
const Logo = styled.img`
  height: 40px;
  width: auto;
`;
const Header = styled.div`
  color: var(--heading-color, #2e2e2e);
  text-align: center;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: -2px;
  width: 100%;
`;
const Subheader = styled.div`
  color: ${Colors.CONSIGNER_GREY};
  text-align: center;
  font-family: ${Fonts.CONSIGNER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  width: 100%;
`;

const validateEmail = (email: String) => {
  var re =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return re.test(String(email));
};

const StoreSignup = () => {
  const dispatch = useAppDispatch();
  const { createdSuccess, createStoreLoading, store }: StoreState =
    useAppSelector((state) => state.StoreReducer);
  const { isLoggedIn, dbUser } = useAppSelector((state) => state.UserReducer);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [catalogue, setCatalogue] = useState<string>();
  const [sizing, setSizing] = useState<string>();
  const [payoutOptions, setPayoutOptions] = useState<string>();
  const [currency, setCurrency] = useState<string>();
  const [showTOSModal, setShowTOSModal] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const auth = AuthService();

  useEffect(() => {
    const domain = Cookies.get("domain");
    if (domain) {
      Cookies.remove("domain");
    }
  }, []);

  // useEffect(() => {
  //   if (store && store?.domain) {
  //     message.success("Loading...");
  //     window.open(
  //       `${process.env.REACT_APP_APP_URL}/login?domain=${store.handle}.fulltrace.co&shop=${store.shop?.shop}`,
  //       "_self"
  //     );
  //   }
  // }, [store]);

  const handleSignup = async () => {
    if (
      step === 2 &&
      (sizing || currency !== "Sneakers") &&
      catalogue &&
      currency &&
      payoutOptions
    ) {
      await signupStore(
        `${firstName} ${lastName}`,
        email,
        sizing ?? "",
        catalogue,
        currency,
        password,
        payoutOptions
      );
    } else {
      setStep(2);
    }
  };

  const signupStore = async (
    name: string,
    email: string,
    sizing: string,
    payoutOptions: string,
    catalogue: string,
    currency: string,
    password: string
  ) => {
    setLoading(true);
    if (
      email !== "" &&
      validateEmail(email) &&
      name !== "" &&
      (sizing || catalogue !== "Sneakers") &&
      catalogue &&
      currency &&
      password !== ""
    ) {
      message.success("Please check your email to continue.");
      message.loading({
        content: "Please wait while we log you in.",
        duration: 0,
        key: 1,
      });
      dispatch(
        createStore(
          name,
          email,
          payoutOptions,
          sizing,
          catalogue,
          currency,
          password
        )
      );
    } else {
      message.error("Please enter a valid email, name and details");
      setLoading(false);
    }
  };

  const login = (email: string, password: string) => {
    auth.login(email, password);
  };

  useEffect(() => {
    console.log(store, createdSuccess);
    if (createdSuccess && store && store.id && (!isLoggedIn || !dbUser)) {
      login(email, password);
    }
    if (createdSuccess && !store) {
      dispatch(getStoreFromEmail(email));
    }

    if (isLoggedIn && dbUser) {
      message.destroy(1);
    }
  }, [createdSuccess, store, isLoggedIn, dbUser]);

  if (createdSuccess && dbUser) return <Redirect to="/login" />;

  return (
    <Container>
      <TopBarGeneric>
        <Logo
          src={logo}
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = logo;
          }}
        />
      </TopBarGeneric>
      <SignupContainer>
        <FormContainer>
          <Header>Create Free Account</Header>
          <Subheader>
            Sign up with trace to start managing your inventory in a go with our
            easy to use dashboard
          </Subheader>
          <StoreSignupForm
            showPasswordInput={true}
            email={email}
            password={password}
            confirm={confirm}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            catalogue={catalogue}
            sizing={sizing}
            // payoutOptions={payoutOptions}
            currency={currency}
            step={step}
            setEmail={setEmail}
            setPassword={setPassword}
            setConfirm={setConfirm}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setPhone={setPhone}
            setCatalogue={setCatalogue}
            setSizing={setSizing}
            setCurrency={setCurrency}
          />
          <TOSContainer>
            I agree with the{" "}
            <a onClick={() => setShowTOSModal(true)}>Terms & Conditions</a> of
            Trace
          </TOSContainer>
          <Button
            style={{
              display: "flex",
              width: "100%",
              height: 65,
              // padding: 16,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
              textAlign: "center",
              fontSize: 16,
              fontWeight: 600,
            }}
            type="primary"
            disabled={confirm !== password || password === ""}
            onClick={handleSignup}
          >
            Sign Up
          </Button>
          <Button
            style={{
              display: "flex",
              width: "100%",
              height: 65,
              // padding: 16,
              justifyContent: "center",
              alignItems: "center",
              // marginTop: 10,
              // marginBottom: 10,
              textAlign: "center",
              fontSize: 16,
              fontWeight: 600,
            }}
            type="text"
            href="/login"
          >
            Sign In
          </Button>
        </FormContainer>
      </SignupContainer>
      <Dialog
        open={/* store?.termsOfService && */ showTOSModal}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Accept Terms of Service
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {/* store.termsOfService */}I accept to anything.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTOSModal(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default StoreSignup;
