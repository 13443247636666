import React, { useState } from "react";
import { SearchBoxWrapper, SearchInput } from "./searchStyle";
export const SearchBox = ({
  value,
  onChange,
  placeholder,
}: {
  value?: string;
  onChange?: any;
  placeholder: string;
}) => {
  const [searchValue, setSearchValue] = useState(value);
  return (
    <>
      <SearchBoxWrapper>
        <SearchInput
          onSearch={() => onChange(searchValue)}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          data-testid="search"
          placeholder={placeholder}
          allowClear
          size="small"
        />
      </SearchBoxWrapper>
    </>
  );
};
