import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  List,
  Card,
  Button,
  Grid,
  Popconfirm,
  Modal,
  Form,
  Input,
  Spin,
  InputNumber,
  message,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Product } from "../../../redux/reducers/ProductReducer";
import { ProductTemplate } from "../../../redux/reducers/ProductTemplateReducer";
import InventoryModal from "../InventoryAddModal";
import { yellow } from "@mui/material/colors";
import {
  getProducts,
  getStoreProducts,
} from "../../../redux/actions/productActions";
import { OptimizedImageUrl } from "../../../helperFunctions/optimizedImageUrl";
import InventoryStoreRequestModal from "../InventoryStoreRequestModal";
import _ from "lodash";
import {
  Inventory,
  InventoryState,
} from "../../../redux/reducers/InventoryReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import getSymbolFromCurrency from "currency-symbol-map";
import { Store } from "../../../redux/reducers/StoreReducer";
import {
  acceptInventory,
  calculateFees,
  storeBuyRequestedInventory,
  updateInventory,
} from "../../../redux/actions/inventoryActions";
import { UserState } from "../../../redux/reducers/UserReducer";
import Colors from "../../../constants/Colors";
import TextArea from "antd/lib/input/TextArea";

/**
 * Product List
 * Expects a Product
 * Returns a grid of Products
 */

//styles
const Container = styled(List)`
  margin-top: 10px;
  // overflow-y: scroll;
  padding: 15px 0;
  width: 100%;
  .ant-col {
    height: 100%;
    & > .ant-list-item {
      height: calc(100% - 15px);
      .ant-card {
        height: 100%;
      }
    }
  }
`;

const ProductContainer = styled(Card)`
  .ant-card-body {
    padding: 75px 50px 25px;
  }
  @media (max-width: 768px) {
    .ant-card-body {
      padding: 12px 16px;
    }
  }
`;
const ProductReqContainer = styled(Card)`
  border-radius: 8px;
  border: 1px solid var(--Line, #ddd);
  .ant-card-body {
    // padding: 25px 50px;
    padding: 0;
    & > div {
      position: relative;
      padding: 16px;
      &:first-of-type {
        border-bottom: 1px solid var(--Line, #ddd);
      }
    }
    img {
      max-width: 215px;
      max-height: 165px;
      margin: auto;
      display: block;
    }
  }
  @media (max-width: 768px) {
    .ant-card-body {
      padding: 0;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  margin-bottom: ${process.env.REACT_APP_TYPE === "consigner" ? "100px" : "0"};
  @media (max-width: 768px) {
    margin-bottom: ${process.env.REACT_APP_TYPE === "consigner" ? "15px" : "0"};
  }
`;
const Price = styled.p``;
const Title = styled.p`
  text-transform: capitalize;
  color: #2e2e2e;
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
const TitleBar = styled.p`
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  margin-top: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Labler = styled.p`
  color: var(--Text---Grey, #989898);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;
const Sku = styled.p`
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
`;

export const FloatingExpander = styled.span`
  position: absolute;
  top: calc(100% - 16px);
  z-index: 2;
  background-color: rgb(255, 255, 255);
  padding: 0px 16px;
  width: calc(100% + 2px);
  left: -1px;
  border-radius: 0 0 8px 8px;
  border: 1px solid var(--Line, #ddd);
  border-top: none;
`;

//types
interface Props {
  products: Product[];
  loading: boolean;
  productTemplates: ProductTemplate[];
  updatedProduct: Product;
  dispatch: any;
  setInventoriesToAdd?: any;
  storeRequest?: boolean;
  selectedLocation?: string;
  requestedInventories?: Inventory[];
}

export const ProductCard = (props: any): JSX.Element => {
  const {
    product,
    productTemplates,
    updatedProduct,
    dispatch,
    setInventoriesToAdd,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newInventory, setNewInventory] = useState(false);
  const [reload, setReload] = useState(false);

  const showModal = (value: boolean) => {
    setIsModalVisible(value);
    setNewInventory(value);
  };

  useEffect(() => {
    if (reload === true && newInventory === true) {
      let search = "";
      dispatch(getProducts(search));
    }
  }, [reload]);

  if (process.env.REACT_APP_TYPE === "employee") {
    return (
      <>
        <InventoryModal
          product={product}
          id={product.id}
          productTemplates={productTemplates}
          updatedProduct={updatedProduct}
          dispatch={dispatch}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
          isNewEntry={newInventory}
          setNewInventory={setNewInventory}
          setReload={setReload}
          setInventoriesToAdd={setInventoriesToAdd}
        />
        <List.Item>
          {product && product.productTemplateId && !isNaN(product.id) ? (
            // <Link to={`products/${product.id}`}>
            <ProductContainer
              data-testid="product"
              hoverable
              onClick={() => showModal(true)}
            >
              <div style={{ display: "flex", marginBottom: "8px" }}>
                <Button
                  style={{ marginLeft: "auto" }}
                  onClick={() => showModal(false)}
                  icon={<PlusOutlined style={{ color: "#D3D3D3" }} />}
                />
              </div>
              <Image
                data-testid="image"
                src={OptimizedImageUrl(product.image)}
              />
              <Title data-testid="title">{product.title}</Title>
              <Price data-testid="price">{product.price}</Price>
            </ProductContainer>
          ) : (
            // </Link>
            <ProductContainer
              data-testid="product"
              hoverable
              onClick={() => showModal(true)}
            >
              <div style={{ display: "flex", marginBottom: "8px" }}>
                <Button
                  style={{ marginLeft: "auto" }}
                  onClick={() => showModal(true)}
                  icon={<PlusOutlined style={{ color: "#D3D3D3" }} />}
                />
              </div>
              <Image
                data-testid="image"
                src={OptimizedImageUrl(product.image)}
              />
              <Title data-testid="title">{product.title}</Title>
              <Price data-testid="price">{product.price}</Price>
            </ProductContainer>
          )}
        </List.Item>
      </>
    );
  }

  return (
    <>
      <InventoryModal
        product={product}
        id={product.id}
        productTemplates={productTemplates}
        updatedProduct={updatedProduct}
        dispatch={dispatch}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        isNewEntry={false}
        setNewInventory={setNewInventory}
        setInventoriesToAdd={setInventoriesToAdd}
      />
      <List.Item>
        <div onClick={() => showModal(true)}>
          <ProductContainer data-testid="product" hoverable>
            <div style={{ display: "flex", marginBottom: "8px" }}></div>
            <Image
              data-testid="image"
              src={OptimizedImageUrl(product.image)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = product.image;
              }}
            />
            <Title data-testid="title">{product.title}</Title>
            <Price data-testid="price">{product.price}</Price>
          </ProductContainer>
        </div>
      </List.Item>
    </>
  );
};

const SizeButtons = ({
  src,
  onClick,
}: {
  src: any[];
  onClick: any;
}): JSX.Element => {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3,
        xxl: 3,
      }}
      dataSource={src}
      renderItem={(item: any, index: any) => (
        <div
          style={{
            gap: 16,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: 5,
          }}
          onClick={onClick}
        >
          <span
            style={{
              justifyContent: "center",
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              // height: 64,
              padding: 5,
              background: "#e6e6e6",
              borderRadius: 4,
            }}
          >
            <div>{item.size}</div>
          </span>
        </div>
      )}
    />
  );
};

export const ProductCardStoreRequest = (props: any): JSX.Element => {
  const {
    product,
    productTemplates,
    updatedProduct,
    dispatch,
    setInventoriesToAdd,
    selectedLocation,
    requestedInventories,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newInventory, setNewInventory] = useState(false);
  const [reload, setReload] = useState(false);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [targetInventory, setTargetInventory] = useState();
  const [showStoreRequestModal, setshowStoreRequestModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const showModal = (value: boolean) => {
    if (!dispatch) return;
    setIsModalVisible(value);
    setNewInventory(value);
  };

  const showAcceptModal = (value: boolean, inventory: Inventory) => {
    setshowStoreRequestModal(value);
    setTargetInventory(inventory);
  };

  useEffect(() => {
    if (reload === true && newInventory === true && dispatch) {
      let search = "";
      dispatch(getStoreProducts(search, selectedLocation));
    }
  }, [reload]);

  if (process.env.REACT_APP_TYPE === "employee") {
    return (
      <>
        <InventoryStoreRequestModal
          product={product}
          id={product.id}
          productTemplates={productTemplates}
          updatedProduct={updatedProduct}
          dispatch={dispatch}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
          isNewEntry={newInventory}
          setNewInventory={setNewInventory}
          setReload={setReload}
          setInventoriesToAdd={setInventoriesToAdd}
        />
        <List.Item>
          <ProductReqContainer data-testid="product" hoverable>
            <div>
              <Image
                data-testid="image"
                src={OptimizedImageUrl(product.image)}
              />
            </div>
            <div>
              <TitleBar data-testid="title">{product.title}</TitleBar>
              <Labler>SKU</Labler>
              <Sku data-testid="sku">
                {product?.sku && product.sku !== "" ? product.sku : "N/A"}
              </Sku>
              <Labler>Size / Price</Labler>
              {!_.isEmpty(product.forRequest) && (
                <>
                  <SizeButtons
                    src={product.forRequest.slice(0, 3)}
                    onClick={() => showModal(true)}
                  />
                  {product.forRequest.length > 3 && !expanded && (
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() => setExpanded(true)}
                    >
                      More sizes <ArrowDownOutlined />
                    </Button>
                  )}
                  {product.forRequest.length > 3 && expanded && (
                    <FloatingExpander>
                      <SizeButtons
                        src={product.forRequest.slice(3)}
                        onClick={() => showModal(true)}
                      />
                      <Button
                        style={{ padding: 0 }}
                        type="link"
                        onClick={() => setExpanded(false)}
                      >
                        Show Less <ArrowUpOutlined />
                      </Button>
                    </FloatingExpander>
                  )}
                </>
              )}
            </div>
          </ProductReqContainer>
        </List.Item>
      </>
    );
  }

  return (
    <>
      {targetInventory && (
        <AcceptStoreRequestModal
          inventory={targetInventory}
          store={store}
          visible={showStoreRequestModal}
          setshowStoreRequestModal={setshowStoreRequestModal}
          productTemplate={productTemplates.filter(
            (template) => template.id === product.productTemplateId
          )}
          fromCatalogue={false}
        />
      )}
      <List.Item>
        <ProductReqContainer data-testid="product">
          <div>
            <Image data-testid="image" src={OptimizedImageUrl(product.image)} />
          </div>
          <div>
            <TitleBar data-testid="title">{product.title}</TitleBar>
            <Labler>SKU</Labler>
            <Sku data-testid="sku">
              {product?.sku && product.sku !== "" ? product.sku : "N/A"}
            </Sku>
            <Labler>Size / Price</Labler>
            {!_.isEmpty(product.forRequest) && (
              <>
                <List
                  grid={{
                    gutter: 16,
                    xs: 3,
                    sm: 3,
                    md: 3,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                  }}
                  dataSource={product.forRequest.slice(0, 3)}
                  renderItem={(item: any, index: any) => (
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "flex-start",
                        display: "flex",
                        flexDirection: "column",
                        // height: 64,
                        padding: 5,
                      }}
                    >
                      <Button
                        type="text"
                        style={{
                          height: "100%",
                          width: "100%",
                          alignItems: "start",
                          justifyContent: "space-between",
                          display: "flex",
                          flexDirection: "column",
                          fontSize: 11,
                          flex: 1,
                          padding: 5,
                          borderRadius: 4,
                          backgroundColor: "#e6e6e6",
                        }}
                        onClick={() => {
                          showAcceptModal(true, item.inventory);
                        }}
                      >
                        <div>{item.size}</div>
                        {item?.inventory?.price ? (
                          <div>
                            {getSymbolFromCurrency(store.currency)}
                            {item?.inventory?.price}
                          </div>
                        ) : (
                          <div>
                            {getSymbolFromCurrency(store.currency)}
                            {item?.inventory?.storeRequestPriceRange[0]}
                            {item?.inventory?.status === "Consignment" && (
                              <>
                                &nbsp;-&nbsp;
                                {getSymbolFromCurrency(store.currency)}
                                {
                                  item?.inventory?.storeRequestPriceRange[1]
                                }{" "}
                              </>
                            )}
                          </div>
                        )}
                      </Button>
                    </div>
                  )}
                />
                {product.forRequest.length > 3 && !expanded && (
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => setExpanded(true)}
                  >
                    More sizes <ArrowDownOutlined />
                  </Button>
                )}
                {product.forRequest.length > 3 && expanded && (
                  <FloatingExpander>
                    <List
                      grid={{
                        gutter: 16,
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3,
                        xxl: 3,
                      }}
                      dataSource={product.forRequest.slice(3)}
                      renderItem={(item: any, index: any) => (
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            // height: 64,
                            padding: 5,
                          }}
                        >
                          <Button
                            type="text"
                            style={{
                              height: "100%",
                              width: "100%",
                              alignItems: "start",
                              justifyContent: "space-between",
                              display: "flex",
                              flexDirection: "column",
                              fontSize: 11,
                              flex: 1,
                              padding: 5,
                              background: "#e6e6e6",
                              borderRadius: 4,
                            }}
                            onClick={() => {
                              showAcceptModal(true, item.inventory);
                            }}
                          >
                            <div>{item.size}</div>
                            {item?.inventory?.price ? (
                              <div>
                                {getSymbolFromCurrency(store.currency)}
                                {item?.inventory?.price}
                              </div>
                            ) : (
                              <div>
                                {getSymbolFromCurrency(store.currency)}
                                {item?.inventory?.storeRequestPriceRange[0]}
                                {/* -
                              {getSymbolFromCurrency(store.currency)}
                              {item?.inventory?.storeRequestPriceRange[1]} */}
                              </div>
                            )}
                          </Button>
                        </div>
                      )}
                    />
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() => setExpanded(false)}
                    >
                      Show Less <ArrowUpOutlined />
                    </Button>
                  </FloatingExpander>
                )}
              </>
            )}
          </div>
        </ProductReqContainer>
      </List.Item>
    </>
  );
};

const CustomModal = styled(Modal)`
  top: 10px;
  &.ant-modal {
    .ant-modal-title {
      text-align: center;
    }
    // width: 100%;
    max-width: 100vw;
    margin: auto;
    .ant-modal-content {
      // width: 999px;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
  @media (max-width: 768px) {
    top: 0;
    left: 0;
    .ant-modal-content {
      padding: 12px;
      width: 100%;
      min-height: 100vh;
      max-height: 100%;
      overflow-y: auto;
      .ant-modal-header {
        padding: 0 0 13px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        text-transform: capitalize;
      }
      .ant-modal-body {
        padding: 0;
      }

      .ant-modal-footer {
        padding: 0;
        display: flex;
        margin: 24px auto;
        button {
          flex: 1;
        }
      }
    }
  }
`;

const StyledInputNumber = styled(InputNumber)`
  @media (max-width: 768px) {
    .ant-input-number-handler-wrap {
      display: flex;
    }
  }
`;

const FormItem = styled(Form.Item)`
  padding: 24px 16px;
  margin: 0;
  border: 1px solid ${Colors.INPUT_BORDER};
  // .ant-row.ant-form-item-row {
  flex-flow: row;
  .ant-col {
    &.ant-form-item-label {
      flex: 1;
      text-align: left;
      text-transform: capitalize;
    }
    &.ant-form-item-control {
      flex: 3;
      input,
      textarea {
        border: 0;
        &:focused,
        &:hover {
          border: 0;
        }
      }
    }
    // }
  }
`;
const ModalImage = styled.img`
  width: 250px;
  margin: 24px 0;
`;

const AcceptStoreRequestModal = (props: any) => {
  const [range, setRange] = useState<[string, string]>(["0", "0"]);
  const dispatch = useAppDispatch();
  const {
    inventory,
    store,
    visible,
    setshowStoreRequestModal,
    productTemplate,
    fromCatalogue,
    signupURL,
  } = props;
  const [updatedInventory, setUpdatedInventory] = useState<Inventory>();

  const { calculatedFees }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  useEffect(() => {
    setRange(
      inventory.price
        ? ["0", "0"]
        : inventory?.storeRequestPriceRange ?? ["0", "0"]
    );
    setUpdatedInventory({
      ...inventory,
    });
  }, [inventory]);

  useEffect(() => {
    if (updatedInventory?.storeRequestPriceRange && !Number(inventory.price)) {
      const actualPrice = inventory.storeRequestPriceRange[0];
      handleChange(actualPrice);
    }
  }, [updatedInventory?.storeRequestPriceRange]);

  useEffect(() => {
    const newInventory = { ...updatedInventory };
    if (updatedInventory?.id) {
      if (Number(updatedInventory.price) !== 0) {
        if (calculatedFees && calculatedFees?.payout) {
          newInventory.payout = String(calculatedFees.payout ?? 0);
          newInventory.payoutAmount = String(calculatedFees.payout ?? 0);
          newInventory.payoutFee = String(calculatedFees.payoutFee ?? 0);
          newInventory.total = String(calculatedFees.total ?? 0);
          console.log(inventory, newInventory);
          setUpdatedInventory(newInventory);
        }
      }
    }
  }, [calculatedFees]);

  const handleChange = async (price: string | number) => {
    const updateItem = {
      ...inventory,
      price,
    };
    if (fromCatalogue) {
      setUpdatedInventory(updateItem);
      return;
    }
    let cost = 0;
    dispatch(
      calculateFees(
        updateItem.id,
        Number(updateItem.price),
        1,
        updateItem.option1Value,
        updateItem.option2Value,
        updateItem.option3Value,
        Number(cost),
        updateItem.category,
        JSON.stringify(dbUser)
      )
    );
    setUpdatedInventory(updateItem);
  };

  // if(!updatedInventory) return <Spin />
  return (
    <Spin spinning={!updatedInventory}>
      <CustomModal
        title="Accepted Inventory"
        visible={visible}
        closable
        footer={null}
        onCancel={() => setshowStoreRequestModal(false)}
      >
        <Form>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ModalImage src={updatedInventory?.product?.image} />
          </span>
          <FormItem label="title">
            <TextArea
              value={updatedInventory?.product?.title}
              readOnly
              onResize={() => null}
            />
          </FormItem>
          <FormItem label="quantity">
            <Input value={1} readOnly />
          </FormItem>
          <FormItem label="Size">
            <Input value={updatedInventory?.option1Value} readOnly />
          </FormItem>
          {updatedInventory?.option2Value && (
            <FormItem label="Condition">
              <Input value={updatedInventory?.option2Value} readOnly />
            </FormItem>
          )}
          {updatedInventory?.option3Value && (
            <FormItem label="Location">
              <Input value={updatedInventory?.option3Value} readOnly />
            </FormItem>
          )}

          <FormItem label="price">
            {inventory?.storeRequestPriceRange &&
            inventory.status === "Consignment" &&
            Number(range?.[0]) ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <InputNumber
                  value={updatedInventory?.price ?? 0}
                  onChange={(value: any) => handleChange(value)}
                  style={{
                    borderColor:
                      Number(range[0]) &&
                      (updatedInventory.price > range[1] ||
                        updatedInventory.price < range[0])
                        ? "red"
                        : "initial",
                  }}
                />
                <span>
                  Ranges from {range[0]} - {range[1]}
                </span>
              </div>
            ) : (
              <Input
                value={`${getSymbolFromCurrency(store?.currency)} ${
                  updatedInventory?.price
                }`}
                readOnly
              />
            )}
          </FormItem>
          <FormItem label="status">
            <Input value={updatedInventory?.status} readOnly />
          </FormItem>
          <FormItem label="payout">
            <Input
              value={`${getSymbolFromCurrency(store?.currency)} ${
                updatedInventory?.status === "Buying"
                  ? updatedInventory?.price
                  : updatedInventory?.payout
              }`}
              readOnly
            />
          </FormItem>
          <span
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              padding: 8,
            }}
          >
            <Button
              style={{ flex: 1, height: 40 }}
              onClick={() => setshowStoreRequestModal(false)}
            >
              Cancel
            </Button>
            <Button
              style={{ flex: 1, height: 40 }}
              onClick={async () => {
                if (fromCatalogue) {
                  window.location = signupURL;
                  return;
                }
                if (
                  Number(range[0]) &&
                  (updatedInventory.price > range[1] ||
                    updatedInventory.price < range[0])
                ) {
                  message.error(
                    "Price is not within the requested price range."
                  );
                  return;
                }
                const inventoryToAccept = {
                  ...updatedInventory,
                  consigner: null,
                };

                await dispatch(
                  storeBuyRequestedInventory(inventoryToAccept, dbUser.id)
                );
                message.success("Inventory accepted successfully", 3);
                setshowStoreRequestModal(false);
              }}
              type="primary"
            >
              Accept
            </Button>
          </span>
        </Form>
      </CustomModal>
    </Spin>
  );
};

export const CatalogueList = (props: any): JSX.Element => {
  const {
    products,
    loading,
    productTemplates,
    updatedProduct,
    dispatch,
    setInventoriesToAdd,
    storeRequest,
    selectedLocation,
    requestedInventories,
    signupURL,
  } = props;

  const showAcceptModal = (value: boolean, inventory: Inventory) => {
    setshowStoreRequestModal(value);
    setTargetInventory(inventory);
  };

  const [targetInventory, setTargetInventory] = useState();
  const [showStoreRequestModal, setshowStoreRequestModal] = useState(false);
  const [expanded, setExpanded] = useState<number[]>([]);

  const { store } = useAppSelector((state) => state.StoreReducer);

  return (
    <Container
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 5,
        xxl: 5,
      }}
      dataSource={products.filter((product) => !_.isEmpty(product.forRequest))}
      loading={loading}
      renderItem={(item: any, index: any) => {
        const targetProduct = products.filter(
          (product) => !_.isEmpty(product.forRequest)
        )[index];
        const inventories = requestedInventories?.filter(
          (inventory) => inventory.productId === targetProduct.id
        );
        return (
          <>
            {targetInventory && (
              <AcceptStoreRequestModal
                inventory={targetInventory}
                store={store}
                visible={showStoreRequestModal}
                setshowStoreRequestModal={setshowStoreRequestModal}
                productTemplate={productTemplates.filter(
                  (template) => template.id === targetProduct.productTemplateId
                )}
                fromCatalogue={true}
                signupURL={signupURL}
              />
            )}
            {inventories && (
              <List.Item>
                <ProductReqContainer data-testid="product">
                  <div>
                    <Image
                      data-testid="image"
                      src={OptimizedImageUrl(targetProduct.image)}
                    />
                  </div>
                  <div>
                    <TitleBar data-testid="title">
                      {targetProduct.title}
                    </TitleBar>
                    <Labler>SKU</Labler>
                    <Sku data-testid="sku">
                      {targetProduct?.sku && targetProduct.sku !== ""
                        ? targetProduct.sku
                        : "N/A"}
                    </Sku>
                    <Labler>Size / Price</Labler>
                    {!_.isEmpty(targetProduct.forRequest) && (
                      <>
                        <List
                          grid={{
                            gutter: 16,
                            xs: 3,
                            sm: 3,
                            md: 3,
                            lg: 3,
                            xl: 3,
                            xxl: 3,
                          }}
                          dataSource={inventories.slice(0, 3)}
                          renderItem={(item: any, index: any) => (
                            <div
                              style={{
                                display: "flex",
                                padding: 5,
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 20,
                              }}
                            >
                              <Button
                                type="text"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  display: "flex",
                                  flexDirection: "column",
                                  fontSize: 11,
                                  flex: 1,
                                  padding: 5,
                                  background: "#e6e6e6",
                                  borderRadius: 4,
                                }}
                                onClick={() => {
                                  showAcceptModal(true, item);
                                }}
                              >
                                <div>{item.option1Value}</div>
                                {item?.price ? (
                                  <div>
                                    {getSymbolFromCurrency(store.currency)}
                                    {item?.price}
                                  </div>
                                ) : (
                                  <div>
                                    {getSymbolFromCurrency(store.currency)}
                                    {item?.storeRequestPriceRange[0]}
                                  </div>
                                )}
                              </Button>
                            </div>
                          )}
                        />
                        {inventories.length > 3 &&
                          !expanded.includes(index) && (
                            <Button
                              style={{ padding: 0 }}
                              type="link"
                              onClick={() => setExpanded([...expanded, index])}
                            >
                              More sizes <ArrowDownOutlined />
                            </Button>
                          )}
                        {inventories.length > 3 && expanded.includes(index) && (
                          <FloatingExpander>
                            <List
                              grid={{
                                gutter: 16,
                                xs: 3,
                                sm: 3,
                                md: 3,
                                lg: 3,
                                xl: 3,
                                xxl: 3,
                              }}
                              dataSource={inventories.slice(3)}
                              renderItem={(item: any, index: any) => (
                                <div
                                  style={{
                                    display: "flex",
                                    padding: 5,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 20,
                                  }}
                                >
                                  <Button
                                    type="text"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      alignItems: "start",
                                      justifyContent: "space-between",
                                      display: "flex",
                                      flexDirection: "column",
                                      fontSize: 11,
                                      flex: 1,
                                      padding: 5,
                                      background: "#e6e6e6",
                                      borderRadius: 4,
                                    }}
                                    onClick={() => {
                                      showAcceptModal(true, item);
                                    }}
                                  >
                                    <div>{item.option1Value}</div>
                                    {item?.price ? (
                                      <div>
                                        {getSymbolFromCurrency(store.currency)}
                                        {item?.price}
                                      </div>
                                    ) : (
                                      <div>
                                        {getSymbolFromCurrency(store.currency)}
                                        {item?.storeRequestPriceRange[0]}
                                      </div>
                                    )}
                                  </Button>
                                </div>
                              )}
                            />
                            <Button
                              style={{ padding: 0 }}
                              type="link"
                              onClick={() =>
                                setExpanded(
                                  [...expanded].filter((idx) => idx !== index)
                                )
                              }
                            >
                              Less sizes <ArrowUpOutlined />
                            </Button>
                          </FloatingExpander>
                        )}
                      </>
                    )}
                  </div>
                </ProductReqContainer>
              </List.Item>
            )}
          </>
        );
      }}
    />
  );
};

const ProductList = (props: Props): JSX.Element => {
  const {
    products,
    loading,
    productTemplates,
    updatedProduct,
    dispatch,
    setInventoriesToAdd,
    storeRequest,
    selectedLocation,
    requestedInventories,
  } = props;

  return (
    <Container
      grid={{
        gutter: 16,
        xs: storeRequest ? 1 : 2,
        sm: storeRequest ? 1 : 2,
        md: storeRequest ? 3 : 4,
        lg: storeRequest ? 3 : 4,
        xl: 4,
        xxl: storeRequest ? 5 : 4,
      }}
      dataSource={
        storeRequest
          ? products.filter((product) => !_.isEmpty(product.forRequest))
          : products
      }
      loading={loading}
      renderItem={(item: any, index: any) =>
        storeRequest ? (
          <ProductCardStoreRequest
            key={index}
            product={
              storeRequest
                ? products.filter((product) => !_.isEmpty(product.forRequest))[
                    index
                  ]
                : products[index]
            }
            productTemplates={productTemplates}
            updatedProduct={updatedProduct}
            dispatch={dispatch}
            setInventoriesToAdd={setInventoriesToAdd}
            selectedLocation={selectedLocation}
            requestedInventories={requestedInventories}
          />
        ) : (
          <ProductCard
            key={index}
            product={products[index]}
            productTemplates={productTemplates}
            updatedProduct={updatedProduct}
            dispatch={dispatch}
            setInventoriesToAdd={setInventoriesToAdd}
          />
        )
      }
    />
  );
};

export default ProductList;
