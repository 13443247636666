import React from "react";
import { CustomModal } from "./inventoryStyle";
import { Button, Form, Row, Col, Select, Input, AutoComplete } from "antd";
const { Option } = Select;

interface ResendLinkProps {
  isModaleVisible: boolean;
}
export default function ResendLink({ isModaleVisible }: ResendLinkProps) {
  const [form] = Form.useForm();

  const onFinish = () => {};

  const handleModale = () => {};
  return (
    <CustomModal
      title={`Order #${"3434"}`}
      width={425}
      visible={isModaleVisible}
      // onCancel={handleModale}
      footer={null} // Make sure footer is set to null to include buttons within the form
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="buyerName" label="Buyer Name">
          <Input
            style={{ borderRadius: "5px" }}
            placeholder="Enter buyer name"
          />
        </Form.Item>

        <Form.Item
          name="buyerEmail"
          label="Seller Email"
          rules={[
            { required: true, message: "Please enter the buyer email" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input
            placeholder="Enter buyer email"
            style={{ borderRadius: "5px" }}
          />
        </Form.Item>
        <Form.Item label="Phone Number" style={{ marginBottom: 0 }}>
          <Form.Item
            name="phonePrefix"
            style={{ display: "inline-block", width: "30%" }}
          >
            <Select style={{ borderRadius: "5px" }}>
              <Option value="1">+1</Option>
              <Option value="91">+91</Option>
              <Option value="44">+44</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              { required: true, message: "Please enter the phone number" },
            ]}
            style={{ display: "inline-block", width: "70%" }}
          >
            <Input
              placeholder="Enter phone number"
              style={{ borderRadius: "5px" }}
            />
          </Form.Item>
        </Form.Item>
      </Form>
    </CustomModal>
  );
}
