import {
  CREATE_PRODUCT,
  CREATE_SUBMIT_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_SUBMITTED_PRODUCTS,
  MERGE_PRODUCT,
  RESYNC_PRODUCT,
  UPDATE_PRODUCT,
  ADD_SELECTED_PRODUCT,
  REMOVE_SELECTED_PRODUCT,
  UPDATE_SELECTED_PRODUCT,
  CLEAR_SELECTED_PRODUCT,
} from "../actions/types";
export type Product = any;
export type SelectedProduct = any;

export interface ProductState {
  productsLoading: boolean;
  products: Product[];
  submittedProductsLoading: boolean;
  submittedProducts: Product[];
  createdProduct: Product | null;
  createProductLoading: boolean;
  updatedProduct: Product | null;
  updateProductLoading: boolean;
  product: Product | null;
  createSubmitProductLoading: boolean;
  createdSubmitProduct: Product | null;
  productLoading: boolean;
  resyncProductLoading: boolean;
  mergeProduct: string;
  mergeProductLoading: boolean;
  selectedProducts: SelectedProduct[];
}

const defaultState = {
  productsLoading: false,
  products: [],
  productLoading: false,
  product: null,
  submittedProducts: [],
  submittedProductsLoading: false,
  createProductLoading: false,
  createdProduct: null,
  updateProductLoading: false,
  updatedProduct: null,
  createSubmitProductLoading: false,
  createdSubmitProduct: null,
  resyncProductLoading: false,
  mergeProduct: "",
  mergeProductLoading: false,
  selectedProducts: [], // Add selected products array
};

export const DefaultProductState = defaultState;

export type ProductAction = {
  productId: any;
  size: any;
  type: string;
  productsLoading: boolean;
  productLoading: boolean;
  submittedProductsLoading: boolean;
  products: Product[];
  product: Product | null;
  submittedProducts: Product[];
  createdProduct: Product | null;
  createProductLoading: boolean;
  updatedProduct: Product | null;
  index: number;
  updateProductLoading: boolean;
  createSubmitProductLoading: boolean;
  createdSubmitProduct: Product | null;
  resyncProduct: string;
  resyncProductLoading: boolean;
  mergeProduct: string;
  mergeProductLoading: boolean;
  selectedProducts: SelectedProduct[];
};

export default (state: ProductState = defaultState, action: ProductAction) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        productsLoading: action.productsLoading,
        products: action.products,
      };
    case ADD_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProducts: [...state.selectedProducts, action.selectedProducts],
      };
    case CLEAR_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProducts: [],
      };
    case REMOVE_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProducts: action.size
          ? state.selectedProducts.filter(
              (product) =>
                !(
                  product.id === action.productId &&
                  product.option1Value === action.size
                )
            )
          : state.selectedProducts.filter(
              (product) => product.id !== action.productId
            ),
      };
    case UPDATE_SELECTED_PRODUCT:
      const { index, updatedProduct } = action;

      let updatedProducts;

      if (index !== undefined && index !== null) {
        // Check if the index is within bounds
        if (index >= 0 && index < state.selectedProducts.length) {
          updatedProducts = [...state.selectedProducts];
          updatedProducts[index] = updatedProduct;
        } else {
          console.error(`Invalid index ${index} for selectedProducts array.`);
          return state; // Return current state unchanged if index is invalid
        }
      } else {
        updatedProducts = state.selectedProducts.map((product) =>
          product.id === updatedProduct.id ? updatedProduct : product
        );
      }

      return {
        ...state,
        selectedProducts: updatedProducts,
      };

    case GET_SUBMITTED_PRODUCTS:
      return {
        ...state,
        submittedProductsLoading: action.submittedProductsLoading,
        submittedProducts: action.submittedProducts,
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        createProductLoading: action.createProductLoading,
        createdProduct: action.createdProduct,
      };
    case GET_PRODUCT:
      return {
        ...state,
        productLoading: action.productLoading,
        product: action.product,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        updateProductLoading: action.updateProductLoading,
        updatedProduct: action.updatedProduct,
      };
    case CREATE_SUBMIT_PRODUCT:
      return {
        ...state,
        createSubmitProductLoading: action.createSubmitProductLoading,
        createdSubmitProduct: action.createdSubmitProduct,
      };
    case RESYNC_PRODUCT:
      return {
        ...state,
        resyncProduct: action.resyncProduct,
        resyncProductLoading: action.resyncProductLoading,
      };
    case MERGE_PRODUCT:
      return {
        ...state,
        mergeProduct: action.mergeProduct,
        mergeProductLoading: action.mergeProductLoading,
      };
    default:
      return state;
  }
};
