import React, { useEffect, useState } from "react";
import { CustomModal, SearchDiv } from "./inventoryStyle";
import { Button, message } from "antd";
import ProductList from "./ProductList";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { InventoryState } from "../../redux/reducers/InventoryReducer";
import { SearchBox } from "../../components/Search/Search";
import { getProducts } from "../../redux/actions/productActions";
import ProductModal from "./ProductModal";
import AddCustomProduct from "./AddCustomProduct";
interface AddInventoryModalProp {
  isModalVisible: boolean;
  setIsModalVisible: () => void;
}
export default function AddInventoryModal({
  isModalVisible,
  setIsModalVisible,
}: AddInventoryModalProp) {
  const { products, updatedProduct, productsLoading, selectedProducts } =
    useAppSelector((state) => state.ProductReducer);
  const { inventoriesToReview }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );
  const { productTemplates } = useAppSelector(
    (state) => state.ProductTemplateReducer
  );
  const [inventoriesToAdd, setInventoriesToAdd] = useState<any[]>(
    inventoriesToReview ?? []
  );
  const [search, setSearch] = useState("");
  const [productModal, setProductModal] = useState<boolean>(false);
  const [customModal, setCustomModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const addToInventories = (items: any[]) => {
    setInventoriesToAdd([...inventoriesToAdd, ...items]);
  };
  const handleCancel = () => {
    setIsModalVisible();
  };
  const handleProductModal = () => {
    if (selectedProducts.length <= 0) {
      message.error("Please select at least one product");
    } else if (selectedProducts.some((product) => !product.option1Value)) {
      message.error("One or more selected products do not have any size");
    } else {
      setIsModalVisible();
      setProductModal(true);
    }
  };
  const handleCustomModal = () => {
    setIsModalVisible();
    setCustomModal(true);
  };
  const handleAddProductModal = () => {
    setProductModal(false);
  };
  const handleAddCustomProductModal = () => {
    setCustomModal(false);
  };
  const handleAddCustomProductModalSuccess = () => {
    setCustomModal(false);
    setProductModal(true);
  };
  useEffect(() => {
    dispatch(getProducts(search));
  }, [search]);
  return (
    <>
      {productModal && (
        <ProductModal
          productModal={handleAddProductModal}
          isProductModal={productModal}
        />
      )}
      {customModal && (
        <AddCustomProduct
          setIsCustomModal={handleAddCustomProductModal}
          setIsSucces={handleAddCustomProductModalSuccess}
          isProductModal={customModal}
        />
      )}
      <CustomModal
        title="Catalogue"
        visible={isModalVisible}
        onCancel={() => handleCancel()}
        width={1200}
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: "flex" }}>
            <Button
              key="back"
              type="primary"
              style={{
                padding: 8,
                width: 90,
                height: 52,
                borderRadius: "8px",
                border: "1px solid #DDDDDD",
                color: "black",
                background: "#F4F4F4",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 10px",
                height: 52,
                borderRadius: "8px",
                background: "#685FF9",
                right: "2%",
                position: "absolute",
              }}
              onClick={() => handleProductModal()}
            >
              Add Products {`(${selectedProducts.length})`}
            </Button>
          </div>,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <SearchDiv style={{ flexGrow: 1, marginRight: "10px" }}>
            <SearchBox
              //   value={}
              //   onChange={(e) => setSearch(e)}
              placeholder={"Search Products"}
            />
          </SearchDiv>
          <Button
            key="submit"
            type="primary"
            style={{
              padding: "8px 10px",
              height: 52,
              border: "1px solid #DDDDDD",
              color: "black",
              borderRadius: "8px",
              background: "#F4F4F4",
            }}
            onClick={() => handleCustomModal()}
          >
            + Custom Product
          </Button>
        </div>
        <ProductList
          selectedProducts={selectedProducts}
          data-testid="productList"
          products={products}
          loading={productsLoading}
          productTemplates={productTemplates}
          updatedProduct={updatedProduct!}
          dispatch={dispatch}
          setInventoriesToAdd={addToInventories}
        />
      </CustomModal>
    </>
  );
}
