import React, { useEffect } from "react";
import { Form, Radio, Checkbox, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useForm } from "antd/lib/form/Form";
import { InventoryFilterOptions } from "../../redux/reducers/InventoryReducer";
import { getSubLocations } from "../../redux/actions/subLocationActions";
import styled from "styled-components";

/**
 * Inventory Filter
 * Used to filer a list of inventory
 * has onFilter prop which triggers on filter changes
 * form of filters
 * TODO Test
 *  - render correctly
 *  - onFilter triggers on filter changes
 */
export interface FormValues {
  printed: string;
  status: string;
  option1Value: string;
  option2Value: string;
  option3Value: string;
  category: string;
  consigner: string;
  orderDateRange?: any;
  productId?: string;
  location?: string;
  subLocation?: string;
  sortOption?: string;
  brand?: string;
}

interface Props {
  formOptions: InventoryFilterOptions;
  onFilter: (values: FormValues) => void;
  showConsignerFilter: boolean;
  showStatusFilter?: boolean;
  currentFilters: FormValues;
  subLocations: any;
  option1Values?: string[];
  option2Values?: string[];
  option3Values?: string[];
}

export const CustomFormItem = styled(Form.Item)`
  flex: 0 100px !important;
  align-self: stretch;
  margin-right: 0 !important;
  margin-bottom: 0;

  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      flex: auto;
      max-width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .ant-select {
      .ant-select-selector {
        overflow: hidden;
      }
    }
  }
  @media (max-width: 768px) {
    .ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      font-size: 16px;
    }
    &.ant-row.ant-form-item {
      margin-right: 0;
    }
  }
`;

const InventoryFilter = (props: Props) => {
  const [form] = useForm();
  const {
    formOptions,
    onFilter,
    showConsignerFilter,
    showStatusFilter,
    currentFilters,
    subLocations,
    option1Values,
    option2Values,
    option3Values,
  } = props;

  const [filteredSubLocations, setFilteredSubLocations] = React.useState<any>(
    []
  );

  useEffect(() => {
    let data;
    if (currentFilters && currentFilters.location) {
      data = subLocations?.filter(
        (item: any) => item.locationName == currentFilters.location
      );
      setFilteredSubLocations(data);
    }
  }, [currentFilters]);

  return (
    <Form
      style={{ flex: 1, gap: 8 }}
      layout="inline"
      name="inventoryFilter"
      form={form}
      data-testid="inventoryFilter"
      onValuesChange={(values) => onFilter({ ...currentFilters, ...values })}
      initialValues={currentFilters}
    >
      {showStatusFilter && (
        <CustomFormItem name="status" style={{ minWidth: 120 }}>
          <Select
            showSearch
            mode="multiple"
            allowClear
            style={{ overflow: "hidden" }}
            placeholder="Status"
            data-testid="statuses"
            defaultValue={currentFilters.status}
          >
            {formOptions.statuses &&
              formOptions.statuses.map((statuses) => (
                <Select.Option key={statuses.label} value={statuses.value}>
                  {statuses.label}
                </Select.Option>
              ))}
          </Select>
        </CustomFormItem>
      )}
      {formOptions.locations && formOptions.locations.length > 0 && (
        <CustomFormItem name="location">
          <Select
            showSearch
            allowClear
            placeholder="Location"
            data-testid="locations"
          >
            {formOptions.locations &&
              formOptions.locations.map((location) => (
                <Select.Option key={location.label} value={location.value}>
                  {location.label}
                </Select.Option>
              ))}
          </Select>
        </CustomFormItem>
      )}
      {filteredSubLocations && filteredSubLocations.length > 0 && (
        <CustomFormItem name="subLocation">
          <Select
            showSearch
            allowClear
            placeholder="Sublocation"
            data-testid="subLocations"
          >
            {filteredSubLocations &&
              filteredSubLocations.length > 0 &&
              filteredSubLocations.map((sublocation: any) => (
                <Select.Option key={sublocation.id} value={sublocation.name}>
                  {sublocation.name}
                </Select.Option>
              ))}
          </Select>
        </CustomFormItem>
      )}
      {showConsignerFilter && formOptions.consigners && (
        <CustomFormItem name="consigner">
          <Select
            // style={{ width: 200 }}
            showSearch
            filterOption={(input, option) =>
              String(option?.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              String(option?.value)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            data-testid="consigners"
            placeholder="Consigner"
            allowClear
          >
            {formOptions.consigners.map((consigner) => (
              <Select.Option
                // this key is currently returning duplicate errors, so it has been temporarly commented
                // key={consigner.label}
                key={consigner.value.id}
                value={JSON.stringify(consigner.value.id)}
                title={consigner.value.email}
              >
                {consigner.label}
              </Select.Option>
            ))}
          </Select>
        </CustomFormItem>
      )}
      {option1Values && option1Values.length > 1 && (
        <CustomFormItem name="option1Value">
          <Select
            showSearch
            allowClear
            placeholder="Option 1"
            data-testid="option1Value"
          >
            {option1Values &&
              option1Values.length > 0 &&
              option1Values.map((option1Value: any) => (
                <Select.Option key={option1Value} value={option1Value}>
                  {option1Value}
                </Select.Option>
              ))}
          </Select>
        </CustomFormItem>
      )}
      {option2Values && option2Values.length > 1 && (
        <CustomFormItem name="option2Value">
          <Select
            showSearch
            allowClear
            placeholder="Option 2"
            data-testid="option2Value"
          >
            {option2Values &&
              option2Values.length > 0 &&
              option2Values.map((option2Value: any) => (
                <Select.Option key={option2Value} value={option2Value}>
                  {option2Value}
                </Select.Option>
              ))}
          </Select>
        </CustomFormItem>
      )}
      {option3Values && option3Values.length > 1 && (
        <CustomFormItem name="option3Value">
          <Select
            showSearch
            allowClear
            placeholder="Option 3"
            data-testid="option3Value"
          >
            {option3Values &&
              option3Values.length > 0 &&
              option3Values.map((option3Value: any) => (
                <Select.Option key={option3Value} value={option3Value}>
                  {option3Value}
                </Select.Option>
              ))}
          </Select>
        </CustomFormItem>
      )}
      <CustomFormItem name="sortOption">
        <Select
          showSearch
          allowClear
          placeholder="Sort Inventory by"
          data-testid="sortOption"
        >
          <Select.Option value="oldestCreated">Oldest Created</Select.Option>
          <Select.Option value="newestCreated">Newest Created</Select.Option>
          <Select.Option value="oldestUpdated">Oldest Updated</Select.Option>
          <Select.Option value="newestUpdated">Newest Updated</Select.Option>
          <Select.Option value="lowestPrice">Lowest Price</Select.Option>
          <Select.Option value="highestPrice">Highest Price</Select.Option>
          {/* Sort by lower price first then newest created */}
          <Select.Option value="inventoryPriority">
            Inventory Priority
          </Select.Option>
          <Select.Option value="lowestPayout">Lowest Payout</Select.Option>
          <Select.Option value="highestPayout">Highest Payout</Select.Option>
          <Select.Option value="sizeLowToHigh">Size: Low to high</Select.Option>
          <Select.Option value="sizeHighToLow">Size: High to Low</Select.Option>
        </Select>
      </CustomFormItem>
    </Form>
  );
};

export default InventoryFilter;
