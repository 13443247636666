import { Modal, Select } from "antd";
import styled from "styled-components";

export const CustomModal = styled(Modal)`
  top: 10px;
  &.ant-modal {
    max-width: 100vw;
    margin: auto;
    .ant-modal-content {
    }
  }
  @media (max-width: 768px) {
    top: 0;
    left: 0;
    .ant-modal-content {
      padding: 12px;
      width: 100%;
      min-height: 100vh;
      max-height: 100%;
      overflow-y: auto;
      .ant-modal-header {
        padding: 0 0 13px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        text-transform: capitalize;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .ant-modal-body {
        padding: 0;
      }

      .ant-modal-footer {
        padding: 0;
        display: flex;
        margin: 24px auto;
        button {
          flex: 1;
        }
      }
    }
  }
`;
export const SearchDiv = styled.span`
  border-radius: 10px !important;
  width: 100%;
`;
export const StyledSelect = styled(Select)`
  width: 100%;
  height: 48px !important;

  .ant-select-selector {
    height: 48px !important;
    display: flex;
    align-items: center;
    border-radius: 8px !important;
  }

  .ant-select-dropdown {
    border-radius: 8px !important;
  }
`;
