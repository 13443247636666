import firebase from "firebase";
import React, { ReactElement, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
//Redux Stuff
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import AuthService from "../../services/auth";
//components
import Topbar from "../../components/Layout/Topbar";
import Footer from "../../components/Layout/Footer";
import EmailCollectionCard from "../../components/Auth/EmailCollectionCard";
//constants
import Content from "../../constants/Content";
import Colors from "../../constants/Colors";
import { consignerNavItems, navItems } from "../../nav/navData";
import { message } from "antd";
import { UserState } from "../../redux/reducers/UserReducer";
/*
SendEmailVerfication Component using Firebase Auth
When a user logges is, firebase sign in is called
User is redirected to secret routes via a firebase onAuthStateChanged listener
onAuthStateChanged fires when user sendEmailVerfication states is changed from logged in to logged off
The logic for this function is found in ./redux/actions/users/setUserAuthState.ts
*/
//styles
const Container = styled.div`
  background: ${Colors.BACKGROUND};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
`;
const ContentContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LogoutLink = styled.a`
  color: #a3a3a3;
  margin-top: 20px;
`;

//types
export interface SendEmailVerficationProps {}

export default function SendEmailVerfication({}: SendEmailVerficationProps): JSX.Element {
  //global state / redux
  const [email, setEmail] = useState("");
  const [sendEmailVerficationLoading, setSendEmailVerficationLoading] =
    useState(false);
  const { fbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const dispatch = useAppDispatch();
  const auth = AuthService();
  const { store } = useAppSelector((state) => state.StoreReducer);

  //helpers
  const sendEmailVerfication = async () => {
    setSendEmailVerficationLoading(true);
    await auth.sendEmailVerfication(dispatch);
    setSendEmailVerficationLoading(false);
  };
  //component
  if (!fbUser) return <Redirect data-testid="dashboardRedirect" to="/login" />;
  if (fbUser && fbUser.emailVerified) {
    if (process.env.REACT_APP_TYPE === "consigner") {
      return <Redirect data-testid="dashboardRedirect" to="/signupSplash" />;
    }
    return <Redirect data-testid="dashboardRedirect" to="/" />;
  }

  return (
    <Container data-testid="sendEmailVerfication">
      <Topbar
        logo={store?.logo!}
        backText={Content.TOPBAR_BACK_TEXT}
        backLink={`https://${store?.redirectURL!}`}
        nav={
          process.env.REACT_APP_TYPE === "consigner"
            ? consignerNavItems
            : navItems
        }
      />
      <ContentContainer>
        <EmailCollectionCard
          onSubmit={(email) => sendEmailVerfication()}
          email={fbUser && fbUser.email ? fbUser.email : email}
          onEmailChange={(newEmail: string) => setEmail(newEmail)}
          loading={sendEmailVerficationLoading}
          title={Content.VERIFY_EMAIL_TITLE}
          message={Content.VERIFY_EMAIL_MESSAGE}
          buttonText={Content.VERIFY_EMAIL_BUTTON_TEXT}
          showConfirm
        />
        <LogoutLink onClick={() => auth.logout()}>Logout</LogoutLink>
      </ContentContainer>

      <Footer />
    </Container>
  );
}
