import { Input } from "antd";
import styled from "styled-components";
const { Search } = Input;

export const SearchBoxWrapper = styled.div`
  position: relative;
  flex: 1;
  .ant-input-affix-wrapper {
    border-radius: 7px !important;
    border: 1px solid #d9d9d9;
    transition: all 0.3s;
    padding-left: 32px; // Adjust padding to make room for the icon
    height: 50px;
    width: 100%;
    &:hover {
      border-color: #40a9ff;
    }

    &:focus,
    &:active {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .ant-input {
    height: 50px;
    border-radius: 4px;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .ant-input-group-addon {
    position: absolute;
    left: 10px !important;
    top: 50%;
    transform: translateY(-50%);
    color: #d9d9d9;
    font-size: 16px;
    z-index: 2;
    button {
      border: none;
    }
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #d9d9d9;
    font-size: 16px;
    z-index: 2;
  }

  @media (max-width: 768px) {
    .ant-input-group-addon {
      left: 25px !important;
    }
  }
`;

export const SearchInput = styled(Input.Search)`
  width: 100%;
  height: 32px; // Adjust the height as needed to match your screenshot

  .ant-input {
    height: 100%;
  }
`;
