import {
  GET_ORDERS,
  GET_ORDER,
  EXPORT_ORDERS,
  RESYNC_ORDER,
  CREATE_ORDER,
} from "../actions/types";
import { Inventory } from "./InventoryReducer";

interface Customer {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

export type Order = any;

export interface DraftOrder {
  line_items: {
    variant_id: string;
    quantity: number;
    title?: string;
    price?: string;
    option?: {
      option1?: string;
    };
  }[];
  customer: {
    first_name: string;
    last_name: string;
    phone: string;
    email?: string;
  };
  note?: string;
}

export interface OrderState {
  orders: Order[];
  ordersLoading: boolean;
  order: Order | null;
  orderLoading: boolean;
  exportedOrders: Order[] | null;
  exportOrdersLoading: boolean;
  resyncingOrderLoading: boolean;
}

const defaultState = {
  orders: [],
  ordersLoading: false,
  order: null,
  orderLoading: false,
  payoutFilterOptionsLoading: false,
  payoutFilterOptions: {
    statuses: [],
  },
  exportedOrders: [],
  exportOrdersLoading: false,
  resyncingOrderLoading: false,
};

export const DefaultOrderState = defaultState;

export type OrderAction = {
  type: string;
  orders: Order[];
  ordersLoading: boolean;
  order: Order | null;
  orderLoading: boolean;
  exportedOrders: Order[] | null;
  exportOrdersLoading: boolean;
  resyncingOrderLoading: boolean;
};

export default (state: OrderState = defaultState, action: OrderAction) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.orders,
        ordersLoading: action.ordersLoading,
      };
    case GET_ORDER:
      return {
        ...state,
        order: action.order,
        orderLoading: action.orderLoading,
      };
    case EXPORT_ORDERS:
      return {
        ...state,
        exportOrdersLoading: action.exportOrdersLoading,
      };
    case RESYNC_ORDER: {
      return {
        ...state,
        resyncingOrderLoading: action.resyncingOrderLoading,
      };
    }
    case CREATE_ORDER: {
      return {
        ...state,
        resyncingOrderLoading: action.resyncingOrderLoading,
      };
    }
    default:
      return state;
  }
};
