import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CustomModal, SearchDiv } from "./inventoryStyle";
import { Button, Col, Form, Input, Row, Select, AutoComplete } from "antd";
import { useAppSelector } from "../../redux/hooks";
import { api } from "../../api";

const { Option } = Select;
const CustomSelect = styled(Select)`
  border-radius: 15px;
`;
interface EditSellerModaleProps {
  isModalVisible: boolean;
  order: any;
}
const EditSellerModale = ({ isModalVisible, order }: EditSellerModaleProps) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const updateOrdersInfo = async (payload: any) => {
    const { data, error } = await api.provide(
      "post",
      "/api/orders/updateOrderInfo",
      payload
    );
    if (error) throw error;
    return data;
  };

  const handleCompleteOrder = (values: any) => {
    const updatedData = {
      sellerName: values.buyerName,
      sellerEmail: values.buyerEmail,
      sellerPhone: values.phonePrefix + values.phoneNumber,
      id: order.id,
    };
    updateOrdersInfo(updatedData)
      .then(() => {
        history.push("/completeOrder");
      })
      .catch((error) => {
        console.error("Error updating order info:", error);
      });
  };

  const onFinish = (values: any) => {
    handleCompleteOrder(values);
  };
  const handleSkip = () => {
    history.push("/completeOrder");
  };
  return (
    <CustomModal
      title={"Seller Info"}
      width={425}
      visible={isModalVisible}
      closeIcon={"Skip"}
      onCancel={handleSkip} // Submit the form on cancel
      footer={[
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          key="footer"
        >
          <Button
            key="submit"
            type="primary"
            style={{
              padding: "8px 40px",
              height: 52,
              borderRadius: "8px",
              background: "#685FF9",
            }}
            onClick={() => form.submit()} // Submit the form on next button click
          >
            Next
          </Button>
        </div>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="buyerName" label="Buyer Name">
          <Input
            style={{ borderRadius: "5px" }}
            placeholder="Enter buyer name"
          />
        </Form.Item>

        <Form.Item
          name="buyerEmail"
          label="Buyer Email"
          rules={[
            { required: true, message: "Please enter the buyer email" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input
            placeholder="Enter buyer email"
            style={{ borderRadius: "5px" }}
          />
        </Form.Item>
        <Form.Item label="Phone Number" style={{ marginBottom: 0 }}>
          <Form.Item
            name="phonePrefix"
            style={{ display: "inline-block", width: "30%" }}
          >
            <Select style={{ borderRadius: "5px" }}>
              <Option value="1">+1</Option>
              <Option value="91">+91</Option>
              <Option value="44">+44</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              { required: true, message: "Please enter the phone number" },
            ]}
            style={{ display: "inline-block", width: "70%" }}
          >
            <Input
              placeholder="Enter phone number"
              style={{ borderRadius: "5px" }}
            />
          </Form.Item>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

interface EditBuyerModaleProps {
  isModalVisible: boolean;
  selectedOrders: any;
  order: any;
  setIsBuyerModaleVisible: () => void;
}

const EditBuyerModale = ({
  isModalVisible,
  selectedOrders,
  order,
  setIsBuyerModaleVisible,
}: EditBuyerModaleProps) => {
  const [sellerModale, setSellerModale] = useState<boolean>(false);
  const [orderSeller, setOrderSeller] = useState<any[]>();
  const [form] = Form.useForm();

  const handleModale = () => {
    setIsBuyerModaleVisible();
  };

  const handleSubmit = (values: any) => {
    const updatedData = { ...values, id: order.id };
    openSellerModale(updatedData);
  };

  const updateOrdersInfo = async (payload: any) => {
    const { data, error } = await api.provide(
      "post",
      "/api/orders/updateOrderInfo",
      payload
    );
    if (error) throw error;
    return data;
  };
  useEffect(() => {
    setOrderSeller(order);
  }, [orderSeller]);

  const openSellerModale = async (payload: any) => {
    await updateOrdersInfo(payload);
    setSellerModale(true);
  };

  return (
    <>
      {sellerModale && (
        <EditSellerModale order={orderSeller} isModalVisible={sellerModale} />
      )}
      {isModalVisible && (
        <CustomModal
          title={`Order #${"3434"}`}
          width={425}
          visible={isModalVisible}
          onCancel={handleModale}
          footer={null} // Make sure footer is set to null to include buttons within the form
        >
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item name="buyerName" label="Buyer Name">
              <AutoComplete
                className="custom-auto-complete"
                options={[{ value: "Buyer 1" }, { value: "Buyer 2" }]}
                placeholder="Select or enter buyer"
                filterOption={(inputValue, option) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="shippingMethod" label="Shipping Method">
                  <Select placeholder="Select shipping method">
                    <Select.Option value="standard">Standard</Select.Option>
                    <Select.Option value="express">Express</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="shippingCost" label="Shipping Cost">
                  <Input type="number" placeholder="Enter shipping cost" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="paymentDetail" label="Payment Details">
              <Select placeholder="Select payment method">
                <Select.Option value="creditCard">Credit Card</Select.Option>
                <Select.Option value="paypal">PayPal</Select.Option>
                <Select.Option value="bankTransfer">
                  Bank Transfer
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="primary"
                  style={{
                    padding: "8px 40px",
                    height: 52,
                    borderRadius: "8px",
                    border: "1px solid #DDDDDD",
                    color: "black",
                    background: "#F4F4F4",
                  }}
                  onClick={handleModale}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  style={{
                    padding: "8px 40px",
                    height: 52,
                    borderRadius: "8px",
                    background: "#685FF9",
                  }}
                  onClick={() => form.submit()} // Trigger form submission
                >
                  Next
                </Button>
              </div>
            </Form.Item>
          </Form>
        </CustomModal>
      )}
    </>
  );
};

interface EditOrderModaleProps {
  isModalVisible: boolean;
  setIsModalVisible: () => void;
  selectedOrders: any[];
  orders: any;
  order: any;
}
export default function EditOrderModale({
  isModalVisible,
  setIsModalVisible,
  selectedOrders,
  order,
  orders,
}: EditOrderModaleProps) {
  const [editBuyer, setEditBuyer] = useState<boolean>(false);
  const [buyerOrder, setBuyerORder] = useState<any[]>();

  const handleCancel = () => {
    setIsModalVisible();
  };
  const handleEditBuyer = () => {
    setEditBuyer(true);
  };

  const showModale = () => {
    setEditBuyer(false);
  };
  useEffect(() => {
    setBuyerORder(order);
  }, [buyerOrder]);
  const totalPrice = selectedOrders.reduce((acc, order) => {
    const price = parseFloat(order.price);
    return acc + (isNaN(price) ? 0 : price);
  }, 0);
  const totalCost = selectedOrders.reduce((acc, order) => {
    const price = parseFloat(order.cost);
    return acc + (isNaN(price) ? 0 : price);
  }, 0);
  return (
    <>
      {editBuyer && (
        <EditBuyerModale
          isModalVisible={editBuyer}
          selectedOrders={selectedOrders}
          order={buyerOrder}
          setIsBuyerModaleVisible={showModale}
        />
      )}
      <CustomModal
        title={`Order ${orders.name}`}
        visible={isModalVisible}
        onCancel={() => handleCancel()}
        width={800}
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: "flex" }}>
            <Button
              key="back"
              type="primary"
              style={{
                padding: "8px 40px",
                height: 52,
                borderRadius: "8px",
                border: "1px solid #DDDDDD",
                color: "black",
                background: "#F4F4F4",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 40px",
                height: 52,
                borderRadius: "8px",
                background: "#685FF9",
                right: "2%",
                position: "absolute",
              }}
              onClick={() => handleEditBuyer()}
            >
              Next
            </Button>
          </div>,
        ]}
      >
        <span style={{ fontWeight: "700" }}>Order Items</span>
        <Row
          style={{
            background: "#F9FAFB",
            border: "1px solid #DDDDDD",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            padding: "1rem",
          }}
        >
          <Col span={16} style={{ fontWeight: "500", color: "#2E2E2E" }}>
            Inventory
          </Col>
          <Col
            span={4}
            style={{
              fontWeight: "500",
              color: "#2E2E2E",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Price
          </Col>
          <Col
            span={4}
            style={{
              fontWeight: "500",
              color: "#2E2E2E",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Cost
          </Col>
        </Row>
        {selectedOrders.map((order, key) => (
          <Row
            key={key}
            style={{
              background: "#FFFFFF",
              border: "1px solid #DDDDDD",
              padding: "1rem",
            }}
          >
            <Col
              span={15}
              style={{
                fontWeight: "500",
                color: "#2E2E2E",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={order.product.image}
                alt=""
                style={{ height: "61px", width: "61px" }}
              />
              <p>{order.product.title}</p>
            </Col>
            <Col
              span={4}
              style={{
                fontWeight: "500",
                color: "#2E2E2E",
                display: "flex",
                alignItems: "center",
                paddingRight: "20px",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  border: "1px #E4E4E7 solid",
                  padding: "3px 25px",
                  borderRadius: "5px",
                }}
              >
                {order.price}
              </p>
            </Col>
            <Col
              span={4}
              style={{
                fontWeight: "500",
                color: "#2E2E2E",
                display: "flex",
                paddingRight: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  border: "1px #E4E4E7 solid",
                  padding: "3px 25px",
                  borderRadius: "5px",
                }}
              >
                {order.cost}
              </p>
            </Col>
          </Row>
        ))}

        <Row
          style={{
            background: "#FFFFFF",
            border: "1px solid #DDDDDD",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            padding: "1rem",
          }}
        >
          <Col span={16} style={{ fontWeight: "500", color: "#685FF9" }}>
            Total Price
          </Col>
          <Col
            span={4}
            style={{
              fontWeight: "600",
              color: "#2E2E2E",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {totalPrice}
          </Col>
          <Col
            span={4}
            style={{
              fontWeight: "500",
              color: "#685FF9",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {totalCost}
          </Col>
        </Row>
      </CustomModal>
    </>
  );
}
