import firebase from "firebase";
import Cookies from "js-cookie";

import { InventoryFormValues } from "../../screens/AddInventory";
import { UpdateInventoryFormValues } from "../../screens/InventoryItem";
import { FormValues as InventoryFilterValues } from "../../components/Common/InventoryFilter";
import { FormValues as OrderFilterValues } from "../../components/Common/OrderFilter";

import {
  Inventory,
  InventoryFee,
  InventoryFilterOptions,
  InventoryPreapproval,
} from "../reducers/InventoryReducer";
import { AppDispatch } from "../store";
import {
  ACCEPT_INVENTORY,
  ACCEPT_INVENTORY_PRICE,
  ACCEPT_INVENTORY_WITHDRAWAL,
  ACCEPT_TRANSFER_INVENTORY,
  CALCULATE_FEES,
  CREATE_INVENTORY,
  DECLINE_INVENTORY,
  DECLINE_INVENTORY_PRICE,
  DECLINE_INVENTORY_WITHDRAWAL,
  DELETE_INVENTORY,
  EXPORT_INVENTORY,
  GET_INVENTORIES,
  GET_INVENTORY,
  GET_INVENTORY_FILTER_OPTIONS,
  PRINT_INVENTORY,
  REQUEST_INVENTORY_PRICE_CHANGE,
  REQUEST_INVENTORY_WITHDRAWAL,
  RESET_CREATED_INVENTORY,
  SCAN_GTIN_INVENTORY,
  TRANSFER_INVENTORY,
  UPDATE_INVENTORY,
  WITHDRAW_INVENTORY,
  GET_PAGINATED_INVENTORIES,
  CANCEL_DROPOFF,
  ACCEPT_REQUESTED_INVENTORY,
  DECLINE_REQUESTED_INVENTORY,
  SET_INVENTORIES_TO_ADD,
  GET_ORDERS_PAYOUTS_INVENTORIES,
} from "./types";
import { Product } from "../reducers/ProductReducer";
import { ProductTemplate } from "../reducers/ProductTemplateReducer";
import { api } from "../../api";
import moment from "moment";
import { NewConsignerFormValues } from "../../consignerScreens/ConsignerSignup";
import { Store } from "../reducers/StoreReducer";

const getLastUpdatedUser = () => {
  let parseItem = Cookies.get("loadedUser");
  let parsedItem;
  if (parseItem) {
    parsedItem = JSON.parse(`${parseItem}`);
  }
  const lastUpdatedBy = {
    id: parsedItem?.id,
    name: parsedItem?.name,
  };
  return lastUpdatedBy;
};

/*
  actions
  (an object with :
  a type and a payload)
*/

const getPaginatedInventoriesSuccess = (paginatedInventories: {
  rows: Inventory[];
  count: number;
}) => {
  return {
    type: GET_PAGINATED_INVENTORIES,
    paginatedInventories: paginatedInventories,
    inventoriesLoading: false,
  };
};

const getOrdersPayoutsInventoriesSuccess = (
  ordersPayoutsInventories: Inventory[]
) => {
  return {
    type: GET_ORDERS_PAYOUTS_INVENTORIES,
    ordersPayoutsInventories: ordersPayoutsInventories,
    inventoriesLoading: false,
  };
};

const setInventoriesToAdd = (inventories: Inventory[]) => ({
  type: SET_INVENTORIES_TO_ADD,
  inventoriesToReview: inventories,
});

const getPaginatedInventoriesLoading = () => ({
  type: GET_PAGINATED_INVENTORIES,
  paginatedInventories: [],
  inventoriesLoading: true,
});

const getPaginatedInventoriesError = () => ({
  type: GET_PAGINATED_INVENTORIES,
  paginatedInventories: [],
  inventoriesLoading: false,
});

const getOrdersPayoutsInventoriesLoading = () => ({
  type: GET_ORDERS_PAYOUTS_INVENTORIES,
  ordersPayoutsInventories: [],
  inventoriesLoading: true,
});

const getOrdersPayoutsInventoriesError = () => ({
  type: GET_ORDERS_PAYOUTS_INVENTORIES,
  ordersPayoutsInventories: [],
  inventoriesLoading: false,
});

const getInventoriesLoading = () => ({
  type: GET_INVENTORIES,
  inventories: [],
  inventoriesLoading: true,
});

const getInventoriesError = () => ({
  type: GET_INVENTORIES,
  inventories: [],
  inventoriesLoading: false,
});
const getInventoriesSuccess = (inventories: Inventory[]) => ({
  type: GET_INVENTORIES,
  inventories,
  inventoriesLoading: false,
});
const updateInventorySuccess = (updatedInventory: Inventory) => ({
  type: UPDATE_INVENTORY,
  updatedInventory,
  updatedInventoryLoading: false,
});
const updateInventoryLoading = () => ({
  type: UPDATE_INVENTORY,
  updatedInventory: [],
  updatedInventoryLoading: true,
});

const updateInventoryError = () => ({
  type: UPDATE_INVENTORY,
  updatedInventory: [],
  updatedInventoryLoading: false,
});

const getInventoryLoading = () => ({
  type: GET_INVENTORY,
  inventory: null,
  inventoryLoading: true,
});

const getInventoryError = () => ({
  type: GET_INVENTORY,
  inventory: null,
  inventoryLoading: false,
});

const getInventorySuccess = (inventory: Inventory) => ({
  type: GET_INVENTORY,
  inventory,
  inventoryLoading: false,
});
const getInventoryFilterOptionsLoading = () => ({
  type: GET_INVENTORY_FILTER_OPTIONS,
  inventoryFilterOptions: [],
  inventoryFilterOptionsLoading: true,
});

const getInventoryFilterOptionsError = () => ({
  type: GET_INVENTORY_FILTER_OPTIONS,
  inventoryFilterOptions: [],
  inventoryFilterOptionsLoading: false,
});

const getInventoryFilterOptionsSuccess = (
  inventoryFilterOptions: InventoryFilterOptions
) => ({
  type: GET_INVENTORY_FILTER_OPTIONS,
  inventoryFilterOptions,
  inventoryFilterOptionsLoading: false,
});

const checkPreapprovalLoading = () => ({
  type: CALCULATE_FEES,
  calculatedFees: [],
  calculatedFeesLoading: true,
});

const checkPreapprovalError = () => ({
  type: CALCULATE_FEES,
  calculatedFees: [],
  calculatedFeesLoading: false,
});

const checkPreapprovalSuccess = (calculatedFees: InventoryFee) => ({
  type: CALCULATE_FEES,
  calculatedFees,
  calculatedFeesLoading: false,
});

const calculateFeesLoading = () => ({
  type: CALCULATE_FEES,
  calculatedFees: [],
  calculatedFeesLoading: true,
});

const calculateFeesError = () => ({
  type: CALCULATE_FEES,
  calculatedFees: [],
  calculatedFeesLoading: false,
});

const calculateFeesSuccess = (calculatedFees: InventoryFee) => ({
  type: CALCULATE_FEES,
  calculatedFees,
  calculatedFeesLoading: false,
});

const createInventoryLoading = () => ({
  type: CREATE_INVENTORY,
  createdInventory: null,
  createdInventoryLoading: true,
});

const createInventoryError = () => ({
  type: CREATE_INVENTORY,
  createdInventory: null,
  createdInventoryLoading: false,
});

const createInventorySuccess = (createdInventory: Inventory) => ({
  type: CREATE_INVENTORY,
  createdInventory,
  createdInventoryLoading: false,
});

const bulkPrintInventoryLoading = () => ({
  type: PRINT_INVENTORY,
  bulkPrintedInventory: null,
  bulkPrintInventoryLoading: true,
});

const bulkPrintInventoryError = () => ({
  type: PRINT_INVENTORY,
  bulkPrintedInventory: null,
  bulkPrintInventoryLoading: false,
});

const bulkPrintInventorySuccess = (bulkPrintedInventory: Inventory[]) => ({
  type: PRINT_INVENTORY,
  bulkPrintedInventory,
  bulkPrintInventoryLoading: false,
});

const exportInventoryLoading = () => ({
  type: EXPORT_INVENTORY,
  exportedInventory: null,
  exportInventoryLoading: true,
});

const exportInventoryError = () => ({
  type: EXPORT_INVENTORY,
  exportedInventory: null,
  exportInventoryLoading: false,
});

const exportInventorySuccess = (exportedInventory: Inventory[]) => ({
  type: EXPORT_INVENTORY,
  exportedInventory,
  exportInventoryLoading: false,
});

const acceptRequestedInventoryLoading = () => ({
  type: ACCEPT_REQUESTED_INVENTORY,
  acceptRequestedInventory: null,
  acceptRequestedInventoryLoading: true,
});

const acceptInventoryLoading = () => ({
  type: ACCEPT_INVENTORY,
  acceptInventory: null,
  acceptInventoryLoading: true,
});

const acceptRequestedInventoryError = () => ({
  type: ACCEPT_REQUESTED_INVENTORY,
  acceptRequestedInventory: null,
  acceptRequestedInventoryLoading: false,
});

const acceptInventoryError = () => ({
  type: ACCEPT_INVENTORY,
  acceptInventory: null,
  acceptInventoryLoading: false,
});

const scanGTINInventorySuccess = (data: ScanGtinResponse) => ({
  type: SCAN_GTIN_INVENTORY,
  scanGTINInventories: data.scanGTINInventories,
  scanGTINProduct: data.scanGTINProduct,
  scanGTINInventoriesLoading: false,
  scanGTINProductTemplate: data.scanGTINProductTemplate,
  gtinSize: data.gtinSize,
});
const scanGTINInventoryLoading = () => ({
  type: SCAN_GTIN_INVENTORY,
  scanGTINInventories: null,
  scanGTINProduct: null,
  scanGTINInventoriesLoading: true,
  scanGTINProductTemplate: null,
  gtinSize: null,
});

const scanGTINInventoryError = () => ({
  type: SCAN_GTIN_INVENTORY,
  scanGTINInventories: null,
  scanGTINProduct: null,
  scanGTINInventoriesLoading: false,
  scanGTINProductTemplate: null,
  gtinSize: null,
});

const acceptRequestedInventorySuccess = (
  acceptRequestedInventory: Inventory
) => ({
  type: ACCEPT_REQUESTED_INVENTORY,
  acceptRequestedInventory,
  acceptRequestedInventoryLoading: false,
});

const acceptInventorySuccess = (acceptInventory: Inventory) => ({
  type: ACCEPT_INVENTORY,
  acceptInventory,
  acceptInventoryLoading: false,
});

const declineInventoryLoading = () => ({
  type: DECLINE_INVENTORY,
  declineInventory: null,
  declineInventoryLoading: true,
});

const declineInventoryError = () => ({
  type: DECLINE_INVENTORY,
  declineInventory: null,
  declineInventoryLoading: false,
});

const declineInventorySuccess = (declineInventory: Inventory) => ({
  type: DECLINE_INVENTORY,
  declineInventory,
  declineInventoryLoading: false,
});

const transferInventoryLoading = () => ({
  type: TRANSFER_INVENTORY,
  transferInventoryLoading: true,
});

const transferInventoryError = () => ({
  type: TRANSFER_INVENTORY,
  transferInventoryLoading: false,
});

const transferInventorySuccess = () => ({
  type: TRANSFER_INVENTORY,
  transferInventoryLoading: false,
});

const acceptTransferInventoryLoading = () => ({
  type: ACCEPT_TRANSFER_INVENTORY,
  acceptTransferInventoryLoading: true,
});

const acceptTransferInventoryError = () => ({
  type: ACCEPT_TRANSFER_INVENTORY,
  acceptTransferInventoryLoading: false,
});

const acceptTransferInventorySuccess = () => ({
  type: ACCEPT_TRANSFER_INVENTORY,
  acceptTransferInventoryLoading: false,
});

const withdrawInventoryLoading = () => ({
  type: WITHDRAW_INVENTORY,
  withdrawInventory: null,
  withdrawInventoryLoading: true,
});

const withdrawInventoryError = () => ({
  type: WITHDRAW_INVENTORY,
  withdrawInventory: null,
  withdrawInventoryLoading: false,
});

const withdrawInventorySuccess = (withdrawInventory: Inventory) => ({
  type: WITHDRAW_INVENTORY,
  withdrawInventory,
  withdrawInventoryLoading: false,
});

const acceptInventoryPriceLoading = () => ({
  type: ACCEPT_INVENTORY_PRICE,
  acceptInventoryPrice: null,
  acceptInventoryPriceLoading: true,
});

const acceptInventoryPriceError = () => ({
  type: ACCEPT_INVENTORY_PRICE,
  acceptInventoryPrice: null,
  acceptInventoryPriceLoading: false,
});

const acceptInventoryPriceSuccess = (acceptInventoryPrice: Inventory) => ({
  type: ACCEPT_INVENTORY_PRICE,
  acceptInventoryPrice,
  acceptInventoryPriceLoading: false,
});

const declineInventoryPriceLoading = () => ({
  type: DECLINE_INVENTORY_PRICE,
  declineInventoryPrice: null,
  declineInventoryPriceLoading: true,
});

const declineInventoryPriceError = () => ({
  type: DECLINE_INVENTORY_PRICE,
  declineInventoryPrice: null,
  declineInventoryPriceLoading: false,
});

const declineInventoryPriceSuccess = (declineInventoryPrice: Inventory) => ({
  type: DECLINE_INVENTORY_PRICE,
  declineInventoryPrice,
  declineInventoryPriceLoading: false,
});
const deleteInventoryLoading = () => ({
  type: DELETE_INVENTORY,
  deletedInventory: null,
  deleteInventoryLoading: true,
});

const deleteInventoryError = () => ({
  type: DELETE_INVENTORY,
  deletedInventory: null,
  deleteInventoryLoading: false,
});

const deleteInventorySuccess = (deletedInventory: Inventory) => ({
  type: DELETE_INVENTORY,
  deletedInventory,
  deleteInventoryLoading: false,
});
const requestInventoryPriceChangeLoading = () => ({
  type: REQUEST_INVENTORY_PRICE_CHANGE,
  requestInventoryPriceChangedInventory: null,
  requestInventoryPriceChangeLoading: true,
});

const requestInventoryPriceChangeError = () => ({
  type: REQUEST_INVENTORY_PRICE_CHANGE,
  requestInventoryPriceChangedInventory: null,
  requestInventoryPriceChangeLoading: false,
});

const requestInventoryPriceChangeSuccess = (
  requestInventoryPriceChangedInventory: Inventory
) => ({
  type: REQUEST_INVENTORY_PRICE_CHANGE,
  requestInventoryPriceChangedInventory,
  requestInventoryPriceChangeLoading: false,
});

const requestInventoryWithdrawalSuccess = (
  requestInventoryWithdrawal: Inventory
) => ({
  type: REQUEST_INVENTORY_WITHDRAWAL,
  requestInventoryWithdrawal,
  requestInventoryWithdrawalLoading: false,
});

const requestInventoryWithdrawalLoading = () => ({
  type: REQUEST_INVENTORY_WITHDRAWAL,
  requestInventoryWithdrawalLoading: true,
});

const requestInventoryWithdrawalError = () => ({
  type: REQUEST_INVENTORY_WITHDRAWAL,
  requestInventoryWithdrawal: null,
  requestInventoryWithdrawalLoading: false,
});

const acceptInventoryWithdrawalSuccess = (
  acceptInventoryWithdrawal: Inventory
) => ({
  type: ACCEPT_INVENTORY_WITHDRAWAL,
  acceptInventoryWithdrawal,
  acceptInventoryWithdrawalLoading: false,
});

const acceptInventoryWithdrawalLoading = () => ({
  type: ACCEPT_INVENTORY_WITHDRAWAL,
  acceptInventoryWithdrawalLoading: true,
});

const acceptInventoryWithdrawalError = () => ({
  type: ACCEPT_INVENTORY_WITHDRAWAL,
  acceptInventoryWithdrawal: null,
  acceptInventoryWithdrawalLoading: false,
});

const declineInventoryWithdrawalSuccess = (
  declineInventoryWithdrawal: Inventory
) => ({
  type: DECLINE_INVENTORY_WITHDRAWAL,
  declineInventoryWithdrawal,
  declineInventoryWithdrawalLoading: false,
});

const declineInventoryWithdrawalLoading = () => ({
  type: DECLINE_INVENTORY_WITHDRAWAL,
  declineInventoryWithdrawalLoading: true,
});

const declineInventoryWithdrawalError = () => ({
  type: DECLINE_INVENTORY_WITHDRAWAL,
  declineInventoryWithdrawal: null,
  declineInventoryWithdrawalLoading: false,
});

const cancelDropoffLoading = () => ({
  type: CANCEL_DROPOFF,
  cancelDropoff: null,
  cancelDropoffLoading: true,
});

const cancelDropoffSuccess = (cancelDropoff: number) => ({
  type: CANCEL_DROPOFF,
  cancelDropoff,
  cancelDropoffLoading: false,
});

const cancelDropoffError = () => ({
  type: CANCEL_DROPOFF,
  acceptInventory: null,
  acceptInventoryLoading: false,
});

export const getInventoriesFromAPI = async (
  search: string,
  filters: InventoryFilterValues
) => {
  let filterStatusOptions = "";

  if (typeof filters.status === "string" && filters.status.length !== 0) {
    filterStatusOptions += filters.status;
  } else {
    if (filters.status.length > 0) {
      for (let i = 0; i < filters.status.length; i++) {
        if (i === 0) {
          filterStatusOptions += filters.status[i];
        } else {
          filterStatusOptions += "&status=" + filters.status[i];
        }
      }
    }
  }
  const encodedLocation = encodeURIComponent(filters.location ?? "");

  const payload = {
    ...filters,
    location: encodedLocation,
    status: filterStatusOptions,
    search,
  };
  const { data, error } = await api.provide(
    "get",
    "/api/inventories",
    payload as any
  );
  if (error) throw error;
  return (data?.inventories || []) as any;
};
const getOrderInventoriesFromAPI = async (
  search: string,
  filters: OrderFilterValues
) => {
  const payload = {
    consigner: filters.consigner,
    dateRangeStart: filters.orderDateRange[0],
    dateRangeEnd: filters.orderDateRange[1],
    status: "Sold",
    search,
  };
  const { data, error } = await api.provide("get", "/api/inventories", payload);
  if (error) throw error;
  return (data?.inventories || []) as any;
};

export const getPaginatedInventoriesFromAPI = async (
  search: string,
  filters: InventoryFilterValues,
  currentPage: number,
  pageSize: number,
  takeProducts = false,
  takeConsigners = false
) => {
  var filterStatusOptions = "";

  if (typeof filters.status === "string" && filters.status.length !== 0) {
    filterStatusOptions += filters.status;
  } else {
    if (filters.status.length > 0) {
      for (let i = 0; i < filters.status.length; i++) {
        if (i === 0) {
          filterStatusOptions += filters.status[i];
        } else {
          filterStatusOptions += "&status=" + filters.status[i];
        }
      }
    }
  }

  const encodedLocation = encodeURIComponent(filters.location ?? "");

  const payload = {
    ...filters,
    location: encodedLocation,
    status: filterStatusOptions,
    search,
    pageSize,
    currentPage,
    takeProducts,
    takeConsigners,
  };
  console.log("=====payload=======", payload);

  const { data, error } = await api.provide(
    "get",
    "/api/inventories/paginatedInventories",
    payload as any
  );
  if (error) throw error;
  return (data || []) as any;
};

export const getOrdersPayoutInventoriesFromAPI = async (
  search: string,
  filters: InventoryFilterValues,
  currentPage: number,
  pageSize: number
) => {
  var filterStatusOptions = "";

  if (typeof filters.status === "string" && filters.status.length !== 0) {
    filterStatusOptions += filters.status;
  } else {
    if (filters.status.length > 0) {
      for (let i = 0; i < filters.status.length; i++) {
        if (i === 0) {
          filterStatusOptions += filters.status[i];
        } else {
          filterStatusOptions += "&status=" + filters.status[i];
        }
      }
    }
  }

  const encodedLocation = encodeURIComponent(filters.location ?? "");

  const payload = {
    ...filters,
    location: encodedLocation,
    status: filterStatusOptions,
    search,
    pageSize,
    currentPage,
  };
  const { data, error } = await api.provide(
    "get",
    "/api/inventories/ordersPayoutInventories",
    payload as any
  );
  if (error) throw error;
  return (data || []) as any;
};

const getPayoutInventoriesFromAPI = async (
  search: string,
  filters: OrderFilterValues
) => {
  const payload = {
    payoutId: null,
    status: "Sold",
    search,
    consigner: filters.consigner,
    dateRangeStart: filters.orderDateRange[0],
    dateRangeEnd: filters.orderDateRange[1],
  };
  const { data, error } = await api.provide("get", "/api/inventories", payload);
  if (error) throw error;
  return (data?.inventories || []) as any;
};

export const getLowestPriceForVariantFromAPI = async (input: {
  option1Value: string;
  option2Value?: string;
  option3Value?: string;
  location: string;
  productId: number;
}): Promise<{ price: number; qty: number }> => {
  const payload = {
    option1Value: input.option1Value,
    option2Value: input?.option2Value ?? undefined,
    option3Value: input?.option3Value ?? undefined,
    location: input.location,
    productId: input.productId,
  };
  const { data, error } = await api.provide(
    "get",
    "/api/inventories/getLowestPriceForVariant",
    payload
  );
  if (error) throw error;
  return (data || {}) as any;
};

export const getConsignerStoreRequestedItemsFromAPI = async (
  consigner: string,
  location: string,
  search: string,
  sale: string
) => {
  const { data, error } = await api.provide(
    "get",
    "/api/inventories/consignerRequestedItems",
    {
      consigner,
      location: location ?? "",
      search: search ?? "",
      sale: sale ?? "",
    }
  );
  if (error) throw error;
  return data;
};

const getInventoryFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/inventories/:id", {
    id,
  });
  if (error) throw error;
  return data;
};

export const getInventoryByCodeFromAPI = async (code: string) => {
  const { data, error } = await api.provide(
    "get",
    "/api/inventories/code/:code",
    {
      code,
    }
  );
  if (error) throw error;
  return data;
};

const updateInventoryFromAPI = async (
  inventoryId: string,
  inventoryFormValues: UpdateInventoryFormValues
) => {
  const payload = {
    id: inventoryId,
    consigner: inventoryFormValues.consigner,
    location: inventoryFormValues.location,
    option1Value: inventoryFormValues.option1Value,
    option2Value: inventoryFormValues.option2Value,
    option3Value: inventoryFormValues.option3Value,
    payoutFee: inventoryFormValues.payoutFee,
    price: inventoryFormValues.price,
    subLocation: inventoryFormValues.subLocation,
    payoutAmount: inventoryFormValues.payout,
    cost: inventoryFormValues.cost,
    status: inventoryFormValues.status,
    notes: inventoryFormValues.notes,
    lastUpdatedBy: getLastUpdatedUser(),
  };

  const { data, error } = await api.provide(
    "put",
    "/api/inventories/:id",
    payload
  );
  if (error) throw error;
  return data;
};

export const getInventoryStatusFromAPI = async (): Promise<any> => {
  const { data, error } = await api.provide(
    "get",
    "/api/inventories/getStoreInventoryStatus",
    {}
  );
  if (error) throw error;
  return data as any;
};

export const checkPreapprovalStatusFromAPI = async (
  id: string,
  price: number,
  option1Value: any,
  option2Value: any,
  option3Value: any,
  consigner: any
): Promise<InventoryPreapproval> => {
  const payload = {
    id,
    price,
    option1Value,
    option2Value,
    option3Value,
    consigner: JSON.parse(consigner).fbUserId,
  };
  const { data, error } = await api.provide(
    "get",
    "/api/inventories/preapprovalStatus",
    payload
  );
  if (error) throw error;
  return data as any;
};

export const calculateFeesFromAPI = async (
  id: string,
  price: number,
  quantity: number,
  option1Value: any,
  option2Value: any,
  option3Value: any,
  cost: number,
  category: any,
  consigner: any
): Promise<InventoryFee> => {
  const payload = {
    id,
    // payoutFee,
    price,
    quantity,
    option1Value,
    option2Value,
    option3Value,
    cost,
    category,
    consigner: JSON.parse(consigner).fbUserId,
  };
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/fees",
    payload
  );
  if (error) throw error;
  return data as any;
};

const createInventoryFromAPI = async (
  inventoryFormValues: InventoryFormValues,
  productId: string
) => {
  const payload = {
    price: inventoryFormValues.price,
    option1Value: inventoryFormValues.option1Value,
    option2Value: inventoryFormValues.option2Value,
    option3Value: inventoryFormValues.option3Value,
    quantity: inventoryFormValues.quantity,
    consignerFbUserId:
      inventoryFormValues.consigner &&
      JSON.parse(inventoryFormValues.consigner) &&
      JSON.parse(inventoryFormValues.consigner).fbUserId
        ? JSON.parse(inventoryFormValues.consigner).fbUserId
        : await firebase.auth().currentUser?.uid,
    productId,
    cost: inventoryFormValues.cost,
    category: inventoryFormValues.category,
    subLocation: inventoryFormValues.subLocation,
  };

  const { data, error } = await api.provide("post", "/api/inventories", {
    inventoryFormArray: payload,
  });
  if (error) throw error;
  return data;
};

export const createBuyOrderFromApi = async (
  newConsigner: NewConsignerFormValues,
  inventoryFormValues: InventoryFormValues[],
  store: Store
) => {
  const consigner: any = {
    firstName: newConsigner.firstName,
    lastName: newConsigner.lastName,
    email: newConsigner.email,
    phone: newConsigner.phone,
    address: newConsigner.address,
    accountName: newConsigner.accountName,
    accountNumber: newConsigner.accountNumber,
    bank: newConsigner.bank,
    bankType: newConsigner.bankType,
    branchCode: newConsigner.branchCode,
    bankData: newConsigner.bankData,
    accountType: newConsigner.accountType,
    storeId: newConsigner.storeId,
  };
  let inventoryFormArray = [];
  for (let i = 0; i < inventoryFormValues.length; i++) {
    let item = {
      price: inventoryFormValues[i].price,
      option1Value: inventoryFormValues[i].option1Value,
      option2Value: inventoryFormValues[i].option2Value,
      option3Value: inventoryFormValues[i].option3Value,
      quantity: inventoryFormValues[i].quantity,
      status: inventoryFormValues[i]?.status,
      storeRequestPriceRange: inventoryFormValues[i]?.storeRequestPriceRange,
      consignerFbUserId: undefined,
      productId: inventoryFormValues[i].productId,
      cost: inventoryFormValues[i].cost,
      category: inventoryFormValues[i].category,
      lastUpdatedBy: getLastUpdatedUser(),
      preapprovalStatus: inventoryFormValues[i]?.preapprovalStatus,
      location: inventoryFormValues[i]?.location,
      subLocation: inventoryFormValues[i]?.subLocation,
      taxable:
        inventoryFormValues[i].consigner &&
        JSON.parse(inventoryFormValues[i].consigner) &&
        JSON.parse(inventoryFormValues[i].consigner).chargeTax
          ? JSON.parse(inventoryFormValues[i].consigner).chargeTax
          : false,
    };
    inventoryFormArray.push(item as never);
  }

  const { data, error } = await api.provide(
    "post",
    "/open/stores/createBuyOrder",
    {
      consigner,
      inventoryFormArray,
      store,
    }
  );
  if (error) throw error;
  return data;
};

const createInventoriesFromAPI = async (
  inventoryFormValues: InventoryFormValues[],
  productId?: string
) => {
  let inventoryFormArray = [];
  for (let i = 0; i < inventoryFormValues.length; i++) {
    let item = {
      price: inventoryFormValues[i].price,
      Seller: inventoryFormValues[i]?.Seller,
      shippingCost: inventoryFormValues[i]?.shippingCost,
      option1Value: inventoryFormValues[i].option1Value,
      option2Value: inventoryFormValues[i].option2Value,
      option3Value: inventoryFormValues[i].option3Value,
      quantity: inventoryFormValues[i].quantity,
      status: inventoryFormValues[i]?.status,
      storeRequestPriceRange: inventoryFormValues[i]?.storeRequestPriceRange,
      consignerFbUserId:
        inventoryFormValues[i].consigner &&
        JSON.parse(inventoryFormValues[i].consigner) &&
        JSON.parse(inventoryFormValues[i].consigner).fbUserId
          ? JSON.parse(inventoryFormValues[i].consigner).fbUserId
          : await firebase.auth().currentUser?.uid,
      productId: productId ?? inventoryFormValues[i].productId,
      cost: inventoryFormValues[i].cost,
      category: inventoryFormValues[i].category,
      lastUpdatedBy: getLastUpdatedUser(),
      preapprovalStatus: inventoryFormValues[i]?.preapprovalStatus,
      location: inventoryFormValues[i]?.location,
      subLocation: inventoryFormValues[i]?.subLocation,
      taxable:
        inventoryFormValues[i].consigner &&
        JSON.parse(inventoryFormValues[i].consigner) &&
        JSON.parse(inventoryFormValues[i].consigner).chargeTax
          ? JSON.parse(inventoryFormValues[i].consigner).chargeTax
          : false,
      invoiceId: inventoryFormValues[i].invoiceId,
      acceptedOn: inventoryFormValues[i].acceptedOn,
    };
    inventoryFormArray.push(item as never);
  }
  console.log("====inventoryFormValues====", inventoryFormValues);

  const { data, error } = await api.provide("post", "/api/inventories", {
    inventoryFormArray,
  });
  if (error) throw error;
  return data;
};

const getInventoryFilterOptionsFromAPI =
  async (): Promise<InventoryFilterOptions> => {
    const { data, error } = await api.provide(
      "get",
      "/api/inventories/filterOptions",
      {}
    );
    if (error) throw error;
    return (data || []) as any;
  };

export const getInStoreInventoryDetailsFromAPI = async (
  inventory: Inventory
): Promise<{ inStock: number; relatedInventories: Inventory[] }> => {
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/inStoreInventoryDetails",
    {
      inventory,
    } as any
  );
  if (error) throw error;
  return (data || []) as any;
};

const exportInventoryFromAPI = async (
  search: string,
  filters: InventoryFilterValues
) => {
  const { data, error } = await api.provide(
    "get",
    "/api/inventories/exportInventory",
    {
      ...filters,
      search,
    } as any
  );
  if (error) throw error;
  const pom = document.createElement("a");
  // @ts-ignore
  const blob = new Blob([data?.csv || ""], { type: "text/csv;charset=utf-8;" });
  pom.href = URL.createObjectURL(blob);
  pom.setAttribute("download", "inventoryExport.csv");
  pom.click();
  return data as any;
};

const bulkPrintInventoryFromAPI = async (
  bulkPrintInventory: Inventory[]
): Promise<Inventory[]> => {
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/bulkPrintInventory",
    {
      bulkPrintInventory,
    }
  );
  if (error) throw error;
  return (data || []) as any;
};

const acceptRequestedInventoryFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/acceptRequestToSell",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};

export const acceptRequestedInventoryPriceChangeFromAPI = async (
  code: string
): Promise<void> => {
  const { data, error } = await api.provide(
    "post",
    "/open/stores/acceptRequestedInventoryPriceChange/:code",
    {
      code,
    }
  );
  if (error) throw error;
  return data;
};

export const rejectRequestedInventoryPriceChangeFromAPI = async (
  code: string
): Promise<void> => {
  const { data, error } = await api.provide(
    "post",
    "/open/stores/rejectRequestedInventoryPriceChange/:code",
    {
      code,
    }
  );
  if (error) throw error;
  return data;
};

const bulkAcceptRequestedInventoryFromAPI = async (
  inventories: any,
  location: string,
  subLocation: string
): Promise<Inventory> => {
  //hardcode store for this

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/bulkAcceptRequestToSell",
    {
      inventories: inventories.length ? inventories : inventories.inventory,
      location,
      subLocation,
      lastUpdatedBy: getLastUpdatedUser(),
    }
  );
  if (error) throw error;
  return data;
};

const acceptInventoryFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/acceptInventory",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};

const storeBuyRequestedInventoryFromAPI = async (
  inventory: Inventory,
  consigner: number | string
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/storeBuyRequestedInventory",
    {
      inventories: [inventory],
      consigner,
    }
  );
  if (error) throw error;
  return data;
};

const cancelDropoffFromAPI = async (
  inventoryIds: number[],
  consignerId: number
): Promise<number> => {
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/cancelDropoff",
    {
      ids: inventoryIds,
      consigner: consignerId,
      lastUpdatedBy: getLastUpdatedUser(),
    }
  );
  if (error) throw error;
  return data.updated;
};

const bulkAcceptInventoryFromAPI = async (
  inventories: any,
  location: string,
  subLocation: string
): Promise<Inventory> => {
  //hardcode store for this

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/bulkAcceptInventory",
    {
      inventories: inventories.length ? inventories : inventories.inventory,
      location,
      subLocation,
      lastUpdatedBy: getLastUpdatedUser(),
    }
  );
  if (error) throw error;
  return data;
};

const declineInventoryFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/declineInventory",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};
const transferInventoriesFromAPI = async (
  inventories: Inventory[],
  location: string,
  shouldOverrideLocationOption: boolean,
  locationOption: string
): Promise<Inventory> => {
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/transfer",
    {
      inventories,
      location,
      shouldOverrideLocationOption,
      locationOption,
      lastUpdatedBy: getLastUpdatedUser(),
    }
  );
  if (error) throw error;
  return data;
};
const acceptTransferInventoriesFromAPI = async (
  inventories: Inventory[],
  location: string
): Promise<Inventory> => {
  //store
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/acceptTransfer",
    {
      inventories,
      location,
      lastUpdatedBy: getLastUpdatedUser(),
    }
  );
  if (error) throw error;
  return data;
};
const withdrawInventoryFromAPI = async (
  inventory: Inventory,
  isWithdrawnEarly: boolean,
  withdrawalFee: number
): Promise<Inventory> => {
  //store
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/withdrawInventory",
    {
      inventory,
      isWithdrawnEarly,
      withdrawalFee,
      lastUpdatedBy: getLastUpdatedUser(),
    }
  );
  if (error) throw error;
  return data;
};

const acceptInventoryPriceFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/acceptInventoryPrice",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};

const declineInventoryPriceFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/declineInventoryPrice",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};

const requestInventoryWithdrawalFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/requestInventoryWithdrawal",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};

const acceptInventoryWithdrawalFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/acceptInventoryWithdrawal",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};

const declineInventoryWithdrawalFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/declineInventoryWithdrawal",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};

const deleteInventoryFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/deleteInventory",
    {
      data: inventory,
    }
  );
  if (error) throw error;
  return data;
};

const requestInventoryPriceChangeFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/requestPriceChange",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};

const storeRequestInventoryPriceChangeFromAPI = async (
  inventory: Inventory
): Promise<Inventory> => {
  inventory.lastUpdatedBy = getLastUpdatedUser();

  const { data, error } = await api.provide(
    "post",
    "/api/inventories/storeRequestPriceChange",
    {
      inventory,
    }
  );
  if (error) throw error;
  return data;
};

interface ScanGtinResponse {
  scanGTINProduct: Product;
  scanGTINInventories: Inventory[];
  scanGTINProductTemplate: ProductTemplate;
  gtinSize: string;
}

const scanGTINInventoryFromAPI = async (scanData: {
  consigner: string;
  location: string;
  gtin: string;
}): Promise<ScanGtinResponse> => {
  const { data, error } = await api.provide(
    "post",
    "/api/inventories/scan",
    scanData
  );
  if (error) throw error;
  return {
    scanGTINProduct: data?.scanGTINProduct,
    scanGTINInventories: data?.scanGTINInventories,
    scanGTINProductTemplate: data?.scanGTINProduct.productTemplate,
    gtinSize: data?.gtinSize,
  };
};

/*
  action creators
  (a function that :
  returns an object)
*/

export const getInventories = (
  search: string,
  filters: InventoryFilterValues
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInventoriesLoading());
    try {
      dispatch(
        getInventoriesSuccess(await getInventoriesFromAPI(search, filters))
      );
    } catch (e) {
      console.log(e);
      dispatch(getInventoriesError());
    }
  };
};

export const getPaginatedInventories = (
  search: string,
  filters: InventoryFilterValues,
  currentPage: number,
  pageSize: number,
  takeProducts = false,
  takeConsigners = false
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getPaginatedInventoriesLoading());
    try {
      dispatch(
        getPaginatedInventoriesSuccess(
          await getPaginatedInventoriesFromAPI(
            search,
            filters,
            currentPage,
            pageSize,
            takeProducts,
            takeConsigners
          )
        )
      );
    } catch (e) {
      console.log(e);
      dispatch(getPaginatedInventoriesError());
    }
  };
};

export const getOrdersPayoutInventories = (
  search: string,
  filters: InventoryFilterValues,
  currentPage: number,
  pageSize: number
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getOrdersPayoutsInventoriesLoading());
    try {
      dispatch(
        getOrdersPayoutsInventoriesSuccess(
          await getOrdersPayoutInventoriesFromAPI(
            search,
            filters,
            currentPage,
            pageSize
          )
        )
      );
    } catch (e) {
      console.log(e);
      dispatch(getOrdersPayoutsInventoriesError());
    }
  };
};
export const getOrderInventories = (
  search: string,
  filters: OrderFilterValues
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInventoriesLoading());
    try {
      dispatch(
        getInventoriesSuccess(await getOrderInventoriesFromAPI(search, filters))
      );
    } catch (e) {
      dispatch(getInventoriesError());
    }
  };
};

export const getPayoutInventories = (
  search: string,
  filters: OrderFilterValues
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInventoriesLoading());
    try {
      dispatch(
        getInventoriesSuccess(
          await getPayoutInventoriesFromAPI(search, filters)
        )
      );
    } catch (e) {
      dispatch(getInventoriesError());
    }
  };
};
export const getInventoryFilterOptions = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInventoryFilterOptionsLoading());
    try {
      dispatch(
        getInventoryFilterOptionsSuccess(
          await getInventoryFilterOptionsFromAPI()
        )
      );
    } catch (e) {
      dispatch(getInventoryFilterOptionsError());
    }
  };
};

export const calculateFees = (
  id: string,
  price: number,
  quantity: number,
  option1Value: any,
  option2Value: any,
  option3Value: any,
  cost: number,
  category: any,
  consigner: any
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(calculateFeesLoading());
    try {
      dispatch(
        calculateFeesSuccess(
          await calculateFeesFromAPI(
            id,
            // payoutFee,
            price,
            quantity,
            option1Value,
            option2Value,
            option3Value,
            cost,
            category,
            consigner
          )
        )
      );
    } catch (e) {
      dispatch(calculateFeesError());
    }
  };
};

export const createInventory = (
  inventoryFormValues: InventoryFormValues,
  productId: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createInventoryLoading());
    try {
      dispatch(
        createInventorySuccess(
          await createInventoryFromAPI(inventoryFormValues, productId)
        )
      );
    } catch (e) {
      dispatch(createInventoryError());
    }
  };
};

export const createInventories = (
  inventoryFormValues: InventoryFormValues[],
  productId?: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createInventoryLoading());

    try {
      dispatch(
        createInventorySuccess(
          await createInventoriesFromAPI(inventoryFormValues, productId)
        )
      );
    } catch (e) {
      dispatch(createInventoryError());
    }
  };
};

export const bulkPrintInventory = (inventoriesToPrint: Inventory[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(bulkPrintInventoryLoading());
    try {
      dispatch(
        bulkPrintInventorySuccess(
          await bulkPrintInventoryFromAPI(inventoriesToPrint)
        )
      );
    } catch (e) {
      dispatch(bulkPrintInventoryError());
    }
  };
};

export const exportInventory = (
  search: string,
  filters: InventoryFilterValues
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(exportInventoryLoading());
    try {
      dispatch(
        exportInventorySuccess(await exportInventoryFromAPI(search, filters))
      );
    } catch (e) {
      dispatch(exportInventoryError());
    }
  };
};

//TODO: accept, decline, withdraw, acceptPrice, declinePrice, printAfterPriceChange
export const acceptRequestToSell = (inventory: Inventory) => {
  return async (dispatch: AppDispatch) => {
    dispatch(acceptRequestedInventoryLoading());
    try {
      dispatch(
        acceptRequestedInventorySuccess(
          await acceptRequestedInventoryFromAPI(inventory)
        )
      );
    } catch (e) {
      dispatch(acceptRequestedInventoryError());
    }
  };
};

export const bulkAcceptRequestToSell = (
  inventories: Inventory[],
  location: string,
  subLocation: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(acceptRequestedInventoryLoading());
    try {
      dispatch(
        acceptRequestedInventorySuccess(
          await bulkAcceptRequestedInventoryFromAPI(
            inventories,
            location,
            subLocation
          )
        )
      );
    } catch (e) {
      dispatch(acceptRequestedInventoryError());
    }
  };
};

//TODO: accept, decline, withdraw, acceptPrice, declinePrice, printAfterPriceChange
export const acceptInventory = (inventory: Inventory) => {
  return async (dispatch: AppDispatch) => {
    dispatch(acceptInventoryLoading());
    try {
      dispatch(acceptInventorySuccess(await acceptInventoryFromAPI(inventory)));
    } catch (e) {
      dispatch(acceptInventoryError());
    }
  };
};
export const cancelDropoff = (inventoryIds: number[], consignerId: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(cancelDropoffLoading());
    try {
      dispatch(
        cancelDropoffSuccess(
          await cancelDropoffFromAPI(inventoryIds, consignerId)
        )
      );
    } catch (e) {
      dispatch(cancelDropoffError());
    }
  };
};

export const bulkAcceptInventory = (
  inventories: Inventory[],
  location: string,
  subLocation: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(acceptInventoryLoading());
    try {
      dispatch(
        acceptInventorySuccess(
          await bulkAcceptInventoryFromAPI(inventories, location, subLocation)
        )
      );
    } catch (e) {
      dispatch(acceptInventoryError());
    }
  };
};

export const declineInventory = (inventory: Inventory) => {
  return async (dispatch: AppDispatch) => {
    dispatch(declineInventoryLoading());
    try {
      dispatch(
        declineInventorySuccess(await declineInventoryFromAPI(inventory))
      );
    } catch (e) {
      dispatch(declineInventoryError());
    }
  };
};

export const transferInventories = (
  inventories: Inventory[],
  location: string,
  shouldOverrideLocationOption: boolean,
  locationOption: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(transferInventoryLoading());
    try {
      await transferInventoriesFromAPI(
        inventories,
        location,
        shouldOverrideLocationOption,
        locationOption
      );
      dispatch(transferInventorySuccess());
    } catch (e) {
      dispatch(transferInventoryError());
    }
  };
};

export const acceptTransferInventories = (
  inventories: Inventory[],
  location: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(acceptTransferInventoryLoading());
    try {
      await acceptTransferInventoriesFromAPI(inventories, location);
      dispatch(acceptTransferInventorySuccess());
    } catch (e) {
      dispatch(acceptTransferInventoryError());
    }
  };
};

export const withdrawInventory = (
  inventory: Inventory,
  isWithdrawnEarly: boolean,
  withdrawalFee: number
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(withdrawInventoryLoading());
    try {
      await dispatch(
        withdrawInventorySuccess(
          await withdrawInventoryFromAPI(
            inventory,
            isWithdrawnEarly,
            withdrawalFee
          )
        )
      );
      dispatch(getInventory(inventory.id));
    } catch (e) {
      dispatch(withdrawInventoryError());
    }
  };
};

export const acceptInventoryPrice = (inventory: Inventory) => {
  return async (dispatch: AppDispatch) => {
    dispatch(acceptInventoryPriceLoading());
    try {
      await dispatch(
        acceptInventoryPriceSuccess(
          await acceptInventoryPriceFromAPI(inventory)
        )
      );
    } catch (e) {
      dispatch(acceptInventoryPriceError());
    }
  };
};

export const setInventoriesToAddAction = (inventories: Inventory[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setInventoriesToAdd(inventories));
  };
};

export const declineInventoryPrice = (inventory: Inventory) => {
  return async (dispatch: AppDispatch) => {
    dispatch(declineInventoryPriceLoading());
    try {
      dispatch(
        declineInventoryPriceSuccess(
          await declineInventoryPriceFromAPI(inventory)
        )
      );
    } catch (e) {
      dispatch(declineInventoryPriceError());
    }
  };
};

export const getInventory = (inventoryId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInventoryLoading());
    try {
      dispatch(getInventorySuccess(await getInventoryFromAPI(inventoryId)));
    } catch (e) {
      dispatch(getInventoryError());
    }
  };
};

export const updateInventory = (
  inventoryId: string,
  inventoryFormValues: UpdateInventoryFormValues
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateInventoryLoading());
    try {
      dispatch(
        updateInventorySuccess(
          await updateInventoryFromAPI(inventoryId, inventoryFormValues)
        )
      );
    } catch (e) {
      dispatch(updateInventoryError());
    }
  };
};
export const requestInventoryPriceChange = (
  inventory: Inventory,
  filters: InventoryFilterValues
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(requestInventoryPriceChangeLoading());
    try {
      await dispatch(
        requestInventoryPriceChangeSuccess(
          await requestInventoryPriceChangeFromAPI(inventory)
        )
      );
      dispatch(getInventories("", filters));
    } catch (e) {
      dispatch(requestInventoryPriceChangeError());
    }
  };
};
export const storeRequestInventoryPriceChange = (
  inventory: Inventory,
  filters: InventoryFilterValues
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(requestInventoryPriceChangeLoading());
    try {
      await dispatch(
        requestInventoryPriceChangeSuccess(
          await storeRequestInventoryPriceChangeFromAPI(inventory)
        )
      );
      dispatch(getInventories("", filters));
    } catch (e) {
      dispatch(requestInventoryPriceChangeError());
    }
  };
};
export const deleteInventory = (inventory: Inventory) => {
  return async (dispatch: AppDispatch) => {
    dispatch(deleteInventoryLoading());
    try {
      await dispatch(
        deleteInventorySuccess(await deleteInventoryFromAPI(inventory))
      );
      dispatch(getInventory(inventory.id));
    } catch (e) {
      dispatch(deleteInventoryError());
    }
  };
};
export const requestInventoryWithdrawal = (
  inventory: Inventory,
  filters: InventoryFilterValues
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(requestInventoryWithdrawalLoading());
    try {
      await dispatch(
        requestInventoryWithdrawalSuccess(
          await requestInventoryWithdrawalFromAPI(inventory)
        )
      );
      dispatch(getInventories("", filters));
    } catch (e) {
      dispatch(requestInventoryWithdrawalError());
    }
  };
};
export const acceptInventoryWithdrawal = (inventory: Inventory) => {
  return async (dispatch: AppDispatch) => {
    dispatch(acceptInventoryWithdrawalLoading());
    try {
      await dispatch(
        acceptInventoryWithdrawalSuccess(
          await acceptInventoryWithdrawalFromAPI(inventory)
        )
      );
    } catch (e) {
      dispatch(acceptInventoryWithdrawalError());
    }
  };
};
export const declineInventoryWithdrawal = (inventory: Inventory) => {
  return async (dispatch: AppDispatch) => {
    dispatch(declineInventoryWithdrawalLoading());
    try {
      await dispatch(
        declineInventoryWithdrawalSuccess(
          await declineInventoryWithdrawalFromAPI(inventory)
        )
      );
    } catch (e) {
      dispatch(declineInventoryWithdrawalError());
    }
  };
};
//TODO: accept, decline, withdraw, acceptPrice, declinePrice, printAfterPriceChange
export const storeBuyRequestedInventory = (
  inventory: Inventory,
  consigner: number | string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(acceptInventoryLoading());
    try {
      dispatch(
        acceptInventorySuccess(
          await storeBuyRequestedInventoryFromAPI(inventory, consigner)
        )
      );
    } catch (e) {
      dispatch(acceptInventoryError());
    }
  };
};
export const resetCreatedInventory = () => {
  return {
    type: RESET_CREATED_INVENTORY,
    createdInventory: null,
  };
};

export const scanGTINInventory = (scanData: {
  consigner: string;
  location: string;
  gtin: string;
}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(scanGTINInventoryLoading());
    try {
      dispatch(
        scanGTINInventorySuccess(await scanGTINInventoryFromAPI(scanData))
      );
    } catch (e) {
      dispatch(scanGTINInventoryError());
    }
  };
};
