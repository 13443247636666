import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Select } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import Fonts from "../../constants/Fonts";
/**
 * Consigner Signup Form
 */
const { Option } = Select;

const Container = styled.div`
  width: 100%;
`;

const FormInput = styled(Input)`
  font-family: ${Fonts.CONSIGNER};
  width: 100%;
  height: 65px;
  padding: 16px;
  margin: 10px auto;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: var(--base-white, #fff);
  font-size: 16px;
`;

const FormPass = styled(Input.Password)`
  display: inline-flex;
  width: 100%;
  padding: 16px;
  margin: 10px auto;
  input {
    font-family: ${Fonts.CONSIGNER};
    width: 100%;
    height: 30px;
    border: 1px solid var(--stroke-color, #e4e4e7);
    background: var(--base-white, #fff);
    font-size: 16px;
    flex: 1;
  }
`;

const FormSelect = styled(Select)`
  font-family: ${Fonts.CONSIGNER};
  width: 100%;
  height: 65px;
  padding: 16px;
  margin: 10px auto;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: var(--base-white, #fff);
  font-size: 16px;
  &.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border: none;
    }
  }
`;

interface Props {
  email: string;
  password: string;
  confirm: string;
  phone: string;
  firstName: string;
  lastName: string;
  catalogue?: string;
  sizing?: string;
  step: number;
  currency?: string;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  setConfirm: (value: string) => void;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setPhone: (value: string) => void;
  setCatalogue: (value: string) => void;
  setSizing: (value: string) => void;
  setCurrency: (value: string) => void;
  showPasswordInput: boolean;
}

const StoreSignupForm = (props: Props): JSX.Element => {
  const {
    email,
    password,
    confirm,
    firstName,
    lastName,
    phone,
    catalogue,
    sizing,
    currency,
    step,
    setEmail,
    setPassword,
    setConfirm,
    setFirstName,
    setLastName,
    setSizing,
    setPhone,
    setCatalogue,
    setCurrency,
    showPasswordInput,
  } = props;
  const typeOptions = ["Sneakers", "Luxury", "Watches", "None"];
  const sizingOptions = ["US", "EU"];
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  if (step === 2)
    return (
      <Container>
        <FormSelect
          placeholder="What type of store are you?"
          value={catalogue}
          onChange={(e: any) => setCatalogue(e)}
        >
          {typeOptions.map((type) => (
            <Option key={type}>{type}</Option>
          ))}
        </FormSelect>
        <FormSelect
          placeholder="Currency?"
          value={currency}
          onChange={(e: any) => setCurrency(e)}
          allowClear
          showSearch
          dropdownStyle={{
            width: "100%",
          }}
        >
          <Select.Option value="AED">AED</Select.Option>
          <Select.Option value="ALL">ALL</Select.Option>
          <Select.Option value="AMD">AMD</Select.Option>
          <Select.Option value="AOA">AOA</Select.Option>
          <Select.Option value="ARS">ARS</Select.Option>
          <Select.Option value="AUD">AUD</Select.Option>
          <Select.Option value="AZN">AZN</Select.Option>
          <Select.Option value="BAM">BAM</Select.Option>
          <Select.Option value="BBD">BBD</Select.Option>
          <Select.Option value="BGN">BGN</Select.Option>
          <Select.Option value="BHD">BHD</Select.Option>
          <Select.Option value="BMD">BMD</Select.Option>
          <Select.Option value="BOB">BOB</Select.Option>
          <Select.Option value="BRL">BRL</Select.Option>
          <Select.Option value="BWP">BWP</Select.Option>
          <Select.Option value="BYN">BYN</Select.Option>
          <Select.Option value="CAD">CAD</Select.Option>
          <Select.Option value="CDF">CDF</Select.Option>
          <Select.Option value="CHF">CHF</Select.Option>
          <Select.Option value="CLP">CLP</Select.Option>
          <Select.Option value="CNY">CNY</Select.Option>
          <Select.Option value="COP">COP</Select.Option>
          <Select.Option value="CRC">CRC</Select.Option>
          <Select.Option value="CVE">CVE</Select.Option>
          <Select.Option value="CZK">CZK</Select.Option>
          <Select.Option value="DKK">DKK</Select.Option>
          <Select.Option value="DOP">DOP</Select.Option>
          <Select.Option value="DZD">DZD</Select.Option>
          <Select.Option value="EGP">EGP</Select.Option>
          <Select.Option value="EUR">EUR</Select.Option>
          <Select.Option value="FJD">FJD</Select.Option>
          <Select.Option value="GBP">GBP</Select.Option>
          <Select.Option value="GEL">GEL</Select.Option>
          <Select.Option value="GHS">GHS</Select.Option>
          <Select.Option value="GIP">GIP</Select.Option>
          <Select.Option value="GTQ">GTQ</Select.Option>
          <Select.Option value="GYD">GYD</Select.Option>
          <Select.Option value="HKD">HKD</Select.Option>
          <Select.Option value="HNL">HNL</Select.Option>
          <Select.Option value="HRK">HRK</Select.Option>
          <Select.Option value="HUF">HUF</Select.Option>
          <Select.Option value="IDR">IDR</Select.Option>
          <Select.Option value="ILS">ILS</Select.Option>
          <Select.Option value="IMP">IMP</Select.Option>
          <Select.Option value="INR">INR</Select.Option>
          <Select.Option value="IQD">IQD</Select.Option>
          <Select.Option value="ISK">ISK</Select.Option>
          <Select.Option value="JMD">JMD</Select.Option>
          <Select.Option value="JOD">JOD</Select.Option>
          <Select.Option value="JPY">JPY</Select.Option>
          <Select.Option value="KES">KES</Select.Option>
          <Select.Option value="KGS">KGS</Select.Option>
          <Select.Option value="KHR">KHR</Select.Option>
          <Select.Option value="KRW">KRW</Select.Option>
          <Select.Option value="KWD">KWD</Select.Option>
          <Select.Option value="KYD">KYD</Select.Option>
          <Select.Option value="KZT">KZT</Select.Option>
          <Select.Option value="LAK">LAK</Select.Option>
          <Select.Option value="LBP">LBP</Select.Option>
          <Select.Option value="LKR">LKR</Select.Option>
          <Select.Option value="LYD">LYD</Select.Option>
          <Select.Option value="MAD">MAD</Select.Option>
          <Select.Option value="MDL">MDL</Select.Option>
          <Select.Option value="MGA">MGA</Select.Option>
          <Select.Option value="MKD">MKD</Select.Option>
          <Select.Option value="MMK">MMK</Select.Option>
          <Select.Option value="MNT">MNT</Select.Option>
          <Select.Option value="MOP">MOP</Select.Option>
          <Select.Option value="MRU">MRU</Select.Option>
          <Select.Option value="MUR">MUR</Select.Option>
          <Select.Option value="MVR">MVR</Select.Option>
          <Select.Option value="MWK">MWK</Select.Option>
          <Select.Option value="MXN">MXN</Select.Option>
          <Select.Option value="MYR">MYR</Select.Option>
          <Select.Option value="MZN">MZN</Select.Option>
          <Select.Option value="NAD">NAD</Select.Option>
          <Select.Option value="NGN">NGN</Select.Option>
          <Select.Option value="NIO">NIO</Select.Option>
          <Select.Option value="NOK">NOK</Select.Option>
          <Select.Option value="NZD">NZD</Select.Option>
          <Select.Option value="OMR">OMR</Select.Option>
          <Select.Option value="PAB">PAB</Select.Option>
          <Select.Option value="PEN">PEN</Select.Option>
          <Select.Option value="PGK">PGK</Select.Option>
          <Select.Option value="PHP">PHP</Select.Option>
          <Select.Option value="PKR">PKR</Select.Option>
          <Select.Option value="PLN">PLN</Select.Option>
          <Select.Option value="PYG">PYG</Select.Option>
          <Select.Option value="QAR">QAR</Select.Option>
          <Select.Option value="RON">RON</Select.Option>
          <Select.Option value="RSD">RSD</Select.Option>
          <Select.Option value="RUB">RUB</Select.Option>
          <Select.Option value="RWF">RWF</Select.Option>
          <Select.Option value="SAR">SAR</Select.Option>
          <Select.Option value="SEK">SEK</Select.Option>
          <Select.Option value="SGD">SGD</Select.Option>
          <Select.Option value="SZL">SZL</Select.Option>
          <Select.Option value="THB">THB</Select.Option>
          <Select.Option value="TJS">TJS</Select.Option>
          <Select.Option value="TMT">TMT</Select.Option>
          <Select.Option value="TND">TND</Select.Option>
          <Select.Option value="TRY">TRY</Select.Option>
          <Select.Option value="TTD">TTD</Select.Option>
          <Select.Option value="TWD">TWD</Select.Option>
          <Select.Option value="TZS">TZS</Select.Option>
          <Select.Option value="UAH">UAH</Select.Option>
          <Select.Option value="UGX">UGX</Select.Option>
          <Select.Option value="USD">USD</Select.Option>
          <Select.Option value="UYU">UYU</Select.Option>
          <Select.Option value="UZS">UZS</Select.Option>
          <Select.Option value="VEF">VEF</Select.Option>
          <Select.Option value="VND">VND</Select.Option>
          <Select.Option value="XAF">XAF</Select.Option>
          <Select.Option value="XCD">XCD</Select.Option>
          <Select.Option value="XOF">XOF</Select.Option>
          <Select.Option value="ZAR">ZAR</Select.Option>
          <Select.Option value="ZMW">ZMW</Select.Option>
          <Select.Option value="ZWD">ZWD</Select.Option>
        </FormSelect>
        {catalogue === "Sneakers" && (
          <FormSelect
            placeholder="Sizing?"
            value={sizing}
            onChange={(e: any) => setSizing(e)}
            size="middle"
            dropdownStyle={{
              width: "100%",
            }}
          >
            {sizingOptions.map((size) => (
              <Option key={size}>{size}</Option>
            ))}
          </FormSelect>
        )}
      </Container>
    );
  return (
    <Container>
      <FormInput
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <FormInput
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />

      <FormInput
        placeholder="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <FormInput
        placeholder="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      {showPasswordInput && (
        <>
          <FormPass
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            /* prefix={
              <Button
                type="text"
                icon={showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={() => setShowPassword(!showPassword)}
              />
            } */
          />
          <FormPass
            type={showConfirm ? "text" : "password"}
            placeholder="Confirm Password"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
            required
            /* prefix={
              <Button
                type="text"
                icon={showConfirm ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={() => setShowConfirm(!showConfirm)}
              />
            } */
          />
        </>
      )}
    </Container>
  );
};

export default StoreSignupForm;
