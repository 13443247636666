import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router";
import {
  Button,
  Collapse,
  Input,
  Menu,
  Select,
  Row,
  Col,
  Spin,
  Image,
  Pagination,
} from "antd";
import Content from "../../constants/Content";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getOrders,
  exportOrders,
  resyncAllOrders,
  getOrder,
} from "../../redux/actions/orderActions";
import OrderFilter from "../../components/Common/OrderFilter";
import {
  getInventoryFilterOptions,
  getOrdersPayoutInventories,
  getOrdersPayoutInventoriesFromAPI,
  getPaginatedInventories,
} from "../../redux/actions/inventoryActions";
import OrderTable from "../../components/Common/OrderTable";
import { Order } from "../../redux/reducers/OrderReducer";
import { RouteComponentProps, useHistory } from "react-router";
import { Payout } from "../../redux/reducers/PayoutReducer";
import { UserState } from "../../redux/reducers/UserReducer";
import { UploadOutlined, SyncOutlined, EyeOutlined } from "@ant-design/icons";
// import { ReactComponent as CaretDownOutlined } from "../assets/images/svg/CaretCircleUp.svg";
import { ReactComponent as EditOutlined } from "../assets/images/svg/TripleDot.svg";
import RedesignStyling from "../../constants/RedesignStyling";
import Colors from "../../constants/Colors";
import { getConsigners } from "../../redux/actions/consignerActions";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import getSymbolFromCurrency from "currency-symbol-map";
import Link from "antd/lib/typography/Link";
import { getStore } from "../../redux/actions/storeActions";
import {
  Inventory,
  InventoryState,
} from "../../redux/reducers/InventoryReducer";
import {
  CustomB,
  CustomInventoryFilterContainer,
  InventoryPageTypes,
  SrchBx,
} from "../Inventory";
import InventoryFilter from "../../components/Common/InventoryFilter";
import { inventories } from "../../constants/TestData";
import {
  ColumnTypes,
  sizeSorter,
} from "../../consignerScreens/ConsignerInventory";
import moment from "moment";
import { Wrapper } from "../../components/Common/InventoryCardView";
import { getLowestPriceForVariantFromAPI } from "../../redux/actions/inventoryActions";
import {
  CaretDownFilled,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
} from "@ant-design/icons";
import InvoiceModal from "../../screens/Inventory/InvoiceModal";
import ViewOrderModale from "../Inventory/ViewOrderModale";

const { Search } = Input;

/**
 * Order Screen
 * Renders table of orders and search
 * TODO TESTS:
 *  - renders correctly
 *  - on search refetchs orders
 */
const Container = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  #inventoryFilter {
    margin: 0 0 0 15px;
    flex-wrap: nowrap;
    &.ant-form-inline .ant-form-item:last-of-type {
      margin-right: 0;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    height: 40px;
    color: #292d32;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
  }
  table {
    thead {
      .ant-checkbox-inner {
        background-color: transparent; /* Customize the check color */
      }
    }
  }
  @media (max-width: 768px) {
    background-color: #fff;
  }
`;

interface CustomSpanProps {
  order?: number;
}
const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  .ant-btn {
    height: 100%;
    background-color: #e1ecff;
    color: #2e2e2e;
    border-radius: 8px;
    border: none;
  }
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;
export const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto;
  width: 100%;
  padding: 0 16px;
  & > span {
    width: 100%;
    max-width: 100%;
    height: ${RedesignStyling.SEARCH_HEIGHT};
  }
  .ant-input-group.ant-input-wrapper {
    display: flex;
    height: 100%;
    .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH});
    }
  }
  button.ant-input-search-button {
    padding: 8px 16px;
    background-color: ${Colors.ADMIN_BLUE};
    color: ${Colors.WHITE};
    border: 0;
    height: ${RedesignStyling.SEARCH_HEIGHT};
    width: ${RedesignStyling.SEARCH_BUTTON_WIDTH};
    padding: ${RedesignStyling.BUTTON_PADDING};
  }

  @media (max-width: 768px) {
    margin: 0 0 8px;
    order: 3;
    padding: 0;
    .ant-input-group.ant-input-wrapper .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE});
      width: 100%;
    }

    .ant-input-search-with-button .ant-input-group input.ant-input {
      font-size: 16px;
    }

    button.ant-input-search-button {
      width: ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE};
    }
  }
`;
const goToOrderPage = (
  history: RouteComponentProps["history"],
  order: Order | Payout
) => {
  history.push(`/orders/${order.id}`);
};

const CustomSpan = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  b {
    font-size: 20px;
    line-height: 150%;
  }
  button {
    max-height: 40px;
    height: 40px;
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .ant-picker-range {
      background: transparent;
      width: 225px;
      border: 0;
      color: ${Colors.CONSIGNER_GREY};
    }
    .ant-picker-input > input {
      color: #9ca3af;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    #inventoryFilter {
      margin: 0;
      gap: 2px !important;
    }
  }
`;
const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const ProductDetails = styled.div`
  flex: 1;
  padding: 5px 16px;
  gap: 18px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  &.pricing {
    & > div {
      &:not(.Requested) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.center {
    align-items: center;
  }
  &:first-of-type {
    border-left: 1px solid #ddd;
  }
  b {
    align-self: stretch;
    flex: 1;
    a {
      color: inherit;
      white-space: nowrap;
    }
  }
  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    flex-wrap: nowrap;
    span {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      p {
        margin: 0 0 3px;
        color: #292d32;
      }
      label {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid #ddd;
    border-right: none;
    font-size: 10px;
    b {
      font-size: inherit;
    }
    & > div {
      font-size: inherit;
      gap: 16px;
      span {
        justify-content: flex-start;
        font-size: inherit;
        label {
          font-size: inherit;
        }
      }
    }
    &.center {
      align-items: flex-start;
    }
  }
`;
const SkuDivMargin = styled.div`
  margin-bottom: 1.4rem;
`;

const InventoryCardMobileView = ({
  dataSource,
  columnSrc,
  priceMap,
  status,
}: {
  dataSource: Inventory[];
  columnSrc: any[];
  priceMap: any[];
  status: any;
}) => {
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [expandedKey, setExpandedKey] = useState(-1);
};

interface OrderProps {
  nestedView?: boolean;
  groupedData?: any[];
  loading: boolean;
  order: any[];
  search: string;
}

const Buying = (data: OrderProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [fetchOngoing, setFetchOngoing] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [nestedView, setNestedView] = useState(false);
  const [viewOrder, setViewOrder] = useState(false);
  const { paginatedInventories }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );
  // const [groupedData, setGroupedData] = useState<any[]>([])
  const { order, search } = data;
  const [filters, setFilters] = useState<any>({
    status: "",
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: undefined,
    // sortOption: "newestUpdated",
    consigner:
      dbUser && dbUser.id && process.env.REACT_APP_TYPE === "consigner"
        ? dbUser.id
        : undefined,
  });
  const { orders, ordersLoading } = useAppSelector(
    (state) => state.OrderReducer
  );

  const { ordersPayoutsInventories, inventoriesLoading }: InventoryState =
    useAppSelector((state) => state.InventoryReducer);
  const { consigners, consignersLoading } = useAppSelector(
    (state) => state.ConsignerReducer
  );

  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
  const [orderDetails, setOrderDetails] = useState<any>();
  const { inventoryFilterOptions, inventoryFilterOptionsLoading } =
    useAppSelector((state) => state.InventoryReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  useEffect(() => {
    const takeProducts = true;
    const takeConsigners = true;
    dispatch(
      getPaginatedInventories("", filters, 1, 100, takeProducts, takeConsigners)
    );
  }, []);
  const fetchLowestPricesAndQtyOfVariants = async (data: Inventory[]) => {
    setFetchOngoing(true);
    // const prcMap = [...priceMap];
    for (const inventoryToReturn of data) {
      const key = `${inventoryToReturn.option1Value}-${
        inventoryToReturn?.option2Value
      }-${inventoryToReturn?.option3Value}-${inventoryToReturn?.location}-${
        inventoryToReturn?.storeId
      }-${
        inventoryToReturn?.productId ?? inventoryToReturn?.product.id
      }`.replace(/[\s]/g, "");
      // const dataFound = prcMap.find((data) => data.key === key);
      // if (dataFound === undefined || !dataFound) {
      //   const lowestPriceData = await getLowestPriceForVariantFromAPI({
      //     option1Value: inventoryToReturn?.option1Value ?? "",
      //     option2Value: inventoryToReturn?.option2Value ?? undefined,
      //     option3Value: inventoryToReturn?.option3Value ?? undefined,
      //     location: inventoryToReturn?.location ?? "",
      //     productId: Number(inventoryToReturn?.product?.id) ?? 0,
      //   });
      //   prcMap.push({
      //     key,
      //     qty: lowestPriceData.qty,
      //     price: lowestPriceData.price,
      //   });
      // }
    }
    // setPriceMap(prcMap);
    setFetchOngoing(false);
  };
  const handleResyncAllOrders = () => {
    dispatch(resyncAllOrders()).then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    setFilteredOrders(data.order);
  }, [search]);

  // const groupedData = inventories.reduce((groups, item) => {
  //   const groupId =
  //     props.type === InventoryPageTypes.Find
  //       ? `${item.productId}`
  //       : `${item.consigner.id}`;
  //   // const groupId = `${item.productId}${item.sku}`;
  //   if (!groups[groupId]) {
  //     groups[groupId] = {
  //       productId: item.productId,
  //       key: groupId,
  //       productTitle:
  //         props.type === InventoryPageTypes.Find
  //           ? item?.product?.title
  //           : `${item.consigner?.firstName} ${item.consigner?.lastName}`,
  //       productImage: item.product?.image,
  //       productSku: item.product?.sku,
  //       consigner: item?.consigner,
  //       quantity: 0,
  //       inventories: [],
  //     };
  //   }
  //   groups[groupId].quantity++;
  //   groups[groupId].inventories.push(item);
  //   return groups;
  // }, {});

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
    dispatch(getConsigners(""));
  }, []);

  // const fetchOrdersData = async () => {
  //   setLoading(true);
  //   const data = await getOrdersPayoutInventoriesFromAPI(
  //     search,
  //     filters,
  //     currentPage,
  //     pageSize
  //   );
  //   setInventories(data.inventories);
  //   setTotal(data.total);
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   fetchOrdersData();
  // }, [search, filters, currentPage, pageSize]);

  const handleCollapseChange = (key) => {
    setNestedView(true);
    const dataSrc = inventories.find((data) => data.key === key);
    // if (status == InventoryPageTypes.Requested) {
    //   fetchLowestPricesAndQtyOfVariants(dataSrc?.inventories);
    // }
    setActiveKey(key);
  };
  const handleViewClick = async (inventory) => {
    const response = await dispatch(getOrder(inventory.order.id));
    setOrderDetails(response);
    setViewOrder(true);
  };
  const showModale = () => {
    setViewOrder(false);
  };

  return (
    <>
      <ViewOrderModale
        isModaleVisible={viewOrder}
        setIsModaleVisible={showModale}
        orderDetails={orderDetails}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div style={{ flex: "0 0 85%", marginRight: "2%" }}>
          <Row
            style={{
              padding: "15px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              marginTop: "5px",
              width: "100%",
            }}
          >
            <Col
              style={{
                color: "#989898",
                fontSize: "14px",
                fontWeight: "500",
              }}
              span={3}
            >
              Consigner
            </Col>
            <Col
              style={{
                color: "#989898",
                fontSize: "14px",
                fontWeight: "500",
              }}
              span={3}
            >
              Revenue
            </Col>
            <Col
              style={{
                color: "#989898",
                fontSize: "14px",
                fontWeight: "500",
              }}
              span={2}
            >
              Cost
            </Col>
            <Col
              style={{
                color: "#989898",
                fontSize: "14px",
                fontWeight: "500",
              }}
              span={2}
            >
              Profit
            </Col>
            <Col
              style={{
                color: "#989898",
                fontSize: "14px",
                fontWeight: "500",
              }}
              span={3}
            >
              Order number
            </Col>
            <Col
              style={{
                color: "#989898",
                fontSize: "14px",
                fontWeight: "500",
              }}
              span={2}
            >
              Units
            </Col>
            <Col
              style={{
                color: "#989898",
                fontSize: "14px",
                fontWeight: "500",
              }}
              span={2}
            >
              Shipping cost
            </Col>
          </Row>
        </div>
        <div
          style={{ flex: "0 0 13%", display: "flex", justifyContent: "center" }}
        >
          <button
            style={{
              backgroundColor: "#FFFFFF",
              marginTop: "3px",
              padding: "10px",
              cursor: "pointer",
              border: "none",
              width: "100%",
              borderRadius: "10px",
              color: "#989898",
              fontSize: "14px",
              fontWeight: "500",
            }}
            onClick={() => dispatch(exportOrders(filteredOrders))}
          >
            {Content.FIND_ORDERS_ACTION_BUTTON_TEXT}
            <UploadOutlined />
          </button>
        </div>
      </div>

      <Container>
        <Wrapper>
          {paginatedInventories?.rows?.map((inventory, key) => {
            let rangeUsed = false;
            const { storeRequestPriceRange } = inventory;
            if (
              storeRequestPriceRange &&
              storeRequestPriceRange[1] &&
              storeRequestPriceRange[0]
            ) {
              rangeUsed = true;
            }
            return (
              <ProductRow key={key}>
                <ProductDetails>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={inventory?.product?.image}
                        alt=""
                        style={{ height: "61px", width: "61px" }}
                      />
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {inventory.product?.title}
                      </p>
                    </div>

                    <div style={{ flex: "0 0 0px" }}>
                      <label style={{ color: "grey", fontWeight: "500" }}>
                        SKU
                      </label>
                      <SkuDivMargin>
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.9em",
                            marginTop: "4px",
                            lineHeight: "1rem",
                          }}
                        >
                          {inventory.product?.sku?.slice(0, 12)}
                          {inventory.product?.sku.length > 12 ? "..." : null}
                        </p>
                      </SkuDivMargin>
                    </div>
                    <div style={{ flex: "0 0 0px" }}>
                      <label style={{ color: "grey", fontWeight: "500" }}>
                        Size
                      </label>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {inventory.option2Value}
                      </p>
                    </div>
                    <div style={{ flex: "0 0 0px" }}>
                      <label style={{ color: "grey", fontWeight: "500" }}>
                        Quantity
                      </label>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {inventory.option1Value}
                      </p>
                    </div>
                    <div style={{ flex: "0 0 0px" }}>
                      <label style={{ color: "grey", fontWeight: "500" }}>
                        Order ID
                      </label>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {inventory.option1Value}
                      </p>
                    </div>
                    <div style={{ flex: "0 0 0px" }}>
                      <label style={{ color: "grey", fontWeight: "500" }}>
                        Seller name
                      </label>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {inventory.option1Value}
                      </p>
                    </div>
                    <div style={{ flex: "0 0 0px" }}>
                      <label style={{ color: "grey", fontWeight: "500" }}>
                        Price
                      </label>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {getSymbolFromCurrency(store.currency)}
                        {inventory.cost
                          ? Number(inventory.cost).toFixed(1)
                          : "0"}
                      </p>
                    </div>
                    <div style={{ flex: "0 0 0px" }}>
                      <label style={{ color: "grey", fontWeight: "500" }}>
                        Cost
                      </label>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {getSymbolFromCurrency(store.currency)}
                        {inventory.cost
                          ? Number(inventory.cost).toFixed(1)
                          : "0"}
                      </p>
                    </div>
                    <div style={{ flex: "0 0 0px" }}>
                      <label style={{ color: "grey", fontWeight: "500" }}>
                        Date Sold
                      </label>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9em",
                          marginTop: "4px",
                          lineHeight: "1rem",
                        }}
                      >
                        {inventory.option1Value}
                      </p>
                    </div>
                    <div
                      style={{
                        flex: "0 0 auto",
                        marginLeft: "auto",
                        alignSelf: "center",
                      }}
                    ></div>
                  </div>
                </ProductDetails>
              </ProductRow>
            );
          })}
        </Wrapper>
      </Container>
    </>
  );
};

export default Buying;
