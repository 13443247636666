import React from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import format from "date-fns/format";
import { Timeline, Card, Tag } from "antd";
import { useAppSelector } from "../../redux/hooks";
import { InventoryState } from "../../redux/reducers/InventoryReducer";

//styles
const Container = styled.div`
  display: flex;
  background: ${Colors.WHITE};
  padding: 10px;
`;

const CardContainer = styled(Card)`
  margin-top: 10px;
`;

interface Props {
  inventoryLogs: any;
}

const InventoryTimeline = (props: Props) => {
  const { inventoryLogs } = props;
  const { inventoryFilterOptions }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );

  return (
    <CardContainer title="Timeline">
      <Container>
        <Timeline>
          {inventoryLogs.map((logItem: any, lid: number) => {
            return (
              <Timeline.Item key={logItem?.id ?? lid}>
                {logItem.modifiedFields.length == 1 &&
                logItem.modifiedFields[0].variableChanged === "createdOn" ? (
                  logItem.lastUpdatedBy.name + " added this item "
                ) : (
                  <>
                    {logItem.lastUpdatedBy.name + " changed"}
                    {logItem.modifiedFields.map((change: any, cid: number) => {
                      if (change.variableChanged === "consignerId") {
                        return (
                          <div key={change?.id ?? cid}>
                            {change.beforeValue || change.afterValue ? (
                              <>
                                {" "}
                                <Tag color="blue">Owner</Tag>
                                {change.beforeValue ? (
                                  <>
                                    from{" "}
                                    <Tag color="orange">
                                      {
                                        inventoryFilterOptions.consigners.find(
                                          (consigner) =>
                                            consigner.value.id ===
                                            change.beforeValue
                                        )?.label
                                      }
                                    </Tag>
                                  </>
                                ) : (
                                  <></>
                                )}
                                to{" "}
                                <Tag color="green">
                                  {
                                    inventoryFilterOptions.consigners.find(
                                      (consigner) =>
                                        consigner.value.id === change.afterValue
                                    )?.label
                                  }
                                </Tag>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }
                      return (
                        <div key={change?.id ?? cid}>
                          {change.beforeValue || change.afterValue ? (
                            <>
                              {" "}
                              <Tag color="blue">{change.variableChanged}</Tag>
                              {change.beforeValue ? (
                                <>
                                  from{" "}
                                  <Tag color="orange">{change.beforeValue}</Tag>
                                </>
                              ) : (
                                <></>
                              )}
                              to <Tag color="green">{change.afterValue}</Tag>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
                on {format(new Date(logItem.updatedAt), "PPp")}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Container>
    </CardContainer>
  );
};
export default InventoryTimeline;
