import React, { useState } from "react";
import { Button, Checkbox, Col, List, Row, Spin } from "antd";
import styled from "styled-components";
import { Inventory } from "../../redux/reducers/InventoryReducer";
import { Link } from "react-router-dom";
import Fonts from "../../constants/Fonts";
import Colors from "../../constants/Colors";
import { useAppSelector } from "../../redux/hooks";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import { OptimizedImageUrl } from "../../helperFunctions/optimizedImageUrl";
import { Order } from "../../redux/reducers/OrderReducer";
import { ConsignerBankDetailsModal } from "../../screens/ConsignerView";

/**
 * Inventory List
 * TODO Tests:
 *  - render correctyl
 *  - render optionals
 *   - price change
 *   - order number
 *   - danger btn
 * - on accept / on decline clicks
 * - selected, onSelect, selectable
 */

//styles
const Container = styled(List)``;
const ListItem = styled(List.Item)`
  margin-bottom: 10px;
  padding: 0px;
`;
const Card = styled.div`
  background: white;
  width: 100%;
  padding: 40px;
  @media (max-width: 500px) {
    justify-content: center;
    padding: 20px;
  }
  cursor: pointer;
  border: ${(props: InventoryItemProps) =>
    props.isSelected ? `1px solid ${Colors.BLUE_MAGENTA}` : "none"};
`;
const InnerCard = styled.div`
  background: white;
  width: 100%;
  padding: 0px;
  margin-top: 8px;
  @media (max-width: 500px) {
    justify-content: center;
    padding: 2px;
    width: 100%;
  }
  width: 70%;
`;

const Image = styled.img`
  width: 150px;
  height: 100%;
  @media (max-width: 500px) {
    width: 50%;
    margin-left: 5%;
    margin-top: 5%;
  }
  object-fit: cover;
`;
const ImageTextContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;
const TextContainer = styled.div`
  margin-left: 10px;
  @media (max-width: 500px) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;
const ActionContainer = styled.div`
  @media (max-width: 500px) {
    display: flex;
    flex-start: center;
    margin-top: 30px;
  }
  @media (min-width: 500px) {
    display: flex;
    justify-content: right;
  }
`;
const Title = styled.p`
  margin-bottom: 0px;
  font-family: ${Fonts.PRIMARY};
  font-weight: 600;
  @media (max-width: 500px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const Subtext = styled.p`
  margin-bottom: 0px;
  font-family: ${Fonts.PRIMARY};
  font-size: 12px;
`;

const AcceptButton = styled(Button)`
  justify-content: flex-end;
`;

const DeclineButton = styled(Button)`
  justify-content: flex-end;
`;

interface InventoryItemProps {
  isSelected: boolean;
}

interface Props {
  inventories: Inventory[];
  loading: boolean;
  onDecline?: (inventory: Inventory) => void;
  declineButtonText?: string;
  acceptButtonText: string;
  onAccept: (inventory: Inventory) => void;
  selected?: Inventory[];
  onSelect?: (newSelectedInventories: any) => void;
  acceptLoading?: boolean;
  declineLoading?: boolean;
}

interface OrderWithCreatedAt extends Order {
  orderCreatedAt: string;
}

interface CardProps {
  inventory: Inventory;
  onDecline?: (inventory: Inventory) => void;
  declineButtonText?: string;
  acceptButtonText: string;
  onAccept: (inventory: Inventory) => void;
  selected?: Inventory[];
  onSelect?: (newSelectedInventories: any) => void;
  acceptLoading?: boolean;
  declineLoading?: boolean;
}

const isItemSelected = (
  selected: Inventory[] | undefined,
  currentInventoryId: string
) => {
  if (selected && Array.isArray(selected)) {
    // add a check to make sure selected is an array
    return selected.some((item) => item.id === currentInventoryId);
  } else {
    return false;
  }
};

const getNewSelectedInventories = (
  isSelected: boolean,
  selectedInventories: Inventory[],
  inventory: Inventory
): Inventory[] => {
  if (isSelected) {
    ///remove
    return Array.isArray(selectedInventories)
      ? selectedInventories.filter((item) => item.id !== inventory.id) // remove the item from the array
      : []; // if selectedInventories is not an array, return an empty array
  } else {
    return Array.isArray(selectedInventories)
      ? [...selectedInventories, inventory] // add the item to the array
      : [inventory]; // if selectedInventories is not an array, return an array with the item
  }
};

//Component for hyperlink props
const conditionalProps = (
  inventory: Inventory
): { href: string; target: string } => {
  const page = {
    href: "/inventories/" + inventory.id,
    target: "",
  };
  return page;
};

const InventoryCard = (props: CardProps): JSX.Element => {
  const {
    inventory,
    acceptButtonText,
    declineButtonText,
    onAccept,
    onDecline,
    selected,
    onSelect,
    acceptLoading,
    declineLoading,
  } = props;

  const {
    product,
    option1Value,
    option2Value,
    option3Value,
    location,
    subLocation,
    consigner,
    price,
    payoutAmount,
    requestedPrice,
    order,
    orderId,
    id,
    status,
    code,
    createdAt,
    updatedAt,
    discount,
    buyingId,
  } = inventory;

  const { image, title, sku } = product;
  const isSelected = isItemSelected(selected, id);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [showConsignerDetailsModal, setShowConsignerDetailsModal] =
    useState(false);

  /*
    1000 = 1 sec
    60 = 1 min
    60 = 1 hour
    24 = 1 day
  */
  const getAge = (inputDate: any) => {
    const today = new Date().getTime();
    let orgDate = new Date(moment(inputDate).format("L")).getTime();
    let agePerDay = Math.floor((today - orgDate) / (1000 * 60 * 60 * 24));
    return agePerDay;
  };

  const handleShowConsignerDetails = () => {
    setShowConsignerDetailsModal(true);
  };

  return (
    <ListItem>
      <ConsignerBankDetailsModal
        consigner={consigner}
        setShowDetailsModal={setShowConsignerDetailsModal}
        showDetailsModal={showConsignerDetailsModal}
      />
      <Card isSelected={isSelected}>
        <Row align="middle" wrap={false}>
          <Col span={1}>
            <Checkbox
              checked={isSelected}
              onChange={() => {
                const newSelected = getNewSelectedInventories(
                  isSelected,
                  selected || [],
                  inventory
                );
                onSelect && onSelect(newSelected);
              }}
            ></Checkbox>
          </Col>
          <Col flex="auto">
            <ImageTextContainer>
              <Image src={OptimizedImageUrl(image)} />

              <TextContainer>
                <Title>
                  {title} {sku} {code}
                </Title>
                <Subtext>
                  {option1Value} {option2Value} {option3Value}
                </Subtext>
                {location ? <Subtext>Location: {location}</Subtext> : ""}
                {subLocation ? (
                  <Subtext>Sublocation: {subLocation}</Subtext>
                ) : (
                  ""
                )}
                <Subtext>
                  Status: <b>{status}</b>
                </Subtext>
                {status === "Sold" ? (
                  <Subtext>
                    Date sold:{" "}
                    {moment(inventory?.soldOn ?? order?.createdAt).format(
                      "lll"
                    )}
                  </Subtext>
                ) : (
                  ""
                )}
                {Number(buyingId) && status === "Pending" ? (
                  <Subtext>
                    <b>This item is for Buying</b>
                  </Subtext>
                ) : (
                  ""
                )}
                {consigner && (
                  <Subtext>
                    Seller: {""}
                    <Link target="_blank" to={`/consigners/${consigner.id}`}>
                      <b>{consigner.firstName}</b>
                    </Link>
                    &nbsp; (
                    <a onClick={handleShowConsignerDetails}>Bank Details</a>)
                  </Subtext>
                )}
                {order && (
                  <Subtext>
                    Order:
                    <Link target="_blank" to={`/orders/${order.id}`}>
                      {order.name}
                    </Link>
                  </Subtext>
                )}

                <InnerCard>
                  {requestedPrice &&
                    [
                      "StoreRequestPriceChange",
                      "Requested",
                      "Pending",
                    ].includes(status) && (
                      <Subtext>
                        Price change request:{" "}
                        <b>
                          {getSymbolFromCurrency(store!.currency)}
                          {price} to {getSymbolFromCurrency(store!.currency)}
                          {requestedPrice}
                        </b>
                      </Subtext>
                    )}

                  {price && (
                    <Subtext>
                      Sale Price:{" "}
                      <b>
                        {getSymbolFromCurrency(store!.currency)}
                        {price}

                        {discount
                          ? ` - sold with discount for ${getSymbolFromCurrency(
                              store!.currency
                            )}${Number(price) - Number(discount)}`
                          : ""}
                      </b>{" "}
                      <br></br>
                      Payout Amount:{" "}
                      <b>
                        {getSymbolFromCurrency(store!.currency)}
                        {payoutAmount}
                      </b>
                    </Subtext>
                  )}
                  {status === "Active" ? (
                    <Subtext>
                      Age: <b>{getAge(createdAt)} days old</b>
                    </Subtext>
                  ) : (
                    ""
                  )}
                </InnerCard>
              </TextContainer>
              <Col flex="auto">
                <ActionContainer>
                  <AcceptButton
                    loading={acceptLoading}
                    type="primary"
                    {...(acceptButtonText === "EDIT" &&
                      conditionalProps(inventory))}
                    onClick={() =>
                      acceptButtonText !== "EDIT" && onAccept(inventory)
                    }
                  >
                    {acceptButtonText}
                  </AcceptButton>
                  {onDecline && (
                    <DeclineButton
                      loading={declineLoading}
                      danger
                      onClick={() => onDecline(inventory)}
                    >
                      {declineButtonText}
                    </DeclineButton>
                  )}
                </ActionContainer>
              </Col>
            </ImageTextContainer>
          </Col>
        </Row>
      </Card>
    </ListItem>
  );
};

const InventoryList = (props: Props): JSX.Element => {
  const {
    inventories,
    loading,
    acceptButtonText,
    declineButtonText,
    onAccept,
    onDecline,
    acceptLoading,
    declineLoading,
    onSelect,
    selected,
  } = props;
  if (loading) return <Spin />;
  return (
    <Container
      dataSource={inventories.sort(
        (a, b) =>
          new Date(b.order ? b.order.orderDate : b.soldOn).getTime() -
          new Date(a.order ? a.order.orderDate : a.soldOn).getTime()
      )}
      loading={loading}
      renderItem={(item, index) => (
        <InventoryCard
          key={index}
          onAccept={onAccept}
          onDecline={onDecline}
          declineButtonText={declineButtonText}
          acceptButtonText={acceptButtonText}
          inventory={inventories[index]}
          onSelect={onSelect}
          selected={selected}
          acceptLoading={acceptLoading}
          declineLoading={declineLoading}
        />
      )}
    />
  );
};

export default InventoryList;
