import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { AppDispatch, store } from "../redux/store";
import { Store, StoreState } from "../redux/reducers/StoreReducer";
import { UserState } from "../redux/reducers/UserReducer";
import { updateStore } from "../redux/actions/storeActions";
import { updateEmployee } from "../redux/actions/employeeActions";
import styled from "styled-components";
import HorizontalPageCard from "../components/Common/HorizontalPageCard";
import SetupSettings from "../components/Common/SetupSettings";
import AuthService from "../services/auth";
import RedesignStyling from "../constants/RedesignStyling";
import { Button, Form, Input, Select } from "antd";
import { CustomModal } from "./Inventory/inventoryStyle";
import { StringDecoder } from "string_decoder";
import { useHistory } from "react-router-dom";
const { Option } = Select;

// Styles
const Container = styled.div`
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Section = styled.div`
  display: flex;
  margin: 5px;
  flex: 1;
  align-items: start;
`;

const SectionTitle = styled.div`
  flex: 1;
  margin: 5px;
  font-size: 16px;
`;

const SectionContent = styled.div`
  flex: 2;
  margin: 5px;
  padding: 10px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #dddddd;
`;

const FooterButton = styled(Button)`
  padding: 8px 40px;
  height: 52px;
  border-radius: 8px;
`;

const SavePasswordButton = styled.button`
  text-decoration: underline;
  color: #685ff9;
  font-weight: 500;
  background-color: transparent;
  border: none;
`;

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: {
    code: string;
    number: string;
  };
}

interface StoreData {
  id: any;
  sizing: string;
  currency: string;
  catalogue: string;
  payoutOptions: string;
}

interface SettingModalProps {
  isModalVisible: boolean;
  setSettingModal: () => void;
  storeData: StoreData;
  userInfo: UserInfo;
}

const SettingModal = ({
  isModalVisible,
  setSettingModal,
  storeData,
  userInfo,
}: SettingModalProps) => {
  const typeOptions = ["Sneakers", "Luxury", "Watches", "None"];
  const sizingOptions = ["US", "EU"];
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  const handleModal = () => {
    setSettingModal();
    history.push("/");
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const id = storeData.id;
        const phone = values.phone.code + values.phone.number;
        const payOut =
          typeof values.payoutOptions === "string"
            ? values.payoutOptions.split(",").map((option) => option.trim())
            : values.payoutOptions;
        const updatedValues = {
          ...values,
          phone: phone,
          safeInventoryStatusMode: true,
          autoPreapproval: true,
          chargeTax: true,
          payoutOptions: payOut,
        };
        console.log("Form values:", id, updatedValues);
        dispatch(updateEmployee(id, updatedValues));
        dispatch(updateStore(id, updatedValues));
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  useEffect(() => {
    console.log("Updated Data", storeData);
  }, []);

  return (
    <CustomModal
      title="Seller Info"
      width={812}
      visible={isModalVisible}
      onCancel={handleModal}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            key="back"
            type="primary"
            style={{
              padding: "8px 40px",
              height: 52,
              borderRadius: "8px",
              border: "1px solid #DDDDDD",
              color: "black",
              background: "#F4F4F4",
            }}
            onClick={handleModal}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            style={{
              padding: "8px 40px",
              height: 52,
              borderRadius: "8px",
              background: "#685FF9",
            }}
            onClick={handleSubmit}
          >
            Next
          </Button>
        </div>
      }
    >
      <FormWrapper>
        <Section>
          <SectionTitle>Personal Information</SectionTitle>
          <SectionContent>
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                firstName: userInfo?.firstName,
                lastName: userInfo?.lastName,
                email: userInfo?.email,
                phone: {
                  code: userInfo?.phone?.code,
                  number: userInfo?.phone?.number,
                },
                sizing: storeData?.sizing,
                currency: storeData?.currency,
                catalogue: storeData?.catalogue,
                payoutOptions: storeData?.payoutOptions,
              }}
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <Form.Item
                  name="firstName"
                  label={<span style={{ color: "#989898" }}>First Name</span>}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    marginRight: "16px",
                  }}
                  rules={[{ message: "Please enter your first name" }]}
                >
                  <Input
                    placeholder="First Name"
                    style={{ height: "40px", borderRadius: "8px" }}
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label={<span style={{ color: "#989898" }}>Last Name</span>}
                  style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                  rules={[{ message: "Please enter your last name" }]}
                >
                  <Input
                    placeholder="Last Name"
                    style={{ height: "40px", borderRadius: "8px" }}
                  />
                </Form.Item>
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="email"
                  label={<span style={{ color: "#989898" }}>Email</span>}
                  style={{ flex: 1, marginRight: "16px" }}
                  rules={[
                    {
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    disabled
                    style={{ height: "40px", borderRadius: "8px" }}
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label={<span style={{ color: "#989898" }}>Phone Number</span>}
                  style={{ flex: 1 }}
                >
                  <Input.Group
                    compact
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Item
                      name={["phone", "code"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please enter country code",
                        },
                      ]}
                    >
                      <Select
                        placeholder="+1"
                        style={{ width: "20%", borderRadius: "8px" }}
                      >
                        <Option value="+1">+1</Option>
                        <Option value="+91">+91</Option>
                        <Option value="+44">+44</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={["phone", "number"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "75%",
                          height: "40px",
                          borderRadius: "8px",
                          marginLeft: "5%",
                        }}
                        placeholder="Enter your phone number"
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </div>
            </Form>
          </SectionContent>
        </Section>
        <Divider />
        <Section>
          <SectionTitle>Password</SectionTitle>
          <SectionContent>
            <Form form={form} layout="vertical">
              <Form.Item style={{ marginBottom: 0 }}>
                <Form.Item
                  name="oldPassword"
                  label="Old Password"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    marginRight: "16px",
                  }}
                  rules={[{ message: "Please enter your old password" }]}
                >
                  <Input.Password
                    placeholder="Old Password"
                    style={{ height: "40px", borderRadius: "8px" }}
                  />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  label="New Password"
                  style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                  rules={[{ message: "Please enter your new password" }]}
                >
                  <Input.Password
                    placeholder="New Password"
                    style={{ height: "40px", borderRadius: "8px" }}
                  />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <SavePasswordButton>Save password</SavePasswordButton>
                </div>
              </Form.Item>
            </Form>
          </SectionContent>
        </Section>
        <Divider />
        <Section>
          <SectionTitle>Store Settings</SectionTitle>
          <SectionContent>
            <Form form={form} layout="vertical">
              <Form.Item style={{ marginBottom: 0 }}>
                <Form.Item
                  name="sizing"
                  label={<span style={{ color: "#989898" }}>Sizing</span>}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    marginRight: "16px",
                  }}
                >
                  <Select
                    style={{ height: "40px", borderRadius: "8px" }}
                    dropdownStyle={{ height: 40 }}
                  >
                    {sizingOptions.map((size) => (
                      <Select.Option key={size}>{size}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="currency"
                  label={<span style={{ color: "#989898" }}>Currency</span>}
                  style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                >
                  <Select
                    placeholder="Select currency"
                    style={{ height: "40px", borderRadius: "8px" }}
                  >
                    <Select.Option value="AED">AED</Select.Option>
                    <Select.Option value="ALL">ALL</Select.Option>
                    <Select.Option value="AMD">AMD</Select.Option>
                    <Select.Option value="AOA">AOA</Select.Option>
                    <Select.Option value="ARS">ARS</Select.Option>
                    <Select.Option value="AUD">AUD</Select.Option>
                    <Select.Option value="AZN">AZN</Select.Option>
                    <Select.Option value="BAM">BAM</Select.Option>
                    <Select.Option value="BBD">BBD</Select.Option>
                    <Select.Option value="BGN">BGN</Select.Option>
                    <Select.Option value="BHD">BHD</Select.Option>
                    <Select.Option value="BMD">BMD</Select.Option>
                    <Select.Option value="BOB">BOB</Select.Option>
                    <Select.Option value="BRL">BRL</Select.Option>
                    <Select.Option value="BWP">BWP</Select.Option>
                    <Select.Option value="BYN">BYN</Select.Option>
                    <Select.Option value="CAD">CAD</Select.Option>
                    <Select.Option value="CDF">CDF</Select.Option>
                    <Select.Option value="CHF">CHF</Select.Option>
                    <Select.Option value="CLP">CLP</Select.Option>
                    <Select.Option value="CNY">CNY</Select.Option>
                    <Select.Option value="COP">COP</Select.Option>
                    <Select.Option value="CRC">CRC</Select.Option>
                    <Select.Option value="CVE">CVE</Select.Option>
                    <Select.Option value="CZK">CZK</Select.Option>
                    <Select.Option value="DKK">DKK</Select.Option>
                    <Select.Option value="DOP">DOP</Select.Option>
                    <Select.Option value="DZD">DZD</Select.Option>
                    <Select.Option value="EGP">EGP</Select.Option>
                    <Select.Option value="EUR">EUR</Select.Option>
                    <Select.Option value="FJD">FJD</Select.Option>
                    <Select.Option value="GBP">GBP</Select.Option>
                    <Select.Option value="GEL">GEL</Select.Option>
                    <Select.Option value="GHS">GHS</Select.Option>
                    <Select.Option value="GIP">GIP</Select.Option>
                    <Select.Option value="GTQ">GTQ</Select.Option>
                    <Select.Option value="GYD">GYD</Select.Option>
                    <Select.Option value="HKD">HKD</Select.Option>
                    <Select.Option value="HNL">HNL</Select.Option>
                    <Select.Option value="HRK">HRK</Select.Option>
                    <Select.Option value="HUF">HUF</Select.Option>
                    <Select.Option value="IDR">IDR</Select.Option>
                    <Select.Option value="ILS">ILS</Select.Option>
                    <Select.Option value="IMP">IMP</Select.Option>
                    <Select.Option value="INR">INR</Select.Option>
                    <Select.Option value="IQD">IQD</Select.Option>
                    <Select.Option value="ISK">ISK</Select.Option>
                    <Select.Option value="JMD">JMD</Select.Option>
                    <Select.Option value="JOD">JOD</Select.Option>
                    <Select.Option value="JPY">JPY</Select.Option>
                    <Select.Option value="KES">KES</Select.Option>
                    <Select.Option value="KGS">KGS</Select.Option>
                    <Select.Option value="KHR">KHR</Select.Option>
                    <Select.Option value="KRW">KRW</Select.Option>
                    <Select.Option value="KWD">KWD</Select.Option>
                    <Select.Option value="KYD">KYD</Select.Option>
                    <Select.Option value="KZT">KZT</Select.Option>
                    <Select.Option value="LAK">LAK</Select.Option>
                    <Select.Option value="LBP">LBP</Select.Option>
                    <Select.Option value="LKR">LKR</Select.Option>
                    <Select.Option value="LYD">LYD</Select.Option>
                    <Select.Option value="MAD">MAD</Select.Option>
                    <Select.Option value="MDL">MDL</Select.Option>
                    <Select.Option value="MGA">MGA</Select.Option>
                    <Select.Option value="MKD">MKD</Select.Option>
                    <Select.Option value="MMK">MMK</Select.Option>
                    <Select.Option value="MNT">MNT</Select.Option>
                    <Select.Option value="MOP">MOP</Select.Option>
                    <Select.Option value="MRU">MRU</Select.Option>
                    <Select.Option value="MUR">MUR</Select.Option>
                    <Select.Option value="MVR">MVR</Select.Option>
                    <Select.Option value="MWK">MWK</Select.Option>
                    <Select.Option value="MXN">MXN</Select.Option>
                    <Select.Option value="MYR">MYR</Select.Option>
                    <Select.Option value="MZN">MZN</Select.Option>
                    <Select.Option value="NAD">NAD</Select.Option>
                    <Select.Option value="NGN">NGN</Select.Option>
                    <Select.Option value="NIO">NIO</Select.Option>
                    <Select.Option value="NOK">NOK</Select.Option>
                    <Select.Option value="NZD">NZD</Select.Option>
                    <Select.Option value="OMR">OMR</Select.Option>
                    <Select.Option value="PAB">PAB</Select.Option>
                    <Select.Option value="PEN">PEN</Select.Option>
                    <Select.Option value="PGK">PGK</Select.Option>
                    <Select.Option value="PHP">PHP</Select.Option>
                    <Select.Option value="PKR">PKR</Select.Option>
                    <Select.Option value="PLN">PLN</Select.Option>
                    <Select.Option value="PYG">PYG</Select.Option>
                    <Select.Option value="QAR">QAR</Select.Option>
                    <Select.Option value="RON">RON</Select.Option>
                    <Select.Option value="RSD">RSD</Select.Option>
                    <Select.Option value="RUB">RUB</Select.Option>
                    <Select.Option value="RWF">RWF</Select.Option>
                    <Select.Option value="SAR">SAR</Select.Option>
                    <Select.Option value="SEK">SEK</Select.Option>
                    <Select.Option value="SGD">SGD</Select.Option>
                    <Select.Option value="SZL">SZL</Select.Option>
                    <Select.Option value="THB">THB</Select.Option>
                    <Select.Option value="TJS">TJS</Select.Option>
                    <Select.Option value="TMT">TMT</Select.Option>
                    <Select.Option value="TND">TND</Select.Option>
                    <Select.Option value="TRY">TRY</Select.Option>
                    <Select.Option value="TTD">TTD</Select.Option>
                    <Select.Option value="TWD">TWD</Select.Option>
                    <Select.Option value="TZS">TZS</Select.Option>
                    <Select.Option value="UAH">UAH</Select.Option>
                    <Select.Option value="UGX">UGX</Select.Option>
                    <Select.Option value="USD">USD</Select.Option>
                    <Select.Option value="UYU">UYU</Select.Option>
                    <Select.Option value="UZS">UZS</Select.Option>
                    <Select.Option value="VEF">VEF</Select.Option>
                    <Select.Option value="VND">VND</Select.Option>
                    <Select.Option value="XAF">XAF</Select.Option>
                    <Select.Option value="XCD">XCD</Select.Option>
                    <Select.Option value="XOF">XOF</Select.Option>
                    <Select.Option value="ZAR">ZAR</Select.Option>
                    <Select.Option value="ZMW">ZMW</Select.Option>
                    <Select.Option value="ZWD">ZWD</Select.Option>
                  </Select>
                </Form.Item>
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="catalogue"
                  label={
                    <span style={{ color: "#989898" }}>Type of Catalogue</span>
                  }
                  style={{ flex: 1, marginRight: "16px" }}
                >
                  <Select
                    placeholder="Select type of catalogue"
                    style={{ height: "40px", borderRadius: "8px" }}
                  >
                    {typeOptions.map((type) => (
                      <Select.Option key={type}>{type}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="payoutOptions"
                  label={
                    <span style={{ color: "#989898" }}>Payment Option</span>
                  }
                  style={{ flex: 1 }}
                >
                  <Select
                    placeholder="Select payment option"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      height: "40px",
                    }}
                  >
                    <Select.Option value="creditCard">
                      Credit Card
                    </Select.Option>
                    <Select.Option value="paypal">PayPal</Select.Option>
                    <Select.Option value="bankTransfer">
                      Bank Transfer
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </SectionContent>
        </Section>
      </FormWrapper>
    </CustomModal>
  );
};

const Settings = () => {
  const auth = AuthService();
  const dispatch = useAppDispatch();
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [settingModal, setSettingModal] = useState<boolean>(true);

  const handleCancel = () => {
    setSettingModal(false);
  };

  let renderSettings;

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Settings")
  ) {
    renderSettings = "";
  } else {
    renderSettings = (
      <>
        <SettingModal
          storeData={store}
          userInfo={dbUser}
          isModalVisible={settingModal}
          setSettingModal={handleCancel}
        />
        {/* <SetupSettings />; */}
      </>
    );
  }

  return (
    <>
      {renderSettings}
      {/* <div>
            <Container>
              <HorizontalPageCard
                title="Settings"
                subtitle="Edit admin settings here"
                primaryActionText="Logout"
                primaryAction={() => {
                  Cookies.remove("domain");
                  Cookies.remove("supportEmail");
                  auth.logout();
                }}
              />
            </Container>
            {renderSettings}
          </div> */}
    </>
  );
};

export default Settings;
