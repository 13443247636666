import React, { useEffect } from "react";
import styled from "styled-components";
import { Table, Switch, Button, Modal, message } from "antd";
import { Consigner } from "../../redux/reducers/ConsignerReducer";
import { Employee } from "../../redux/reducers/EmployeeReducer";
import { Link } from "react-router-dom";
import {
  deleteConsigner,
  getConsigners,
  updateConsigner,
} from "../../redux/actions/consignerActions";
import { AppDispatch } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  deleteEmployee,
  updateEmployee,
} from "../../redux/actions/employeeActions";
import { getConsignerTypes } from "../../redux/actions/consignerTypeActions";
import Colors from "../../constants/Colors";
/**
 * PeopleTable
 * Renders a table of people such as consigners and employees
 * TODO Tests:
 * - render correctly (columns, link to person page)
 * - link to people
 */

//styles
const Container = styled.div`
  width: 100%;
`;

interface Props {
  consigners?: Consigner[];
  employees?: Employee[]; // Employee Reducer
  loading: boolean;
  link: string;
}
interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  hidden: boolean;
  render: (key: string, data: Consigner | Employee) => JSX.Element;
}

export interface ConsignerFormValues {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  phone: string;
  idNumber: string;
  accountName: string;
  bank: string | undefined;
  accountNumber: string;
  bankType: string | undefined;
  branchCode: string;
  bankData: {
    accountName: string;
    accountNumber: string;
    swiftCode: string;
    ibanNumber: string;
    routingNumber: string;
    bank: string;
    bankType: string;
    email: string;
    routingType: string;
  };
  accountType: string;
  payoutData: string;
  payoutType: string;
  storeId: string;
  acceptedTOS?: boolean;
  isActive?: boolean;
}
export interface EmployeeActive {
  accessControls?: string[];
  isActive?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

const onUpdate = async (
  dispatch: AppDispatch,
  consigner: ConsignerFormValues,
  id: string
) => {
  dispatch(updateConsigner(id, consigner, false));
};
const onDeleteConsigner = async (dispatch: AppDispatch, id: string) => {
  Modal.confirm({
    title: "Are you sure you want to delete this consigner?",
    content: "This action cannot be undone",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk: async () => {
      await dispatch(deleteConsigner(id)),
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      message.success("Consigner deleted!");
    },
  });
};
const onDeleteEmployee = async (
  dispatch: AppDispatch,
  id: string,
  employee: Employee
) => {
  Modal.confirm({
    title: "Are you sure you want to delete this employee?",
    content: "This action cannot be undone",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk: async () => {
      await dispatch(deleteEmployee(id, employee));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      message.success("Employee deleted!");
    },
  });
};

const onUpdateEmployee = async (
  dispatch: AppDispatch,
  employee: EmployeeActive,
  id: string
) => {
  dispatch(updateEmployee(id, employee));
};

const PeopleTable = (props: Props) => {
  const dispatch = useAppDispatch();

  const { consigners, employees, loading, link } = props;

  const [switchToggle, setSwitchToggle] = React.useState(false);

  const { consignerTypes, consignerType, consignerTypesLoading } =
    useAppSelector((state) => state.ConsignerTypeReducer);
  const handleSwitchChange = async (checked: any, value: any) => {
    setSwitchToggle(checked);

    const updateUser = {
      ...value,
      isActive: !checked,
    };

    if (consigners) {
      onUpdate(dispatch, updateUser, updateUser.id);
    }
    if (employees) {
      onUpdateEmployee(dispatch, updateUser, updateUser.id);
    }
  };

  useEffect(() => {
    //get consignerTypes on load
    dispatch(getConsignerTypes());
  }, []);

  const getColumns = (peopleLink: string): Columns[] =>
    [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        hidden: false,
        render: (text: string, data: Consigner | Employee) => (
          // <Link target="_blank" to={`${peopleLink}/${data.id}`}>
          <span data-testid="peopleTableName">
            {data.firstName} {data.lastName}
          </span>
          // </Link>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        hidden: false,
        render: (text: string) => (
          <span data-testid="peopleTableEmail">{text}</span>
        ),
      },
      {
        title: "Disabled",
        dataIndex: "isActive",
        key: "isActive",
        hidden: false,
        sorter: (a: any, b: any) => a.isActive - b.isActive,
        render: (text: string, value: any) =>
          value.isStoreAccount ? (
            <div></div>
          ) : (
            <Switch
              onChange={(checked) => handleSwitchChange(checked, value)}
              defaultChecked={!value.isActive}
            />
          ),
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        hidden: false,
        render: (text: string) => (
          <span data-testid="peopleTablePhone">{text}</span>
        ),
      },
      {
        title: "Account Type",
        dataIndex: "accountType",
        key: "accountType",
        hidden: link === "/consigners" ? false : true,
        render: (text: string, data: Consigner | Employee) => (
          <span data-testid="test">{text}</span>
        ),
      },
      {
        title: "Fee %",
        dataIndex: "accountType",
        key: "accountType",
        hidden: link === "/consigners" ? false : true,
        render: (text: string, data: Consigner | Employee) => (
          <span data-testid="test">
            {consignerTypes.map((item: any) => {
              if (item.accountType === text) {
                return item.sellingFee + "%";
              }
            })}
          </span>
        ),
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        hidden: false,
        align: "center",
        render: (text: string, data: Consigner | Employee) => (
          <Button
            type="primary"
            style={{ backgroundColor: "#ff4d4f", borderColor: "#ff4d4f" }}
            onClick={() => {
              if (link === "/consigners") {
                onDeleteConsigner(dispatch, data.id);
              } else {
                onDeleteEmployee(dispatch, data.id, data as Employee);
              }
            }}
          >
            Delete
          </Button>
        ),
      },
    ].filter((item) => !item.hidden);

  if (consigners) {
    return (
      <Container data-testid="PeopleTableContainer" style={{ marginTop: 10 }}>
        <Table
          data-testid="peopleTable"
          columns={getColumns(link)}
          dataSource={consigners}
          rowKey="id"
          loading={loading}
        />
      </Container>
    );
  }
  return (
    <Container data-testid="PeopleTableContainer" style={{ marginTop: 10 }}>
      <Table
        data-testid="peopleTable"
        columns={getColumns(link)}
        dataSource={employees}
        rowKey="id"
        loading={loading}
      />
    </Container>
  );
};

export default PeopleTable;
