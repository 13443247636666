import { Invoice } from "../reducers/InvoiceReducer";

import { AppDispatch } from "../store";
import {
  CREATE_INVOICE,
  GET_INVOICE,
  GET_INVOICES,
  UPDATE_INVOICE,
} from "./types";
import { api } from "../../api";
import { Inventory } from "../reducers/InventoryReducer";

const getInvoicesLoading = () => ({
  type: GET_INVOICES,
  invoices: [],
  invoicesLoading: true,
});

const getInvoicesError = () => ({
  type: GET_INVOICES,
  invoices: [],
  invoicesLoading: false,
});

const getInvoicesSuccess = (invoices: Invoice[]) => ({
  type: GET_INVOICES,
  invoices,
  invoicesLoading: false,
});

const updateInvoiceLoading = () => ({
  type: UPDATE_INVOICE,
  updatedInvoice: null,
  updatedInvoiceLoading: true,
});

const updateInvoiceError = () => ({
  type: UPDATE_INVOICE,
  updatedInvoice: null,
  updatedInvoiceLoading: false,
});

const updateInvoiceSuccess = (updatedInvoice: Invoice) => ({
  type: UPDATE_INVOICE,
  updatedInvoice,
  updatedInvoiceLoading: false,
});

const createInvoiceLoading = () => ({
  type: CREATE_INVOICE,
  createdInvoice: null,
  createdInvoiceLoading: true,
});

const createInvoiceError = () => ({
  type: CREATE_INVOICE,
  createdInvoice: null,
  createdInvoiceLoading: false,
});

const createInvoiceSuccess = (createdInvoice: Invoice) => ({
  type: CREATE_INVOICE,
  createdInvoice: createdInvoice.invoice,
  createdInvoiceLoading: false,
  inventories: createdInvoice.inventories,
});
const getInvoiceLoading = () => ({
  type: GET_INVOICE,
  invoice: null,
  invoiceLoading: true,
});

const getInvoiceError = () => ({
  type: GET_INVOICE,
  invoice: null,
  invoiceLoading: false,
});

const getInvoiceSuccess = (invoice: Invoice) => ({
  type: GET_INVOICE,
  invoice: invoice.invoice,
  invoiceLoading: false,
  inventories: invoice.inventories,
  invoicenedInventories: invoice.invoicenedInventories,
});

//api
const getInvoicesFromAPI = async () => {
  const { data, error } = await api.provide("get", "/api/invoices", {});
  if (error) throw error;
  return (data?.invoices || []) as any;
};
const getInvoiceFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/invoices/:id", {
    id,
  });
  if (error) throw error;
  return data;
};
const updateInvoiceFromAPI = async (
  id: string,
  code: string,
  trackingCode: string,
  invoiceDate: string,
  consignerId: number
) => {
  const { data, error } = await api.provide("put", "/api/invoices/:id", {
    id,
    code,
    trackingCode,
    invoiceDate,
    consignerId,
  });
  if (error) throw error;
  return data;
};

const createInvoiceFromAPI = async (
  trackingCode: string = "",
  invoiceDate: string = "",
  consignerId?: number,
  code?: string
) => {
  const payload = {
    trackingCode,
    invoiceDate,
    consignerId,
    code,
  };

  const { data, error } = await api.provide("post", "/api/invoices", payload);
  if (error) throw error;
  return data;
};
//actions
export const getInvoices = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInvoicesLoading());
    try {
      dispatch(getInvoicesSuccess(await getInvoicesFromAPI()));
    } catch (e) {
      console.log(e);
      dispatch(getInvoicesError());
    }
  };
};

export const getInvoice = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInvoiceLoading());
    try {
      const invoiceData = await getInvoiceFromAPI(id);
      dispatch(getInvoiceSuccess(invoiceData));
      return invoiceData; // Return the invoice data
    } catch (e) {
      console.log(e);
      dispatch(getInvoiceError());
      throw e; // Throw the error so it can be caught in the useEffect
    }
  };
};

export const createInvoice = (
  trackingCode: string = "",
  invoiceDate: string = "",
  consignerId?: number,
  code?: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createInvoiceLoading());
    try {
      const result = await createInvoiceFromAPI(
        trackingCode,
        invoiceDate,
        consignerId,
        code
      );
      dispatch(createInvoiceSuccess(result));
      return result; // Return the result so it can be handled in the component
    } catch (e) {
      console.log(e);
      dispatch(createInvoiceError());
      throw e; // Re-throw the error so it can be handled in the component
    }
  };
};

export const updateInvoice = (
  id: string,
  code: string,
  trackingCode: string,
  invoiceDate: string,
  consignerId: number
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateInvoiceLoading());
    try {
      dispatch(
        updateInvoiceSuccess(
          await updateInvoiceFromAPI(
            id,
            code,
            trackingCode,
            invoiceDate,
            consignerId
          )
        )
      );
    } catch (e) {
      console.log(e);

      dispatch(updateInvoiceError());
    }
  };
};
