import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Form, Input, InputNumber, Card, Select } from "antd";
import Content from "../../constants/Content";
import Colors from "../../constants/Colors";
import {
  InventoryFilterOption,
  InventoryState,
} from "../../redux/reducers/InventoryReducer";
import ProductTemplateReducer from "../../redux/reducers/ProductTemplateReducer";
import { calculateFees } from "../../redux/actions/inventoryActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SubLocationState } from "../../redux/reducers/SubLocationReducer";
import { getSubLocations } from "../../redux/actions/subLocationActions";
import { OptimizedImageUrl } from "../../helperFunctions/optimizedImageUrl";

const { Option } = Select;

/**
 * EditableInventoryCard
 * TODO Implementation
 *  - image, size input, condition input, price input, location container -> location and sublocation input, action -> decline action
 *  - on change should set value
 */

//styles
const Container = styled.div`
  display: flex;
  background: ${Colors.WHITE};
  padding: 10px;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 1240px) {
    flex-direction: column;
  }
`;

const CardContainer = styled(Card)`
  margin-top: 10px;
`;

const Image = styled.img`
  width: 150px;
  margin-right: 20px;
  @media (max-width: 1240px) {
    width: 300px;
`;
const LocationContainer = styled.div``;
const FormItem = styled(Form.Item)`
  margin-right: 20px;
  @media (max-width: 1240px) {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
`;

interface Props {
  id: string;
  image: string;
  payoutFee: string;
  price: string;
  payout: any;
  option1?: string;
  option2?: string;
  option3?: string;
  option1Value: string;
  option2Value: string;
  option3Value: string;
  option1Values?: [];
  option2Values?: [];
  option3Values?: [];
  // condition: string;
  // size: string;
  productTemplate: any;
  location: string;
  title: string;
  subLocation: string;
  locationValues: InventoryFilterOption[];
  isStoreAccount: Function;

  // sizeValues: ProductSize[];
  // conditionValues: ProductCondition[];
  setSubLocation: (value: string) => void;
  setLocation: (value: string) => void;
  // setSize: (value: string) => void;
  setPrice: (value: string) => void;
  setPayoutFee: (value: string) => void;
  setPayout: (value: any) => void;

  setOption1Value: (value: string) => void;
  setOption2Value: (value: string) => void;
  setOption3Value: (value: string) => void;
  // setOption1Values: [];
  // setOption2Values: [];
  // setOption3Values: [];
  // setCondition: (value: string) => void;
  // setBoxCondition: (value: string) => void;
  // boxConditionValues: ProductBoxCondition[];
  // boxCondition: string;
  onDelete: () => void;
  onSave: () => void;
  status: string;
  setSelectedNotes: (value: string) => void;
  selectedNotes: string;
  consigner: any;
  category: any;
}

const EditableInventoryCard = (props: Props): JSX.Element => {
  const {
    id,
    option1Values,
    option2Values,
    option3Values,
    option1,
    option2,
    option3,
    // setOption1Values,
    // setOption2Values,
    // setOption3Values,
    option1Value,
    option2Value,
    option3Value,
    setOption1Value,
    setOption2Value,
    setOption3Value,
    image,
    payoutFee,
    price,
    location,
    productTemplate,
    subLocation,
    title,
    locationValues,
    setSubLocation,
    setPrice,
    setLocation,
    onDelete,
    onSave,
    status,
    payout,
    setPayout,
    setSelectedNotes,
    selectedNotes,
    isStoreAccount,
    consigner,
    category,
  } = props;
  const { inventory, calculatedFees, createdInventory }: InventoryState =
    useAppSelector((state) => state.InventoryReducer);

  const { subLocations }: SubLocationState = useAppSelector(
    (state) => state.SubLocationReducer
  );

  const dispatch = useAppDispatch();
  const [feesCalculatedId, setFeesCalculatedId] = useState("");
  const [filteredSubLocations, setFilteredSubLocations] = useState<any>([]);

  useEffect(() => {
    if (subLocations.length > 0) {
      setFilteredSubLocations(
        subLocations.filter((item: any) => item.locationName == location)
      );
    }
  }, [subLocations]);

  const onLocationChange = (location: any) => {
    setLocation(location);

    if (subLocations.length > 0) {
      const filterSubLocation = subLocations.filter(
        (item: any) => item.locationName == location
      );

      setFilteredSubLocations(filterSubLocation);
      setSubLocation(filterSubLocation[0].name);
    }
  };

  const handlePriceChange = async (newValue: any) => {
    let quantity = 1;
    let cost = 0;

    if (inventory?.price !== newValue) {
      setPrice(newValue);
      //re fetches fees on price and quantity changes
      if (JSON.parse(consigner).isStoreAccount !== true) {
        await dispatch(
          calculateFees(
            id,
            Number(newValue),
            quantity,
            option1Value,
            option2Value,
            option3Value,
            Number(cost),
            category,
            consigner
          )
        );
      }
      setFeesCalculatedId(id);
    }
  };

  useEffect(() => {
    dispatch(getSubLocations());
  }, []);

  useEffect(() => {
    if (calculatedFees && feesCalculatedId == id) {
      setPayout(calculatedFees.payout);
      setFeesCalculatedId("");
    }
  }, [feesCalculatedId]);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function () {
    setInnerWidth(window.innerWidth);
  });

  return (
    <CardContainer title={title}>
      <Container>
        <Image src={OptimizedImageUrl(image)} />
        {productTemplate && productTemplate.option1 && (
          <FormItem label={option1}>
            <Select
              style={
                innerWidth <= 1240 ? { width: "100%" } : { width: "150px" }
              }
              showSearch
              value={option1Value}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => setOption1Value(value)}
            >
              {option1Values?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </FormItem>
        )}

        {productTemplate && productTemplate.option2 && (
          <FormItem label={option2}>
            <Select
              style={
                innerWidth <= 1240 ? { width: "100%" } : { width: "150px" }
              }
              showSearch
              value={option2Value}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => setOption2Value(value)}
            >
              {option2Values?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </FormItem>
        )}
        {productTemplate && productTemplate.option3 && (
          <FormItem label={option3}>
            <Select
              style={
                innerWidth <= 1240 ? { width: "100%" } : { width: "150px" }
              }
              showSearch
              value={option3Value}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => setOption3Value(value)}
            >
              {option3Values?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </FormItem>
        )}

        <FormItem label={Content.EDIT_INVENTORY_INPUT_THREE}>
          <InputNumber
            value={price}
            onChange={(value) => handlePriceChange(value)}
          />
        </FormItem>
        <FormItem label={isStoreAccount() ? "Cost" : "Payout"}>
          <InputNumber
            precision={2}
            defaultValue={payout}
            value={payout}
            onChange={(e) => setPayout(e)}
          />
        </FormItem>
        <FormItem label={Content.EDIT_INVENTORY_INPUT_FOUR}>
          <LocationContainer>
            <Select
              style={
                innerWidth <= 1240 ? { width: "100%" } : { width: "150px" }
              }
              showSearch
              value={location}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => onLocationChange(value)}
            >
              {locationValues?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Select
              style={
                innerWidth <= 1240 ? { width: "100%" } : { width: "150px" }
              }
              showSearch
              // defaultValue={subLocation}
              value={subLocation}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => setSubLocation(value)}
            >
              {filteredSubLocations.map((item: any, index: any) => (
                <Option key={index} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </LocationContainer>
        </FormItem>
        {status !== "Withdrawn" && status !== "Declined" && (
          <Container style={{ display: "flex", flexDirection: "column" }}>
            <Button
              type="primary"
              onClick={onSave}
              style={{ marginBottom: "0.5rem" }}
            >
              {Content.EDIT_INVENTORY_PRIMARY_BUTTON_TEXT}
            </Button>
            {/* <Button type="primary" danger onClick={onDelete}>
              {Content.EDIT_INVENTORY_DANGER_BUTTON_TEXT}
            </Button> */}
          </Container>
        )}
      </Container>
      <Input.TextArea
        placeholder="Notes"
        rows={4}
        value={selectedNotes}
        onChange={(e) => setSelectedNotes(e.target.value)}
      />
    </CardContainer>
  );
};

export default EditableInventoryCard;
