import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router";
import { Card, message, Spin, Tag } from "antd";
import HorizontalPageCard from "../components/Common/HorizontalPageCard";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  exportPayoutInventories,
  getPayout,
  markPaidInventories,
  retryAutoPayout,
} from "../redux/actions/payoutActions";

import Content from "../constants/Content";
import InventoryList from "../components/Common/InventoryList";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { PayoutState } from "../redux/reducers/PayoutReducer";
import { UserState } from "../redux/reducers/UserReducer";
import {
  getInventoriesByConsigner,
  getInventoriesPayoutTotal,
} from "../helperFunctions/getInventoriesHelpers";
import { getCheques } from "../redux/actions/chequeActions";
import { ChequeState } from "../redux/reducers/ChequeReducer";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { getInventoryFilterOptions } from "../redux/actions/inventoryActions";

/**
 * Employee Item Screen
 * renders a screen where user can edit a singular order item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  padding-bottom: 100px;
`;

interface RouteParams {
  id: string;
}

const goToInventoryPage = (
  history: RouteComponentProps["history"],
  inventory: Inventory
) => {
  history.push(`/inventories/${inventory.id}`);
};

const PayoutItem = () => {
  const { id }: RouteParams = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    payout,
    retryPayout,
    payoutLoading,
    payoutSuccesful,
    autoPayoutInventoriesLoading,
  }: PayoutState = useAppSelector((state) => state.PayoutReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const { cheques }: ChequeState = useAppSelector(
    (state) => state.ChequeReducer
  );
  const [consignerWithInventories, setConsignerWithInventories] = useState<any>(
    []
  );

  const key = "updatable";

  useEffect(() => {
    //get payout on load
    dispatch(getInventoryFilterOptions());
    dispatch(getPayout(id));
  }, []);

  useEffect(() => {
    if (payoutSuccesful) {
      dispatch(getPayout(id));
    }
  }, [payoutSuccesful]);

  useEffect(() => {
    if (payoutSuccesful && payout && retryPayout && payout.id == retryPayout) {
      message.success({
        content: "Auto payout retry successful!",
        key,
        duration: 5,
      });
    }
  }, [retryPayout, payout]);

  useEffect(() => {
    if (payout && payout.inventories) {
      const inventoriesByConsigner = getInventoriesByConsigner(
        payout.inventories
      );
      setConsignerWithInventories(inventoriesByConsigner);

      dispatch(getCheques(payout.id));
    }
  }, [payout]);

  useEffect(() => {
    if (
      store &&
      store.automatedPayout &&
      payout &&
      payout.status == "Pending" &&
      consignerWithInventories.length > 0
    ) {
      message.loading({
        content: "Auto payout in progress, check back later for status update",
      });
    }
  }, [consignerWithInventories]);

  const retryFailedPayout = () => {
    // if a failed payout has buyingId present, it is a failed "Buying" payout
    if (
      consignerWithInventories.some(
        (data) =>
          data.inventories?.[0]?.buyingId &&
          Number(data.inventories?.[0].buyingId) > 0
      )
    ) {
      processBoughtInventories();
      return;
    }
    if (consignerWithInventories) {
      //check consigners inventory sum / 2000 !== cheques -> there's issue
      let balance = 0;
      let consignersAndInventoryNotFullyPaid = [];
      for (let i = 0; i < consignerWithInventories.length; i++) {
        const data = consignerWithInventories[i];
        const inventories = data.inventories;

        const inventoryTotal = inventories
          .reduce((total: any, item: any) => {
            return total + Number(item.payoutAmount);
          }, 0)
          .toFixed(2);

        const chequesMatch = cheques.filter(
          (cheque: any) => cheque.consignerId === data.consigner.id
        );

        if (Math.ceil(Number(inventoryTotal / 2000)) !== chequesMatch.length) {
          //get check value that went through and get balance
          const chequesTotal = chequesMatch
            .reduce((total: any, item: any) => {
              return total + Number(item.amount);
            }, 0)
            .toFixed(2);
          balance = Number(inventoryTotal - chequesTotal);

          data.balance = balance;
          //each fail has it's own balance

          consignersAndInventoryNotFullyPaid.push(data as never);
        }
      }
      message.loading({
        content: "Retrying auto payout...",
        key,
        duration: 0,
      });
      dispatch(retryAutoPayout(payout!.id, consignersAndInventoryNotFullyPaid));
    }
  };

  const processBoughtInventories = () => {
    if (consignerWithInventories) {
      //check consigners inventory sum / 2000 !== cheques -> there's issue
      let balance = 0;
      let consignersAndInventoryNotFullyPaid = [];
      for (let i = 0; i < consignerWithInventories.length; i++) {
        const data = consignerWithInventories[i];
        const inventories = data.inventories;
        const consignerTarget = inventoryFilterOptions.consigners.find(
          (con) => con.value.id === inventories[0].buyingId
        )?.value;

        const inventoryTotal = inventories
          .reduce((total: any, item: any) => {
            return total + Number(item.payoutAmount);
          }, 0)
          .toFixed(2);

        const chequesMatch = cheques.filter(
          (cheque: any) => cheque.consignerId === consignerTarget.id
        );

        if (Math.ceil(Number(inventoryTotal / 2000)) !== chequesMatch.length) {
          //get check value that went through and get balance
          const chequesTotal = chequesMatch
            .reduce((total: any, item: any) => {
              return total + Number(item.amount);
            }, 0)
            .toFixed(2);
          balance = Number(inventoryTotal - chequesTotal);

          data.balance = balance;
          //each fail has it's own balance

          consignersAndInventoryNotFullyPaid.push({
            ...data,
            consigner: consignerTarget,
            inventories: inventories.map((inventory) => ({
              ...inventory,
              consignerId: consignerTarget.id,
              consigner: consignerTarget,
            })),
          } as never);
        }
      }
      message.loading({
        content: "Retrying auto payout...",
        key,
        duration: 0,
      });
      dispatch(retryAutoPayout(payout!.id, consignersAndInventoryNotFullyPaid));
    }
  };

  const doTheChequesMatch = (consignerId: string, inventories: Inventory[]) => {
    return (
      cheques.filter((cheque: any) => cheque.consignerId === consignerId)
        .length ===
      Math.ceil(Number(getInventoriesPayoutTotal(inventories)) / 2000)
    );
  };

  const markAsPaid = () => {
    dispatch(markPaidInventories(payout!.id, consignerWithInventories));
    message.success("Status changed to paid!");
  };

  const getPrimaryActionText = (status: string): string => {
    switch (status) {
      case "Failed":
        return "Retry Auto Payout";
      case "Buying":
        return "Pay";
      default:
        return Content.PAYOUT_ACTION_BUTTON_TEXT_TWO;
    }
  };

  const getPrimaryAction = (status: string) => {
    switch (status) {
      case "Failed":
        return retryFailedPayout;
      case "Buying":
        return processBoughtInventories;
      default:
        return markAsPaid;
    }
  };

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Orders")
  ) {
    return <Redirect to="/" />;
  }
  if (payoutLoading || !payout || inventoryFilterOptionsLoading) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return (
    <Container>
      <HorizontalPageCard
        title={`Payout ${payout.name} - ${payout.status}`}
        subtitle={`${payout.itemCount} items`}
        primaryActionText={
          payout
            ? getPrimaryActionText(payout.status)
            : Content.PAYOUT_ACTION_BUTTON_TEXT_TWO
        }
        primaryAction={payout ? getPrimaryAction(payout.status) : markAsPaid}
        boldText={`${store?.currency} ${payout.total}`}
        secondaryActionText={Content.PAYOUT_ACTION_BUTTON_TEXT}
        secondaryAction={() =>
          dispatch(exportPayoutInventories(payout.inventories))
        }
      />
      {consignerWithInventories.map(
        (consignerWithInventory: any, index: any) => {
          const consignerTarget =
            payout.status === "Buying" ||
            consignerWithInventory.inventories[0].buyingId
              ? inventoryFilterOptions.consigners.find(
                  (con) =>
                    con.value.id ===
                    consignerWithInventory.inventories[0].buyingId
                )?.value
              : consignerWithInventory.consigner;
          // consignerWithInventories[index].inventories = consignerWithInventory.inventories.map((inventory) => ({...inventory, consignerId: consignerTarget.id}));
          return (
            <Card
              key={index}
              style={{ marginTop: 10 }}
              title={
                <>
                  Seller: {consignerTarget.firstName} {consignerTarget.lastName}
                  {"  "}
                  {store?.automatedPayout ? (
                    <>
                      {cheques.filter(
                        (cheque: any) =>
                          cheque.consignerId === consignerTarget.id
                      ).length < 1 && payout.status !== "Buying" ? (
                        <Tag icon={<CloseCircleOutlined />} color="error">
                          cheque error
                        </Tag>
                      ) : (
                        <Tag
                          icon={
                            doTheChequesMatch(
                              consignerTarget.id,
                              consignerWithInventory.inventories
                            ) ? (
                              <CheckCircleOutlined />
                            ) : (
                              <ExclamationCircleOutlined />
                            )
                          }
                          color={
                            doTheChequesMatch(
                              consignerTarget.id,
                              consignerWithInventory.inventories
                            )
                              ? "success"
                              : "warning"
                          }
                        >
                          {
                            cheques.filter(
                              (cheque: any) =>
                                cheque.consignerId === consignerTarget.id
                            ).length
                          }{" "}
                          /{" "}
                          {Math.ceil(
                            Number(
                              getInventoriesPayoutTotal(
                                consignerWithInventory.inventories
                              )
                            ) / 2000
                          )}{" "}
                          cheques sent
                        </Tag>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              }
              extra={`Total: ${store?.currency} ${getInventoriesPayoutTotal(
                consignerWithInventory.inventories
              )}`}
            >
              <InventoryList
                inventories={consignerWithInventory.inventories.map(
                  (inventory) => ({
                    ...inventory,
                    consignerId: consignerTarget.id,
                    consigner: consignerTarget,
                  })
                )}
                loading={false}
                onAccept={(inventory: Inventory) =>
                  goToInventoryPage(history, inventory)
                }
                acceptButtonText={Content.ORDER_ITEM_INVENTORY_ITEM_BUTTON_TEXT}
              />
            </Card>
          );
        }
      )}
    </Container>
  );
};

export default PayoutItem;
