import React, { useState } from "react";
import { CustomModal, StyledSelect } from "./inventoryStyle";
import { Button, Col, Input, Row, Select, message } from "antd";
import AddImage from "../../assets/images/svg/AddImage.svg";
import {
  addSelectedProduct,
  createProduct,
} from "../../redux/actions/productActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ProductModal from "./ProductModal";

const { Option } = Select;

interface ProductModalProp {
  setIsCustomModal: () => void;
  setIsSucces: () => void;
  isProductModal: boolean;
}
export default function AddCustomProduct({
  setIsCustomModal,
  setIsSucces,
  isProductModal,
}: ProductModalProp) {
  const { store } = useAppSelector((state) => state.StoreReducer);
  const { productTemplates } = useAppSelector(
    (state) => state.ProductTemplateReducer
  );
  const dispatch = useAppDispatch();

  const [images, setImages] = useState<string[]>([]);
  const [customProducts, setCustomProducts] = useState<any[]>([
    {
      title: "",
      sku: "",
      size: "",
      quantity: "",
      condition: "Select",
      price: "",
      cost: "",
      brand: "Any",
      category: "Any",
      gender: "Any",
      productTemplateId: "",
    },
  ]);
  const handleCancel = () => {
    setIsCustomModal();
  };
  const validateProducts = () => {
    for (const product of customProducts) {
      if (
        !product.title ||
        !product.sku ||
        !product.size ||
        !product.quantity ||
        product.condition === "Select" ||
        !product.price ||
        !product.cost ||
        !product.brand ||
        !product.category ||
        !product.gender ||
        !product.productTemplateId
      ) {
        return false;
      }
    }
    return true;
  };
  const handleOk = async () => {
    try {
      if (!validateProducts()) {
        message.error("Please fill in all required fields.");
        return;
      }

      const createdProducts = await Promise.all(
        customProducts.map(async (product) => {
          const createdProduct = await dispatch(createProduct(product));
          if (createdProduct) {
            const updatedProduct = {
              price: product?.price,
              option2Value: product?.condition,
              quantity: product?.quantity,
              productId: createdProduct?.id,
              cost: 2,
              category: product?.category ?? "",
              sku: product?.sku,
              title: product?.title,
              productTemplateId: product?.productTemplateId,
            };
            dispatch(addSelectedProduct(updatedProduct));
          }
          return createdProduct;
        })
      );

      console.log("Created products:", createdProducts);
      setCustomProducts([]);
      setIsSucces();
    } catch (error) {
      console.error("Error creating products:", error);
      message.error("Failed to create products. Please try again later.");
    }
  };
  const handleAddCustomProduct = () => {
    setCustomProducts([
      ...customProducts,
      {
        title: "",
        sku: "",
        size: "",
        quantity: "",
        condition: "Select",
        price: "",
        cost: "",
        brand: "Any",
        category: "Any",
        gender: "Any",
        productTemplateId: "",
      },
    ]);
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedProducts = [...customProducts];
    updatedProducts[index][name] = value;
    setCustomProducts(updatedProducts);
  };
  const handleSelectChange = (value, index) => {
    const updatedProducts = [...customProducts];
    updatedProducts[index].condition = value;
    setCustomProducts(updatedProducts);
  };
  const handleSelectProductTemplateChange = (value, index) => {
    const updatedProducts = [...customProducts];
    updatedProducts[index].productTemplateId = value;
    setCustomProducts(updatedProducts);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages([...images, reader.result as string]);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <CustomModal
        title="Add Inventory"
        visible={isProductModal}
        onCancel={() => handleCancel()}
        width={800}
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: "flex" }}>
            <Button
              key="back"
              type="primary"
              style={{
                padding: 8,
                width: 90,
                height: 52,
                borderRadius: "8px",
                border: "1px solid #DDDDDD",
                color: "black",
                background: "#F4F4F4",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              style={{
                padding: "8px 35px",
                height: 52,
                borderRadius: "8px",
                background: "#685FF9",
                right: "2%",
                position: "absolute",
              }}
              onClick={() => handleOk()}
            >
              Next
            </Button>
          </div>,
        ]}
      >
        {customProducts.map((product, index) => (
          <div
            style={{
              border: "1px solid #DDDDDD",
              borderRadius: "8px",
              marginTop: "1rem",
            }}
            key={index}
          >
            <Row
              style={{
                padding: "10px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                background: "#F9F9F9",
                fontWeight: "500",
                borderBottom: "1px solid #DDDDDD",
              }}
              gutter={10}
            >
              Custom Product 0{index + 1}
            </Row>
            <div style={{ padding: "10px" }}>
              <Row
                gutter={10}
                style={{ marginBottom: "1rem", alignItems: "center" }}
              >
                <Col span={18}>
                  <span style={{ marginBottom: "3px" }}>Product Name</span>
                  <Input
                    style={{ borderRadius: "8px", height: "48px" }}
                    type="text"
                    value={product.title}
                    onChange={(e) => handleChange(e, index)}
                    name="title"
                  />
                </Col>
                <Col span={6}>
                  <span style={{ visibility: "hidden" }}>.</span>
                  <StyledSelect
                    defaultValue="Select Template"
                    onChange={(value) =>
                      handleSelectProductTemplateChange(value, index)
                    }
                  >
                    {productTemplates?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.title}
                      </Option>
                    ))}
                  </StyledSelect>
                </Col>
              </Row>
              <Row gutter={10} style={{ marginBottom: "1rem" }}>
                <Col span={6}>
                  <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                    SKU
                  </span>
                  <Input
                    style={{ borderRadius: "8px", height: "48px" }}
                    type="text"
                    value={product.sku}
                    onChange={(e) => handleChange(e, index)}
                    name="sku"
                  />
                </Col>
                <Col span={5}>
                  <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                    Size
                  </span>
                  <Input
                    style={{ borderRadius: "8px", height: "48px" }}
                    type="text"
                    value={product.size}
                    onChange={(e) => handleChange(e, index)}
                    name="size"
                  />
                </Col>
                <Col span={5}>
                  <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                    Quantity
                  </span>
                  <Input
                    style={{ borderRadius: "8px", height: "48px" }}
                    type="number"
                    value={product.quantity}
                    onChange={(e) => handleChange(e, index)}
                    name="quantity"
                  />
                </Col>
                <Col span={8}>
                  <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                    Condition
                  </span>
                  <StyledSelect
                    value={product.condition}
                    onChange={(value) => handleSelectChange(value, index)}
                  >
                    <Option value="New">New</Option>
                    <Option value="Used">Used</Option>
                    <Option value="Old">Old</Option>
                  </StyledSelect>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                    Price
                  </span>
                  <Input
                    style={{ borderRadius: "8px", height: "48px" }}
                    type="number"
                    prefix={store?.currency}
                    value={product.price}
                    onChange={(e) => handleChange(e, index)}
                    name="price"
                  />
                </Col>
                <Col span={12}>
                  <span style={{ fontWeight: "500", marginBottom: "3px" }}>
                    Cost
                  </span>
                  <Input
                    style={{ borderRadius: "8px", height: "48px" }}
                    type="number"
                    prefix={store?.currency}
                    value={product.cost}
                    onChange={(e) => handleChange(e, index)}
                    name="cost"
                  />
                </Col>
              </Row>
              <span style={{ marginBottom: "3px", marginTop: "15px" }}>
                Images
              </span>
              <Row style={{ marginBottom: "1rem" }}>
                {images.map((image, index) => (
                  <Col key={index} span={4}>
                    <img
                      src={image}
                      alt={`Product ${index + 1}`}
                      style={{
                        width: "111px",
                        height: "120px",
                        border: "1px solid #DDDDDD",
                        borderRadius: "12px",
                        background: "#F9F9F9",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                ))}
                <Col span={4}>
                  <label htmlFor="file-input">
                    <img
                      src={AddImage}
                      alt="Add Product"
                      style={{
                        width: "111px",
                        height: "120px",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Col>
              </Row>
            </div>
          </div>
        ))}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            key="submit"
            type="primary"
            style={{
              padding: "8px 15px",
              color: "#685FF9",
              height: 52,
              borderRadius: "8px",
              background: "white",
              borderBlockColor: "#685FF9",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
            onClick={() => handleAddCustomProduct()}
          >
            + Custom Product
          </Button>
        </div>
      </CustomModal>
    </>
  );
}
