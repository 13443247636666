import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Input, message } from "antd";
import { Redirect } from "react-router";
import Content from "../constants/Content";
import PeopleTable from "../components/Common/PeopleTable";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getConsigners } from "../redux/actions/consignerActions";
import { UserState } from "../redux/reducers/UserReducer";
import { Link } from "react-router-dom";
import RedesignStyling from "../constants/RedesignStyling";
import { StoreState } from "../redux/reducers/StoreReducer";

const { Search } = Input;

/**
 * Consigner Screen
 * Renders table of consigners and search
 * TODO TESTS:
 *  - renders correctly
 *  - on search refetchs consigners
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

const Consigners = () => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const { consigners, consignersLoading } = useAppSelector(
    (state) => state.ConsignerReducer
  );

  /**
   * Export consigners to csv
   * Maps through Consigners and creates a csv file
   * @returns {void}
   */
  const handleExportConsigners = () => {
    const columnTitles = [
      "Consigner ID",
      "First Name",
      "Last Name",
      "Email",
      "Phone",
      "Address",
      "Account Type",
      "Fee",
      "isActive",
      "sales",
      "profit",
    ];

    consigners.sort((a, b) => a.firstName.localeCompare(b.firstName));
    const csvData = consigners.map((consigner) => [
      `"${consigner.id}"`,
      `"${consigner.firstName}"`,
      `"${consigner.lastName}"`,
      `"${consigner.email}"`,
      `"${consigner.phone}"`,
      `"${consigner.address}"`,
      `"${consigner.accountType}"`,
      consigner.ffData && typeof consigner.ffData.fee !== "undefined"
        ? `"${consigner.ffData.fee}%"`
        : "",
      `"${consigner.isActive}"`,
      consigner.ffData && typeof consigner.ffData.sales !== "undefined"
        ? `"${consigner.ffData.sales}"`
        : "",
      consigner.ffData && typeof consigner.ffData.profit !== "undefined"
        ? `"${consigner.ffData.profit}"`
        : "",
    ]);

    csvData.unshift(columnTitles);
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "consigners.csv");

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    message.success("Consigners exported successfully", 3);
  };

  useEffect(() => {
    //get products here any time id changes
    dispatch(getConsigners(search));
  }, [search]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Consigners")
  ) {
    return <Redirect to="/" />;
  }
  return (
    <Container>
      <Search
        data-testid="search"
        placeholder={Content.CONSIGNER_SCREEN_SEARCH_PLACEHOLDER}
        allowClear
        enterButton="Search"
        size="large"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      <Button
        data-testid="exportConsigners"
        type="primary"
        // style align right
        style={{ marginTop: "10px", marginLeft: "auto", marginRight: "5px" }}
        onClick={handleExportConsigners}
      >
        Export
      </Button>
      <PeopleTable
        link="/consigners"
        consigners={consigners}
        loading={consignersLoading}
      />
      <Link data-testid="addEmployeeLink" to="/addConsigner">
        Add Seller
      </Link>
    </Container>
  );
};

export default Consigners;
